import { FC } from "react";
import { StyledHeader, StyledFooter, StyledFooterLink } from "./style";
import { Card } from "../../Common/Card";
import { Company, DataAnalysis, ScreenConfig } from "../../../types";
import SectionTitle from "../../Common/SectionTitle";
import { ListGroup, ListGroupItem } from "../../Common/ListGroup";
import { useTranslation } from "react-i18next";
import { Row } from "../../Common/Grid";
import Item from "./Item";
import { useNavigate } from "react-router-dom";
import { BasicBusinessDataEmptyState } from "../../BasicBusinessData/EmptyState";

type Props = {
  workspaces: DataAnalysis[];
  companyId: Company["Id"];
  config: ScreenConfig;
  enabled?: boolean;
};

export const BasicBusinessDataWidget: FC<Props> = ({
  workspaces,
  companyId,
  config,
  enabled = false,
}) => {
  const { t } = useTranslation();
  const { Icon } = config;
  const navigate = useNavigate();

  const handleItemClick = (id: number) =>
    navigate(`/company/${companyId}/data-analysis/${id}`);

  return (
    <Card height="100%">
      <StyledHeader color={config.theme.primaryColor}>
        <Row alignItems="center" paddingLeft="1rem">
          <Icon color={config.theme.primaryColor} />
          <SectionTitle title={t("components.menu.basicBusinessData")} />
        </Row>
      </StyledHeader>
      {enabled ? (
        <>
          <ListGroup flush style={{ height: "100%" }}>
            {workspaces.length === 0 && (
              <BasicBusinessDataEmptyState
                message={t("components.basicBusinessData.emptyState")}
              />
            )}
            {workspaces.map((workspace) => (
              <ListGroupItem
                key={workspace.Id}
                display="flex"
                px={[null, "20px"]}
              >
                <Item
                  workspace={workspace}
                  defaultColor={config.theme.primaryColor}
                  onClick={() => handleItemClick(workspace.Id)}
                />
              </ListGroupItem>
            ))}
          </ListGroup>
          {workspaces.length > 0 && (
            <StyledFooter>
              <StyledFooterLink
                path={`/company/${companyId}/data-analysis/${workspaces[0].Id}`}
              >
                {t("components.basicBusinessDataWidget.viewAll")}
                <i className="icon ion-md-arrow-down mg-l-5" />
              </StyledFooterLink>
            </StyledFooter>
          )}
        </>
      ) : (
        <BasicBusinessDataEmptyState
          message={t("components.basicBusinessData.disabled")}
        />
      )}
    </Card>
  );
};
