import React, { FC } from "react";
import { StyledForm } from "./style";
import { useTranslation } from "react-i18next";
import { Select } from "../../Common/Forms";
import { Button } from "../../Common/Button";
import { Filter } from "react-feather";

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent) => any;
};

const FilterForm: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <Select value={value} onChange={onChange}>
        <option value="all">{t("components.applicationCard.filterAll")}</option>
        <option value="enabled">
          {t("components.applicationCard.filterEnabled")}
        </option>
      </Select>
      <Button variant="texted" iconButton size="md">
        <Filter />
      </Button>
    </StyledForm>
  );
};

export default FilterForm;
