import styled, { themeGet, device } from "../../../styled";

export const StyledFooter = styled.footer`
  font-size: 10px;
  font-family: ${themeGet("fonts.interUi")};
  letter-spacing: 0.3px;
  padding: 15px 25px;
  background-color: ${themeGet("colors.gray50")};
  color: ${themeGet("colors.gray950")};
  border-top: 1px solid ${themeGet("colors.border")};
  text-transform: uppercase;
  ${device.large} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
