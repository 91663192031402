import { useEffect, useState } from "react";
import { User } from "../types";
import { useQuery } from "./useQuery";
import { useStores } from "./useStores";
import { useSharepointSupervisionedUsers } from "./sharepoint.hooks";

export const useActiveUser = () => {
  const { session } = useStores();
  const params = useQuery();

  const [activeUserId, setActiveUserId] = useState<User["Id"] | null>(null);

  useEffect(() => {
    if (params.get("user")) {
      setActiveUserId(Number(params.get("user")));
    } else if (session.user) {
      setActiveUserId(session.user.Id);
    }
  }, [params, session.user]);

  return activeUserId;
};

export const useActiveUserData = () => {
  const activeUserId = useActiveUser();
  const { data: supervisionedUsers = [] } = useSharepointSupervisionedUsers();

  const [activeUserData, setActiveUserData] = useState<User | null>(null);

  useEffect(() => {
    if (activeUserId) {
      const activeUser = supervisionedUsers.find(
        (user) => user.Id === activeUserId
      );
      setActiveUserData(activeUser || null);
    }
  }, [activeUserId, supervisionedUsers]);

  return activeUserData;
};
