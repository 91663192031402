import { FC, useCallback } from "react";
import { X } from "react-feather";
import { StyledHeader, StyledTitle, StyledBody } from "./style";
import { Modal } from "../../Common/Modal";
import { Nav, NavLink } from "../../Common/Nav/nav";
import { ScreenConfig, SupervisionedUser } from "../../../types";
import { useTranslation } from "react-i18next";
import { Media, MediaBody } from "../../UI/Media";
import { Col, Row } from "../../Common/Grid";
import { Checkbox } from "../../Common/Forms/Checkbox";
import Heading from "../../Common/Heading";

interface IProps {
  companyUsers: SupervisionedUser[];
  show: boolean;
  config: ScreenConfig;
  onClose: () => any;
  onChange: (userId: SupervisionedUser["Id"], isEmailEnabled: boolean) => any;
}

const SetupMailForwardingModal: FC<IProps> = ({
  show,
  onClose,
  companyUsers,
  config,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleOnClose = useCallback(
    (e?: any) => {
      e && e.preventDefault();
      onClose();
    },
    [onClose]
  );

  return (
    <Modal show={show} onClose={onClose} size="lg" closeOnClickBackdrop={false}>
      <StyledHeader backgroundColor={config.theme.primaryColor}>
        <StyledTitle>{t("components.sharepoint.settings")}</StyledTitle>
        <Nav customStyle="icon">
          <NavLink path="#!" onClick={handleOnClose}>
            <X />
          </NavLink>
        </Nav>
      </StyledHeader>
      <StyledBody>
        <Media display={["block", "flex"]}>
          <MediaBody mt={["25px", 0]}>
            <Heading tt="uppercase" fontWeight="600" mb="1rem">
              {t("components.sharepoint.mailForwarding")}
            </Heading>
            <Row alignItems="center" marginBottom="0.2rem">
              <Col sm fontWeight={600}>
                {t("components.sharepoint.settingsSupervied")}
              </Col>
              <Col sm={2} textAlign={"center"} fontWeight={600}>
                {t("components.sharepoint.settingsEnabeld")}
              </Col>
              <Col sm={2} textAlign={"center"} fontWeight={600}>
                {t(
                  "components.sharepoint.settingsTotMessageUndereadSupervised"
                )}
              </Col>
              <Col sm={2} textAlign={"center"} fontWeight={600}>
                {t(
                  "components.sharepoint.settingsTotMessageUnreadedSupervisor"
                )}
              </Col>
              <Col sm={2} textAlign={"center"} fontWeight={600}>
                {t("components.sharepoint.settingsTotMessageUnreaded")}
              </Col>
            </Row>
            {companyUsers.map((user) => (
              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>{`${user.Firstname} ${user.Lastname}`}:</Col>
                <Col sm={2} textAlign={"center"}>
                  <Checkbox
                    label=""
                    name={`check-${user.Id}`}
                    id={`check-${user.Id}`}
                    checked={user.IsEmailEnabled}
                    color={config.theme.primaryColor}
                    onClick={(event) => {
                      event.stopPropagation();
                      // @ts-ignore
                      onChange(user.Id, event.target.checked);
                    }}
                  />
                </Col>
                <Col sm={2} textAlign={"center"}>
                  {user.TotalSupervisedUnreaded}
                </Col>
                <Col sm={2} textAlign={"center"}>
                  {user.TotalSupervisorUnreaded}
                </Col>
                <Col sm={2} textAlign={"center"}>
                  {user.TotalUnreaded}
                </Col>
              </Row>
            ))}
          </MediaBody>
        </Media>
      </StyledBody>
    </Modal>
  );
};

export default SetupMailForwardingModal;
