import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Main from "../../components/Notifications/Main";
import {
  useNotification,
  useTrakReadedNotification,
} from "../../hooks/notifications.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Screens } from "../../types";

export const NotificationDetail: React.FC = () => {
  const { session } = useStores();
  const params = useParams();
  const config = useScreenConfig(Screens.NOTIFICATIONS);

  const { isFetching, data: notification = null } = useNotification(
    Number(params.notificationId) || -1
  );

  const { isLoading: isSubmitting, mutateAsync: track } =
    useTrakReadedNotification(
      session.user?.Id || -1,
      session.activeCompanyId || -1
    );

  useEffect(() => {
    if (notification && notification.Unreaded && !session.user?.Internal) {
      track(notification.Id);
    }
  }, [notification, session.user?.Internal]);

  return notification ? (
    <Main notification={notification} config={config} />
  ) : null;
};
