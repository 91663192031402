import { useQuery } from "react-query";
import api from "../services/api";
import { useActiveCompany } from "./useActiveCompany";

export const useCompanyUsers = (internal?: number) => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`company-users-${activeCompany}-${internal}`, activeCompany, internal],
    () => api.fetchCompanyUsers(activeCompany || -1, internal),
    {
      enabled: !!activeCompany,
    }
  );
};
