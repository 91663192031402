import { FC, useState } from "react";
import { MoreVertical, Download, CornerUpLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Nav, NavLink } from "../../Common/Nav/nav";
import { StyledToolbar } from "./style";

type Props = {
  hasAttachments: boolean;
  type: "inbox" | "sent";
  onDownloadAttachments: () => void;
  onReply: () => void;
};

const ToolbarIcons: FC<Props> = ({
  hasAttachments,
  type,
  onDownloadAttachments,
  onReply,
}) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleNav = () => {
    setShow((prev) => !prev);
  };

  return (
    <StyledToolbar $show={show}>
      {hasAttachments && (
        <Nav customStyle="icon">
          {type === "inbox" && (
            <NavLink
              path="#"
              data-for="tooltip-reply"
              data-tip={t("components.sharepoint.reply")}
              onClick={(e: any) => {
                e.preventDefault();
                onReply();
              }}
            >
              <CornerUpLeft />
            </NavLink>
          )}
          <NavLink
            path="#"
            data-for="tooltip-download-attachments"
            data-tip={t("components.sharepoint.downloadAttachments")}
            onClick={(e: any) => {
              e.preventDefault();
              onDownloadAttachments();
            }}
          >
            <Download />
          </NavLink>
          <ReactTooltip
            place="bottom"
            effect="solid"
            id="tooltip-download-attachments"
          />
          <ReactTooltip place="bottom" effect="solid" id="tooltip-reply" />
        </Nav>
      )}
      <button type="button" className="option-btn" onClick={handleNav}>
        <MoreVertical />
      </button>
    </StyledToolbar>
  );
};

export default ToolbarIcons;
