import React from "react";
import { SectionAbility } from "../../../types";
import { CardBody } from "../../Common/Card";
import Heading from "../../Common/Heading";
import { Media, MediaBody } from "../../UI/Media";
import Header from "../Header";
import { StyledCard } from "./style";
import { Col, Row } from "../../Common/Grid";
import { X, CheckSquare } from "react-feather";

type Props = {
  title: string;
  abilities: SectionAbility[];
};

export const Card: React.FC<Props> = ({ title, abilities }) => {
  return (
    <StyledCard mb={["20px", null, null, "25px"]}>
      <Header>
        <Heading tt="uppercase" fontWeight="600" mb="0px">
          {title}
        </Heading>
      </Header>
      <CardBody p={["20px", "20px", null, "25px"]}>
        <Media display={["block", "flex"]}>
          <MediaBody mt={["25px", 0]}>
            {abilities.map((ability) => (
              <Row alignItems="center" marginBottom="0.5rem" key={ability.key}>
                <Col sm>{ability.title}:</Col>
                <Col sm={7} textAlign="center">
                  {ability.isEnabled ? (
                    <CheckSquare color="#10b759" />
                  ) : (
                    <X color="#dc3545" />
                  )}
                </Col>
              </Row>
            ))}
          </MediaBody>
        </Media>
      </CardBody>
    </StyledCard>
  );
};
