import styled, { themeGet } from "../../../styled";

export const EmptyStateContainer = styled.div`
  height: 184px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 1rem;

  img {
    height: 116px;
  }
`;

export const StyledText = styled.small`
  color: ${themeGet("colors.text3")};
  font-size: 12px;
  margin-bottom: 0px;
  opacity: 0.75;
  margin-top: 0.5rem;
`;

export const StyledImg = styled.img`
  opacity: 0.3;
`;
