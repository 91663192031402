import i18n from "../../i18n";
import { BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { observer } from "mobx-react";
import { Stores } from "../../types";
import { SessionStore } from "../../stores/Session";
import { AppLoader } from "../../components/AppLoader";
import storageServices from "../../services/storage";
import { StoresContext } from "../../contexts";
import { Navigation } from "./Navigation";
import { UIStore } from "../../stores/UI";
import { ToastContainer } from "react-toastify";
import { SharepointStore } from "../../stores/Sharepoint";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const Root: React.FC = observer(() => {
  const [stores] = useState<Stores>(() => {
    const root: Stores = {} as Stores;

    root.session = new SessionStore(root);
    root.ui = new UIStore(root);
    root.sharepoint = new SharepointStore(root);

    return root;
  });

  useEffect(() => {
    i18n.changeLanguage("it");
  }, []);

  useEffect(() => {
    if (!stores.session.isInitialized) {
      let params = new URL(window.location.href).searchParams;
      console.log(params);
      let accessToken = params.get("accessToken");
      console.log(accessToken);
      if (accessToken) {
        storageServices.setItem("accessToken", { accessToken });
        // remove accessToken
        window.history.pushState({}, document.title, window.location.pathname);
      }

      stores.session.initialize();
    }
  }, []);

  if (!stores.session.isInitialized || !stores.session.user) {
    return <AppLoader />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <StoresContext.Provider value={stores}>
        <Router>
          <Navigation />
        </Router>
      </StoresContext.Provider>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        closeOnClick
      />
    </QueryClientProvider>
  );
});

export default styled(Root)`
  & {
    height: 100vh;
  }
`;
