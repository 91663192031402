import styled from "../../../styled";

export const StyledWrap = styled.div`
  padding: 10px;
`;

export const StyledDisclaimer = styled.div`
  font-size: 0.75rem;
  font-color: #566475;
  margin-left: 0.75rem;
  margin-top: 1.5rem;
  opacity: 0.65;
`;
