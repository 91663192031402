import { useMemo, useEffect, useCallback, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Main from "../../components/Sharepoint/Main";
import {
  useDownloadInboxMessageAttachments,
  useFetchInboxMessage,
  useFetchSentMessage,
  useTrakDownloadedAttachments,
  useTrakReadedMessage,
} from "../../hooks/sharepoint.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Attachment, Screens } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useActiveCompany } from "../../hooks/useActiveCompany";
import { useActiveUser } from "../../hooks/useActiveUser";
import { AttachmentActivitiesModal } from "../../components/Sharepoint/AttachmentActivitiesModal";
import { useDisclosure } from "../../hooks/useDisclosure";

export const Sharepoint: React.FC = observer(() => {
  const location = useLocation();
  const { session, sharepoint: sharepointStore } = useStores();
  const params = useParams();
  const config = useScreenConfig(Screens.SHAREPOINT);
  const { t } = useTranslation();
  const activeCompany = useActiveCompany();
  const activeUserId = useActiveUser();
  const [activeAttachment, setActiveAttachment] = useState<Attachment | null>(
    null
  );
  const { isOpen, open, close } = useDisclosure();

  const activeMessageId = useMemo(() => {
    return Number(params.messageId);
  }, [params]);

  const {
    data: inboxMessage = null,
    isFetching: isFetchingInboxMessage,
    refetch: refetchInboxMessage,
  } = useFetchInboxMessage(activeMessageId);

  const {
    data: sentMessage = null,
    isFetching: isFetchingSentMessage,
    refetch: refetchSentMessage,
  } = useFetchSentMessage(activeMessageId);

  const { refetch: downloadInboxMessageAttachments } =
    useDownloadInboxMessageAttachments(activeMessageId);

  const { mutateAsync: track } = useTrakReadedMessage();

  const { mutateAsync: trackAttachments } =
    useTrakDownloadedAttachments(activeMessageId);

  const activeField = useMemo(() => {
    return location.hash && location.hash.length > 0
      ? location.hash.replace("#", "")
      : "inbox";
  }, [location.hash]);

  const currentMessage = useMemo(() => {
    const message = inboxMessage || sentMessage;

    return message;
  }, [inboxMessage, sentMessage]);

  useEffect(() => {
    if (activeMessageId) {
      sharepointStore.setReadingMessageId(activeMessageId);
    }
  }, [activeMessageId]);

  useEffect(() => {
    return () => {
      sharepointStore.setReadingMessageId(null);
    };
  }, []);

  useEffect(() => {
    if (activeCompany) {
      if (activeField === "sent") {
        refetchSentMessage();
      } else {
        refetchInboxMessage();
      }
    }
  }, [
    activeMessageId,
    activeField,
    refetchSentMessage,
    refetchInboxMessage,
    activeCompany,
  ]);

  useEffect(() => {
    if (
      inboxMessage &&
      inboxMessage.Unreaded //&&
      //activeUserId === session.user?.Id
    ) {
      track(inboxMessage.Id);
    }
  }, [inboxMessage, track]);

  const handleDownloadAttachments = useCallback(async () => {
    const response = await downloadInboxMessageAttachments();
    const blob = response.data;
    if (blob) {
      //  Create blob link to download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${activeCompany}-${currentMessage?.Subject}.zip`
      );
      //  Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);

      if (inboxMessage && inboxMessage.Attachments) {
        trackAttachments(
          inboxMessage.Attachments.map((attachment) => attachment.Id)
        );
      }

      return true;
    } else {
      throw new Error("error");
    }
  }, [
    activeCompany,
    currentMessage,
    downloadInboxMessageAttachments,
    inboxMessage,
    trackAttachments,
  ]);

  const onDownloadAttachments = useCallback(() => {
    toast.promise(
      handleDownloadAttachments(),
      {
        pending: t("components.sharepoint.downloadingAttachments"),
        success: t("components.sharepoint.downloadCompleted"),
        error: "error",
      },
      {
        type: "default",
      }
    );
  }, [handleDownloadAttachments, t]);

  const onReply = useCallback(() => {
    if (inboxMessage && activeCompany) {
      sharepointStore.replayToMessage(inboxMessage, activeCompany);
    }
  }, [activeCompany, inboxMessage, sharepointStore]);

  const onAttachmentClick = useCallback(
    (attachment: Attachment) => {
      if (attachment.FileURL) {
        const link = document.createElement("a");
        link.href = attachment.FileURL;
        link.setAttribute("download", attachment.OriginalFileName);
        link.setAttribute("target", "_blank");
        //  Append to html page
        document.body.appendChild(link);
        // Force download
        link.click();
        // Clean up and remove the link
        link.parentNode?.removeChild(link);

        if (inboxMessage) {
          trackAttachments([attachment.Id]);
        }
      }
    },
    [inboxMessage, trackAttachments]
  );

  const onAttachmentDetailsClick = useCallback(
    (attachment: Attachment) => {
      setActiveAttachment(attachment);

      open();
    },
    [open]
  );

  const handleCloseAttachmentActivitiesModal = () => {
    close();
    setActiveAttachment(null);
  };

  return currentMessage ? (
    <>
      <Main
        message={currentMessage}
        config={config}
        isLoading={isFetchingInboxMessage || isFetchingSentMessage}
        onDownloadAttachments={onDownloadAttachments}
        type={inboxMessage ? "inbox" : "sent"}
        onReply={onReply}
        onAttachmentClick={onAttachmentClick}
        isInternalUser={!!session.user?.Internal}
        onAttachmentDetailsClick={onAttachmentDetailsClick}
      />
      {activeAttachment && (
        <AttachmentActivitiesModal
          onClose={handleCloseAttachmentActivitiesModal}
          show={isOpen}
          attachment={activeAttachment}
          messageId={activeMessageId}
        />
      )}
    </>
  ) : null;
});
