import { observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import Content from "../../components/Common/Content";
import { Main } from "../../components/Profile/Main";
import { Media, MediaBody } from "../../components/UI/Media";
import {
  useSetUserCompanyConfigs,
  useUserCompanyConfigs,
} from "../../hooks/userCompanyConfis.hooks";
import { useStores } from "../../hooks/useStores";
import { Screens, SetCompanyConfig, UserProfileConfig } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  useSetUserProfileConfigs,
  useUploadPicture,
} from "../../hooks/userProfileConfig.hooks";

export const Profile: React.FC = observer(() => {
  const { ui, session } = useStores();
  const { data: companyConfigs = [] } = useUserCompanyConfigs();
  const { mutateAsync: updateCompanyConfig } = useSetUserCompanyConfigs();
  const { mutateAsync: updateProfileConfig } = useSetUserProfileConfigs();
  const { mutateAsync: uploadPicture } = useUploadPicture();
  const { t } = useTranslation();

  useEffect(() => {
    ui.setActiveScreen(Screens.PROFILE);
  }, []);

  if (!session.user) {
    return null;
  }

  const handleUpdateCompanyConfig = useCallback(
    async (data: SetCompanyConfig) => {
      toast.promise(updateCompanyConfig(data), {
        pending: t("components.companyConfiguration.updatingConfiguration"),
        success: t("components.companyConfiguration.configurationUpdated"),
        error: "error",
      });
    },
    [t, updateCompanyConfig]
  );

  const handleUpdateUserProfileConfig = useCallback(
    async (data: UserProfileConfig) => {
      toast.promise(updateProfileConfig(data), {
        pending: t("components.profile.updatingConfiguration"),
        success: t("components.profile.profileUpdated"),
        error: "error",
      });
    },
    [t, updateProfileConfig]
  );

  const handleUploadPicture = useCallback(
    async (data: any) => {
      await uploadPicture(data.Picture);

      session.refreshUserData();
    },
    [session, uploadPicture]
  );

  return (
    <Content p={[null, null, null, "40px 0"]}>
      <Media display={["block", null, null, "flex"]}>
        <MediaBody mt={["40px", null, null, 0]} px={[null, null, null, "10px"]}>
          <Main
            user={session.user}
            companyConfigs={companyConfigs}
            onUpdateCompanyConfig={handleUpdateCompanyConfig}
            onUpdateProfileConfig={handleUpdateUserProfileConfig}
            onUploadPicture={handleUploadPicture}
          />
        </MediaBody>
      </Media>
    </Content>
  );
});
