import { FC, useState, useCallback } from "react";
import { Button } from "../../Common/Button";
import FeinarExtendedLogo from "../../../assets/images/logo-FEINAR-extended.png";
import { Menu, X } from "react-feather";
import {
  StyledHeader,
  StyledNavbarWrap,
  StyledNavbarMenu,
  StyleNavbarRight,
  StyledNavbarElement,
  StyledNavbarHeader,
  StyledNavbarBody,
  StyledMenuBtn,
  StyledFeinarLogo,
  StyledAppVersion,
} from "./style";
import ProfileDropdown from "./ProfileDropdown";
import {
  Company,
  ExternalLink,
  Notification,
  Screens,
  User,
} from "../../../types";
import CompanyDropdown from "./CompanyDropdown";
import { Navbar } from "../../Common/Navbar";
import { Breadcrumbs } from "./Breadcrumbs";
import { useHeaderTheme } from "../../../hooks/useHeaderTheme";
import NotificationDropdown from "./NotificationDropdown";
import CompanyAdvancedSwitch from "./CompanyAdvancedSwitch";
import env from "../../../config/env";

interface IProps {
  user: User | null;
  companies: Company[];
  activeCompany: Company | null;
  hasSidebar?: boolean;
  sidebarLayout?: 1 | 2;
  menu: any;
  activeScreen: Screens | null;
  unreadedNotifications: Notification[];
  notificationsEnabled?: boolean;
  externalLinks: ExternalLink[];
  onSetFavoriteCompany: (companyId: Company["Id"]) => any;
}

const Header: FC<IProps> = ({
  user,
  companies,
  activeCompany,
  hasSidebar,
  sidebarLayout,
  menu,
  activeScreen,
  unreadedNotifications,
  notificationsEnabled = false,
  externalLinks,
  onSetFavoriteCompany,
}) => {
  const sidebar = true;
  //const { sidebar, isBody } = useAppSelector((state) => state.ui);
  const theme = useHeaderTheme();

  const [menuOpen, setMenuOpen] = useState(false);
  //   const sidebarHandler = useCallback(
  //     (_, isOpen?: "open") => {
  //       dispatch(toggleSidebar({ isOpen }));
  //     },
  //     [dispatch]
  //   );

  //   const bodyHandler = useCallback(() => {
  //     dispatch(toggleBody());
  //     dispatch(toggleSidebar({ isOpen: "open" }));
  //   }, [dispatch]);

  const menuHandler = useCallback(() => {
    setMenuOpen((prev) => !prev);
    //   if (menuOpen) {
    //     sidebarHandler(undefined, "open");
    //   }
  }, []);

  //   useEffect(() => {
  //     return () => {
  //       sidebarHandler(undefined, "open");
  //       bodyHandler();
  //     };
  //   }, [sidebarHandler, bodyHandler]);

  return (
    <>
      <StyledHeader backgroundColor={theme.background}>
        {hasSidebar && sidebarLayout === 1 && (
          <>
            <StyledMenuBtn
              variant="texted"
              onClick={menuHandler}
              $hasSidebar={hasSidebar}
              $sidebarOpen={sidebar}
              $bodyOpen={false}
              className="menu-btn"
            >
              <Menu size={20} strokeWidth="2.5px" />
            </StyledMenuBtn>
            {/* <StyledSidebarBtn
              variant="texted"
              onClick={!isBody ? sidebarHandler : bodyHandler}
              $sidebarOpen={sidebar}
              $bodyOpen={isBody}
              className="sidebar-btn"
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn> */}
          </>
        )}
        {hasSidebar && sidebarLayout === 2 && (
          <>
            <StyledMenuBtn
              variant="texted"
              onClick={menuHandler}
              $hasSidebar={hasSidebar}
              $sidebarOpen={!sidebar}
            >
              <Menu size={20} strokeWidth="2.5px" />
            </StyledMenuBtn>
            {/* <StyledSidebarBtn
              variant="texted"
              onClick={sidebarHandler}
              $sidebarOpen={!sidebar}
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn> */}
          </>
        )}
        <StyledMenuBtn
          variant="texted"
          onClick={menuHandler}
          $hasSidebar={hasSidebar}
          $sidebarOpen={!sidebar}
        >
          <Menu size={20} strokeWidth="2.5px" />
        </StyledMenuBtn>
        <StyledNavbarElement ml={["8px", "15px"]}>
          {companies.length <= 3 ? (
            <CompanyDropdown
              companies={companies}
              activeCompany={activeCompany}
              onSetFavorite={onSetFavoriteCompany}
            />
          ) : (
            <CompanyAdvancedSwitch
              companies={companies}
              activeCompany={activeCompany}
              primaryColor={theme.background}
              onSetFavorite={onSetFavoriteCompany}
            />
          )}
        </StyledNavbarElement>
        {activeScreen && activeScreen !== Screens.HOME && (
          <Breadcrumbs
            activeScreen={activeScreen}
            activeCompany={activeCompany}
          />
        )}

        <StyledNavbarWrap $isOpen={menuOpen} onClick={menuHandler}>
          <StyledNavbarMenu
            $isOpen={menuOpen}
            onClick={(e) => e.stopPropagation()}
          >
            <StyledNavbarHeader>
              <Button variant="texted" onClick={menuHandler}>
                <X color="#7987a1" width={20} strokeWidth="2.5px" />
              </Button>
            </StyledNavbarHeader>
            <StyledNavbarBody>
              <Navbar menus={menu} onItemClick={menuHandler} />
            </StyledNavbarBody>
            <StyledAppVersion>{env.APP_VERSION}</StyledAppVersion>
          </StyledNavbarMenu>
        </StyledNavbarWrap>
        <StyleNavbarRight>
          <StyledNavbarElement ml={["8px", "15px"]}>
            {/* <MessageDropdown /> */}
          </StyledNavbarElement>
          {activeCompany && notificationsEnabled && (
            <StyledNavbarElement ml={["8px", "15px"]}>
              <NotificationDropdown
                activeCompanyId={activeCompany.Id}
                unreadedNotifications={unreadedNotifications}
              />
            </StyledNavbarElement>
          )}
          {user && (
            <StyledNavbarElement ml={["15px", "20px", "30px"]}>
              <ProfileDropdown
                user={user}
                company={activeCompany}
                externalLinks={externalLinks}
              />
            </StyledNavbarElement>
          )}
          <StyledNavbarElement ml={["8px", "15px"]}>
            <StyledFeinarLogo src={FeinarExtendedLogo} alt="Feinar" />
          </StyledNavbarElement>
        </StyleNavbarRight>
      </StyledHeader>
    </>
  );
};

Header.defaultProps = {
  sidebarLayout: 1,
};

export default Header;
