import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { QUERY_CONFIGS } from "../config/query";
import api from "../services/api";
import { Communication, Company, User } from "../types";
import { useActiveCompany } from "./useActiveCompany";
import { useActiveUser } from "./useActiveUser";

export const useCommunications = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    [`communications-${userId}-${activeCompany}`, userId, activeCompany],
    ({ pageParam = 0 }) =>
      api.fetchCommunications(
        userId || -1,
        activeCompany || -1,
        pageParam,
        QUERY_CONFIGS.limit
      ),
    {
      enabled: !!userId && !!activeCompany,
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.length > 0 ? lastPage.nextCursor : false,
    }
  );
};

export const useUserUnreadedCommunications = (
  userId: User["Id"],
  companyId: Company["Id"]
) =>
  useQuery(
    [`unreaded-communications-${userId}-${companyId}`, userId, companyId],
    () => api.fetchCommunications(userId, companyId, 0, 999999, true)
  );

export const useCommunication = (communicationId: Communication["Id"]) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [`communications-${communicationId}-${userId}`, communicationId, userId],
    () =>
      api.fetchCommunication(userId || -1, activeCompany || -1, communicationId)
  );
};

export const useTrakReadedCommunication = (
  userId: User["Id"],
  companyId: Company["Id"]
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (communicationId: Communication["Id"]) =>
      api.trackCommunicationActivity(userId, companyId, communicationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`communications-${userId}-${companyId}`);
        queryClient.invalidateQueries(
          `communications-fields-${userId}-${companyId}`
        );
        queryClient.invalidateQueries(
          `unreaded-communications-${userId}-${companyId}`
        );
      },
    }
  );
};

export const useDownloadCommunicationAttachments = (
  communicationId: Communication["Id"]
) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();
  return useQuery(
    [
      `attachments-communication-${userId}-${activeCompany}-${communicationId}`,
      communicationId,
    ],
    () =>
      api.downloadCommunicationsAttachments(
        userId || -1,
        activeCompany || -1,
        communicationId
      ),
    {
      enabled: false,
    }
  );
};
