import { FC } from "react";
import { Search } from "react-feather";
import { StyledGroup, StyledHeader, StyledBody, StyledList } from "./style";
import ScrollBar from "../../../Common/Scrollbar";
import SearchForm from "../SearchForm";
import { Company } from "../../../../types";
import CompanyGroupItem from "../CompanyGroupItem";
import { Checkbox } from "../../../Common/Forms/Checkbox";
import { useTranslation } from "react-i18next";

type Props = {
  companies: Company[];
  search: string;
  onSearchChange: (value: string) => void;
  onItemClick: (company: Company) => void;
  checkRole: boolean;
  onCheckRoleChange: (checked: boolean) => any;
};

const CompanyListGroup: FC<Props> = ({
  companies,
  search,
  checkRole,
  onSearchChange,
  onItemClick,
  onCheckRoleChange,
}) => {
  const { t } = useTranslation();
  return (
    <StyledGroup onScroll={(e) => e.stopPropagation()}>
      <StyledHeader>
        <Search className="search" />
        <SearchForm value={search} onChange={onSearchChange} />
        <div
          style={{
            marginTop: "0.2rem",
            marginLeft: "0.15rem",
            display: "flex",
            flexDirection: "row",
            width: "180px",
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <Checkbox
            label=""
            name={`check-role`}
            id={`check-role`}
            checked={checkRole}
            // color={color}
            onClick={(event) => {
              event.stopPropagation();
              // @ts-ignore
              onCheckRoleChange(event.target.checked);
            }}
          />
          <span style={{ marginLeft: "0.2rem" }}>
            {t("components.companyListGroup.checkRole")}
          </span>
        </div>
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <StyledList>
            {companies.map((company) => (
              <ItemListener
                key={company.Id}
                onClick={() => onItemClick(company)}
              >
                <CompanyGroupItem company={company} />
              </ItemListener>
            ))}
          </StyledList>
        </StyledBody>
      </ScrollBar>
    </StyledGroup>
  );
};

export default CompanyListGroup;

type ItemListenerClick = {
  onClick: () => any;
};

const ItemListener: React.FC<ItemListenerClick> = ({ children, onClick }) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      {children}
    </div>
  );
};
