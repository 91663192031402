import { FC, useMemo } from "react";
import MonthlyCostWidget from "../../components/BasicBusinessData/MonthlyCostWidget";
import { Col } from "../../components/Common/Grid";
import { BIMonthlyData, BusinessChart, BusinessCharts } from "../../types";

type Props = {
  monthlyData: BIMonthlyData[];
  charts: BusinessChart[];
};

const RowTwo: FC<Props> = ({ monthlyData, charts }) => {
  const years: { value: string; name: string }[] = useMemo(() => {
    const list: { value: string; name: string }[] = [];

    for (let sample of monthlyData) {
      if (!list.find((item) => item.value === String(sample.Anno))) {
        list.push({
          value: String(sample.Anno),
          name: String(sample.Anno),
        });
      }
    }
    return list;
  }, [monthlyData]);

  const activeChart = useMemo(() => {
    return charts.find((chart) => chart.Name === BusinessCharts.MONTHLY_COST);
  }, [charts]);

  const data: ({ month: string } & { [year: string]: number })[] =
    useMemo(() => {
      const list: ({ month: string } & { [year: string]: number })[] = [];

      for (let i = 0; i < monthlyData.length; i++) {
        const itemIndex = list.findIndex(
          (item) => item.month === monthlyData[i].DescrizioneMese
        );

        if (itemIndex < 0) {
          const record = {
            month: monthlyData[i].DescrizioneMese,
          };
          // @ts-ignore
          record[String(monthlyData[i].Anno)] = monthlyData[i].Costo;
          // @ts-ignore
          list.push(record);
        } else {
          list[itemIndex][String(monthlyData[i].Anno)] = monthlyData[i].Costo;
        }
      }

      return list;
    }, [monthlyData]);

  return (
    <Col lg={12} xl={12} mt="10px">
      <MonthlyCostWidget
        years={years}
        data={data}
        title={activeChart?.Title || ""}
      />
    </Col>
  );
};

export default RowTwo;
