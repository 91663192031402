import { useCallback, useMemo, useState } from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../../../hooks/useDebounce";
import { Company } from "../../../../types";
import { fuzzyMatch } from "../../../../utils/string";
import { Modal } from "../../../Common/Modal";
import { Nav, NavLink } from "../../../Common/Nav/nav";
import CompanyListGroup from "../CompanyListGroup";
import { StyledHeader, StyledTitle, StyledBody } from "./style";

type Props = {
  show: boolean;
  primaryColor: string;
  companies: Company[];
  handleOnClose: () => any;
};

const AdvancedSearchCompany: React.FC<Props> = ({
  show,
  primaryColor,
  companies,
  handleOnClose,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [checkRole, setCheckRole] = useState(false);
  const debouncedQuery = useDebounce(search, 300);
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    setSearch("");
    handleOnClose();
  }, [handleOnClose]);

  const handleClick = useCallback(
    (company: Company) => {
      navigate(`/company/${company.Id}`);
      onClose();
    },
    [onClose, navigate]
  );

  const filteredCompanies = useMemo(() => {
    const list =
      search.length > 0
        ? companies.filter(
            (company) =>
              String(company.Id) === debouncedQuery ||
              fuzzyMatch(
                company.BusinessName + company.ClientType,
                debouncedQuery
              )
          )
        : companies;

    return checkRole
      ? list.filter((company) => Boolean(company.UserRoleDefined))
      : list;
  }, [checkRole, companies, debouncedQuery, search.length]);

  const handleChangeCheckRole = useCallback((checked: boolean) => {
    setCheckRole(checked);
  }, []);

  return (
    <Modal show={show} onClose={onClose} closeOnClickBackdrop>
      <StyledHeader backgroundColor={primaryColor}>
        <StyledTitle>{t("components.menu.changeCompany")}</StyledTitle>
        <Nav customStyle="icon">
          <NavLink path="#!" onClick={onClose}>
            <X />
          </NavLink>
        </Nav>
      </StyledHeader>
      <StyledBody>
        <CompanyListGroup
          companies={filteredCompanies}
          search={search}
          onSearchChange={(value) => setSearch(value)}
          onItemClick={handleClick}
          checkRole={checkRole}
          onCheckRoleChange={handleChangeCheckRole}
        />
      </StyledBody>
    </Modal>
  );
};

export default AdvancedSearchCompany;
