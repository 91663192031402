import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Group from "../../components/Communications/Group";
import { useCommunicationsFields } from "../../hooks/communicationField.hooks";
import { useCommunications } from "../../hooks/communications.hooks";
import useDebounce from "../../hooks/useDebounce";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Communication, CommunicationFilters, Screens } from "../../types";
import { fuzzyMatch } from "../../utils/string";

export const Communications: React.FC = observer(() => {
  const location = useLocation();
  const { t } = useTranslation();
  const { session } = useStores();
  const [search, setSearch] = useState("");
  const debouncedQuery = useDebounce(search, 300);
  const config = useScreenConfig(Screens.COMMUNICATIONS);

  const {
    isError,
    error,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage = false,
  } = useCommunications();

  const { data: communicationsFields = [] } = useCommunicationsFields();

  const activeField = useMemo(() => {
    return location.hash && location.hash.length > 0
      ? location.hash.replace("#", "")
      : "inbox";
  }, [location.hash]);

  const fieldDescription = useMemo(() => {
    let label: string;
    if (activeField === "inbox") {
      label = t("components.communications.inbox");
    } else {
      // #1-2 => # CommunicationId - FieldIf
      const field = communicationsFields.find(
        (f) => `${f.CommunicationId}-${f.Id}` === activeField
      );
      label = field
        ? `${field.CommunicationDescription} ${field.Description}`
        : "";
    }
    return label;
  }, [activeField, communicationsFields, t]);

  const communications: Communication[] = useMemo(() => {
    return data?.pages.map((page) => page.data).flat() || [];
  }, [data]);

  const filteredCommunications = useMemo(() => {
    let filteredCommunications =
      activeField === "inbox"
        ? communications
        : communications.filter(
            (communication) =>
              `${communication.CommunicationId}-${communication.Field.Id}` ===
              activeField
          );
    if (session.communicationStatusFilter === CommunicationFilters.UNREADED) {
      filteredCommunications = filteredCommunications.filter(
        (communication) => communication.Unreaded
      );
    }

    return debouncedQuery.length > 0
      ? filteredCommunications.filter((communication) =>
          fuzzyMatch(communication.Subject, debouncedQuery)
        )
      : filteredCommunications;
  }, [
    activeField,
    debouncedQuery,
    communications,
    session.communicationStatusFilter,
  ]);

  return (
    <Group
      communications={filteredCommunications}
      isFetchingNextPage={isFetchingNextPage}
      search={search}
      onLoadMore={fetchNextPage}
      hasNextPage={hasNextPage}
      onSearchChange={setSearch}
      fieldDescription={fieldDescription}
      filter={session.communicationStatusFilter}
      onFilterChange={(e) => session.setCommunicationsFilter(e.target.value)}
      config={config}
    />
  );
});
