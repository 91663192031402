import { EmptyStateContainer, StyledImg, StyledText } from "./style";
import EmptyState from "../../../assets/images/empty-state-bi.png";
import { useTranslation } from "react-i18next";

type Props = {
  message?: string;
};

export const BasicBusinessDataEmptyState: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <EmptyStateContainer>
      <StyledImg src={EmptyState} alt="Empty" />
      <StyledText>
        {message || t("components.basicBusinessData.emptyState")}
      </StyledText>
    </EmptyStateContainer>
  );
};
