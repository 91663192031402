import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../services/api";
import { useActiveCompany } from "./useActiveCompany";
import { useActiveUser } from "./useActiveUser";

export const useUserAccessConfiguration = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [`access-configuration-${userId}-${activeCompany}`, userId, activeCompany],
    () => api.fetchUserAccessConfiguration(userId || -1, activeCompany || -1),
    {
      enabled: !!userId && !!activeCompany,
    }
  );
};

export const useConfirmUserAccessConfiguration = () => {
  const queryClient = useQueryClient();
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useMutation(
    () => api.confirmUserAccessConfiguration(userId || -1, activeCompany || -1),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `access-configuration-${userId}-${activeCompany}`
        );
      },
    }
  );
};

export const useRevokeUserAccessConfiguration = () => {
  const queryClient = useQueryClient();
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useMutation(
    () => api.revokeUserAccessConfiguration(userId || -1, activeCompany || -1),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `access-configuration-${userId}-${activeCompany}`
        );
      },
    }
  );
};
