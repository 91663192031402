import { useEffect, useState } from "react";
import { User } from "../types";
import { useStores } from "./useStores";

export const useSessionActiveUser = () => {
  const { session } = useStores();
  const [activeUserId, setActiveUserId] = useState<User["Id"] | null>(null);

  useEffect(() => {
    if (session.user) {
      setActiveUserId(session.user.Id);
    }
  }, [session.user]);

  return activeUserId;
};
