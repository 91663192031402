import {
  Application,
  ApplicationAccessConfiguration,
  Attachment,
  AttachmentActivity,
  BIMonthlyData,
  BIYearlyData,
  BusinessChart,
  Communication,
  CommunicationAccessConfiguration,
  CommunicationField,
  CommunicationsResponse,
  Company,
  CompanyConfig,
  ComposeMessage,
  DataAnalysis,
  DataAnalysisAccessConfiguration,
  ExternalLink,
  Field,
  Message,
  MessagesResponse,
  Notification,
  NotificationAccessConfiguration,
  NotificationField,
  NotificationsResponse,
  SharepointCCN,
  SharepointField,
  SharepointRecipient,
  SupervisionedUser,
  User,
  UserAccessConfiguration,
  UserCompanyAbilities,
  UserCompanyDistributionLists,
} from "../types";

export const toUser = (data: any): User => ({
  Id: data.Id,
  IsActive: data.IsActive,
  Firstname: data.Firstname,
  Lastname: data.Lastname,
  LoginAlias: data.LoginAlias,
  Email: data.Email,
  Picture: data.Picture,
  DaysUntilPasswordExpirationDate: data.DaysUntilPasswordExpirationDate,
  ExpirationDate: new Date(data.ExpirationDate),
  Internal: data.Internal,
  Field: data.Field,
  MobileNumber: data.MobileNumber,
  PhoneNumber: data.PhoneNumber,
  Confirmed: data.Confirmed,
  OtpChannel: data.OtpChannel,
  FiscalCode: data.FiscalCode,
  IsMasterUser: !!data.IsMasterUser,
});

export const toCompany = (data: any): Company => ({
  Id: data.Id,
  BusinessName: data.BusinessName,
  Logo: data.Logo,
  FiscalCode: data.FiscalCode,
  VatNumber: data.VatNumber,
  ClientType: data.ClientType,
  Favorite: !!data.Favorite,
  IsMasterUser: !!data.IsMasterUser,
  FileStorageDays: data.FileStorageDays,
  UnreadedMessages: data.UnreadedMessages,
  UserRoleDefined: data.UserRoleDefined,
});

export const toCommunicationField = (data: any): CommunicationField => ({
  Id: data.Id,
  Description: data.Description,
  TotalUnreaded: data.TotalUnreaded,
  CommunicationDescription: data.CommunicationDescription,
  CommunicationId: data.CommunicationId,
});

export const toNotificationField = (data: any): NotificationField => ({
  Id: data.Id,
  Description: data.Description,
  TotalUnreaded: data.TotalUnreaded,
});

export const toSharepointField = (data: any): SharepointField => ({
  Id: data.Id,
  Description: data.Description,
  TotalUnreaded: data.TotalUnreaded,
});

export const toApplication = (data: any): Application => ({
  Id: data.Id,
  Description: data.Description,
  ExtendedDescription: data.ExtendedDescription,
  Url: data.Url,
  Enabled: data.Enabled,
  Color: data.Color,
  Logo: data.Logo,
});

export const toField = (data: any): Field => ({
  Id: data.Id,
  Description: data.Description,
});

export const toSharepointRecipient = (data: any): SharepointRecipient => ({
  Id: data.Id,
  Email: data.Email,
  Firstname: data.Firstname,
  Lastname: data.Lastname,
  Field: data.Field
    ? {
        Id: data.Field.Id,
        Description: data.Field.Description,
      }
    : undefined,
  DefaultRecipient: !!data.DefaultRecipient,
});

export const toAttachment = (data: any): Attachment => ({
  Id: data.Id,
  FileName: data.FileName,
  MimeType: data.MimeType,
  OriginalFileName: data.OriginalFileName,
  Size: data.Size,
  FileURL: data.FileURL,
  MessageId: data.MessageId,
  Downloaded: !!data.Downloaded,
});

export const toMessage = (data: any): Message => ({
  Id: data.Id,
  Subject: data.Subject,
  Text: data.Text,
  Author: toSharepointRecipient(data.Author),
  Field: toField(data.Field),
  CreatedAt: new Date(data.CreatedAt),
  Attachments: data.Attachments
    ? data.Attachments.map((attachment: any) => toAttachment(attachment))
    : undefined,
  Unreaded: !!data.Unreaded,
  Status: data.Status,
  Recipients: data.Recipients
    ? data.Recipients.map((recipient: any) => toSharepointRecipient(recipient))
    : undefined,
  SentAt: data.SentAt ? new Date(data.SentAt) : null,
  CCN: data.CCN
    ? data.CCN.map((recipient: any) => toSharepointCCN(recipient))
    : undefined,
  IsVisibile: data.IsVisibile,
});

export const toMessagesResponse = (payload: any): MessagesResponse => ({
  data: payload.data.map((record: any) => toMessage(record)),
  nextCursor: payload.nextCursor,
});

export const toNotification = (data: any): Notification => ({
  Id: data.Id,
  Subject: data.Subject,
  Text: data.Text,
  Field: toField(data.Field),
  CreatedAt: new Date(data.CreatedAt),
  Unreaded: !!data.Unreaded,
});

export const toNotificationsResponse = (
  payload: any
): NotificationsResponse => ({
  data: payload.data.map((record: any) => toNotification(record)),
  nextCursor: payload.nextCursor,
});

export const toCommunication = (data: any): Communication => ({
  Id: data.Id,
  Subject: data.Subject,
  Text: data.Text,
  Field: toField(data.Field),
  CreatedAt: new Date(data.CreatedAt),
  Unreaded: !!data.Unreaded,
  Attachments: data.Attachments
    ? data.Attachments.map((attachment: any) => toAttachment(attachment))
    : undefined,
  CommunicationId: data.CommunicationId,
  CommunicationDescription: data.CommunicationDescription,
});

export const toCommunicationsResponse = (
  payload: any
): CommunicationsResponse => ({
  data: payload.data.map((record: any) => toCommunication(record)),
  nextCursor: payload.nextCursor,
});

export const messageToComposeMessage = (message: Message): ComposeMessage => ({
  Id: message.Id,
  Subject: message.Subject,
  Text: message.Text,
  Attachments: message.Attachments,
  FieldId: message.Field?.Id,
  Recipients: message.Recipients?.map((recipient) => recipient.Id) || [],
  CCN: message.CCN?.map((ccnRecipient) => ccnRecipient.RoleId) || [],
});

export const toUserCompanyAbilities = (data: any): UserCompanyAbilities => ({
  applications: data.applications,
  communications: data.communications,
  notifications: data.notifications,
  dataAnalysis: data.dataAnalysis,
});

export const toSharepointCCN = (data: any): SharepointCCN => ({
  RoleId: data.RoleId,
  Description: data.Description,
});

export const toCompanyConfig = (data: any): CompanyConfig => ({
  BusinessName: data.BusinessName,
  Email: data.Email,
  Favorite: data.Favorite,
  Id: data.Id,
  MobileNumber: data.MobileNumber,
});

export const toAttachmentActivity = (data: any): AttachmentActivity => ({
  ActivityDate: new Date(data.ActivityDate),
  AttachmentId: data.AttachmentId,
  Id: data.Id,
  Firstname: data.Firstname,
  Lastname: data.Lastname,
  Picture: data.Picture,
});

export const toDataAnalysis = (data: any): DataAnalysis => ({
  Id: data.Id,
  Description: data.Description,
  ExtendedDescription: data.ExtendedDescription,
  Logo: data.Logo,
});

export const toBusinessChart = (data: any): BusinessChart => ({
  Id: data.Id,
  Name: data.Name,
  Field: toField(data.Field),
  Title: data.Title,
});

export const toBIMonthlyData = (data: any): BIMonthlyData => ({
  Anno: data.Anno,
  Costo: data.Costo,
  DescrizioneMese: data.DescrizioneMese,
  FondoFeriePermessiBancaOre: data.FondoFeriePermessiBancaOre,
  Mese: data.Mese,
  OreLavorate: data.OreLavorate,
});

export const toBIYearlyData = (data: any): BIYearlyData => ({
  Anno: data.Anno,
  CostoTotale: data.CostoTotale,
  CostoTotaleAnnoPrec: data.CostoTotaleAnnoPrec,
  DeltaCostoTotale: data.DeltaCostoTotale,
  DeltaCostoTotalePercent: data.DeltaCostoTotalePercent,
  TotFemmine: data.TotFemmine,
  TotMaschi: data.TotMaschi,
});

export const toExternalLinks = (data: any): ExternalLink => ({
  Description: data.Description,
  Name: data.Name,
});

const toApplicationAccessConfiguration = (
  data: any
): ApplicationAccessConfiguration => ({
  Id: data.Id,
  Description: data.Description,
  UserIsEnabled: data.UserIsEnabled,
});

const toCommunicationAccessConfiguration = (
  data: any
): CommunicationAccessConfiguration => ({
  ...toField(data),
  UserIsEnabled: data.UserIsEnabled,
});

const toNotificationAccessConfiguration = (
  data: any
): NotificationAccessConfiguration => ({
  ...toField(data),
  UserIsEnabled: data.UserIsEnabled,
});

const toDataAnalysisAccessConfiguration = (
  data: any
): DataAnalysisAccessConfiguration => ({
  ...toDataAnalysis(data),
  UserIsEnabled: data.UserIsEnabled,
  Field: toField(data.Field),
});

export const toUserAccessConfiguration = (
  data: any
): UserAccessConfiguration => ({
  User: toUser(data.User),
  Applications: data.Applications.map(toApplicationAccessConfiguration),
  Communications: data.Communications.map(toCommunicationAccessConfiguration),
  Notifications: data.Notifications.map(toNotificationAccessConfiguration),
  DataAnalysis: data.DataAnalysis.map(toDataAnalysisAccessConfiguration),
});

export const toUserCompanyDistributionLists = (
  data: any
): UserCompanyDistributionLists => ({
  Id: data.Id,
  Description: data.Description,
  Recipients: data.Recipients.map(toSharepointRecipient),
});

export const toSupervisionedUser = (data: any): SupervisionedUser => ({
  ...toUser(data),
  IsEmailEnabled: data.IsEmailEnabled,
  TotalSupervisedUnreaded: data.TotalSupervisedUnreaded,
  TotalSupervisorUnreaded: data.TotalSupervisorUnreaded,
  TotalUnreaded: data.TotalUnreaded,
});
