import { FC } from "react";
import { Card, CardBody } from "../../Common/Card";
import SectionTitle from "../../Common/SectionTitle";
import { StyledHeader } from "./style";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  ValueAxis,
  Title,
  Tooltip,
} from "devextreme-react/chart";
import { useTranslation } from "react-i18next";

type Props = {
  data: { year: string; men: number; women: number }[];
  title: string;
};

const TotalEmployeesWidget: FC<Props> = ({ data, title }) => {
  const { t } = useTranslation();

  const customizeTooltip = (arg: any) => {
    return {
      text: `${arg.valueText}`,
    };
  };

  return (
    <Card height={[null, null, null, "100%"]}>
      <StyledHeader>
        <SectionTitle title={title} />
      </StyledHeader>
      <CardBody p={["10px", "10px"]}>
        <Chart id="chart-total-employee" dataSource={data}>
          <CommonSeriesSettings argumentField="year" type="stackedBar" />
          <Series
            valueField="men"
            name={t("components.basicBusinessData.charts.labels.men")}
          />
          <Series
            valueField="women"
            name={t("components.basicBusinessData.charts.labels.women")}
          />
          <ValueAxis position="left">
            <Title
              text={t("components.basicBusinessData.charts.labels.total")}
            />
          </ValueAxis>
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
            itemTextPosition="top"
          />
          <Tooltip
            enabled={true}
            location="edge"
            customizeTooltip={customizeTooltip}
          />
        </Chart>
      </CardBody>
    </Card>
  );
};

export default TotalEmployeesWidget;
