import { useQuery } from "react-query";
import api from "../services/api";
import { useActiveCompany } from "./useActiveCompany";
import { useActiveUser } from "./useActiveUser";

export const useCommunicationsFields = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [`communications-fields-${userId}-${activeCompany}`, userId, activeCompany],
    () => api.fetchCommunicationsFields(userId || -1, activeCompany || -1),
    {
      enabled: !!userId && !!activeCompany,
    }
  );
};
