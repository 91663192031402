import styled, { device } from "../../../styled";
import { CardFooter } from "../../Common/Card";

export const StyledFooter = styled(({ ...rest }) => <CardFooter {...rest} />)`
  background-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${device.small} {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
