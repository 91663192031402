import { FC, useMemo, useState } from "react";
import { ArrowLeft } from "react-feather";
import ScrollBar from "../../Common/Scrollbar";
import {
  StyledMain,
  StyledHeader,
  StyledBody,
  StyledToggleBtn,
  StyledAttachmentsSearchForm,
} from "./style";
import Sender from "../Sender";
import ToolbarIcons from "../ToolbarIcons";
import MailBody from "../MailBody";
import {
  Attachment as TAttachment,
  Message,
  ScreenConfig,
} from "../../../types";
import { Spinner } from "../../Common/Spinner";
import Attachment from "../Attachment";
import { Col, Row } from "../../Common/Grid";
import SearchForm from "../SearchForm";
import { Search } from "react-feather";
import { useTranslation } from "react-i18next";
import { fuzzyMatch } from "../../../utils/string";
import Text from "../../Common/Text";
import { Footer } from "../../Layout/Footer";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type Props = {
  message: Message | null;
  config: ScreenConfig;
  isLoading?: boolean;
  type: "inbox" | "sent";
  isInternalUser: boolean;
  onDownloadAttachments: () => any;
  onReply: () => any;
  onAttachmentClick: (attachment: TAttachment) => void;
  onAttachmentDetailsClick: (attachment: TAttachment) => void;
};

const Main: FC<Props> = ({
  message,
  config,
  isLoading = false,
  type,
  isInternalUser,
  onDownloadAttachments,
  onReply,
  onAttachmentClick,
  onAttachmentDetailsClick,
}) => {
  const { t } = useTranslation();
  const [searchAttachments, setSearchAttachments] = useState("");

  const filteredAttachments = useMemo(() => {
    if (!message || (Boolean(message) && !message.Attachments)) {
      return [];
    }

    return searchAttachments.length > 0 && message.Attachments
      ? message.Attachments.filter((attachment) =>
          fuzzyMatch(attachment.OriginalFileName, searchAttachments)
        ) || []
      : message.Attachments || [];
  }, [message, searchAttachments]);

  const bodyHandler = () => {
    // dispatch(toggleBody());
  };

  return (
    <StyledMain>
      <StyledHeader>
        <StyledToggleBtn type="button" onClick={bodyHandler}>
          <ArrowLeft />
        </StyledToggleBtn>
        {isLoading ? (
          <Spinner />
        ) : message ? (
          <Sender
            color={config.theme.primaryColor}
            name={
              type === "inbox"
                ? `${message.Author.Firstname} ${message.Author.Lastname}`
                : message.Recipients?.map(
                    (recipient) =>
                      `${recipient.Firstname} ${recipient.Lastname}`
                  ).join(", ") || ""
            }
            time={dayjs.utc(message.CreatedAt).format("DD/MM/YYYY HH:mm")}
          />
        ) : null}
        <ToolbarIcons
          type={type}
          onDownloadAttachments={onDownloadAttachments}
          hasAttachments={
            // @ts-ignore
            Boolean(message?.Attachments) && message?.Attachments.length > 0
          }
          onReply={onReply}
        />
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          {!isLoading && message && <MailBody message={message} />}

          <Row paddingX={"30px"}>
            {message &&
            message.Attachments &&
            message.Attachments.length > 0 ? (
              <StyledAttachmentsSearchForm>
                <Search color="#8392a5" width={"18px"} />
                <div style={{ paddingLeft: "10px" }}>
                  <SearchForm
                    onChange={setSearchAttachments}
                    value={searchAttachments}
                    placeholder={t("components.sharepoint.searchAttachments")}
                  />
                </div>
              </StyledAttachmentsSearchForm>
            ) : null}
            <Col>
              {filteredAttachments.map((attachment: TAttachment) => (
                <Attachment
                  key={attachment.FileName}
                  files={0}
                  link={attachment.FileURL}
                  size={String(attachment.Size)}
                  title={attachment.OriginalFileName}
                  onAttachmentClick={() => onAttachmentClick(attachment)}
                  isInternalUser={isInternalUser}
                  onMoreClick={() => onAttachmentDetailsClick(attachment)}
                />
              ))}
              {filteredAttachments.length === 0 &&
              message &&
              message.Attachments &&
              message.Attachments.length > 0 ? (
                <Text>{t("components.sharepoint.emptySearchAttachments")}</Text>
              ) : null}
            </Col>
          </Row>
        </StyledBody>
        <Footer />
      </ScrollBar>
    </StyledMain>
  );
};

export default Main;
