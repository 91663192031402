import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { menus } from "../config/menu";
import { classicTheme } from "../styled";

const DEFAULT_THEME = {
  background: "#002E6B",
  foreground: classicTheme.colors.black,
};

export const useHeaderTheme = () => {
  const location = useLocation();
  const [theme, setTheme] = useState(DEFAULT_THEME);

  useEffect(() => {
    const paths = location.pathname.split("/");

    const routeTheme = menus.find((menu) => paths.includes(menu.key));

    setTheme(routeTheme?.theme || DEFAULT_THEME);
  }, [location]);

  return theme;
};
