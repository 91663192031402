import { useEffect } from "react";
import { observer } from "mobx-react";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { AppLayout } from "../AppLayout";
import { Home } from "../Home";
import { Logout } from "../Logout";
import { PrivateRoute } from "../../components/PrivateRoute";
import { Communications } from "../Communications";
import { Notifications } from "../Notifications";
import { Applications } from "../Applications";
import { Profile } from "../Profile";
import { Sharepoint } from "../Sharepoint";
import { CommunicationDetail } from "../CommunicationDetail";
import { NotificationDetail } from "../NotificationDetail";
import { NotificationsLayout } from "../NotificationsLayout";
import { CommunicationsLayout } from "../CommunicationsLayout";
import { SharepointLayout } from "../SharepointLayout";
import { useActiveCompany } from "../../hooks/useActiveCompany";
import { BasicBusinessData } from "../BasicBusinessData";
import { AccessConfiguration } from "../AccessConfiguration";
import { Forbidden } from "./Forbidden";

const RedirectToCompany: React.FC = observer(() => {
  const { session } = useStores();

  return session.isAuthenticated && session.defaultCompany ? (
    <Navigate to={`/company/${session.defaultCompany.Id}`} />
  ) : (
    <Forbidden />
  );
});

const CompanyRouter: React.FC = observer(({ children }) => {
  const params = useParams();
  const navigate = useNavigate();

  const { session } = useStores();

  useEffect(() => {
    if (params.companyId) {
      if (
        session.companies.find(
          (company) => company.Id === Number(params.companyId)
        )
      ) {
        session.setActiveCompanyId(parseInt(params.companyId));
      } else {
        navigate("/company", { replace: true });
      }
    }
  }, [params.companyId, session, navigate]);

  return <Outlet />;
});

export const Navigation: React.FC = observer(() => {
  useActiveCompany();
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<AppLayout />}>
          <Route path="company">
            <Route index element={<RedirectToCompany />} />
            <Route path=":companyId/*" element={<CompanyRouter />}>
              <Route path="home" element={<Home />} />
              <Route path="communications/*" element={<CommunicationsLayout />}>
                <Route index element={<Communications />} />
                <Route
                  path=":communicationId"
                  element={<CommunicationDetail />}
                />
              </Route>
              <Route path="notifications/*" element={<NotificationsLayout />}>
                <Route index element={<Notifications />} />
                <Route
                  path=":notificationId"
                  element={<NotificationDetail />}
                />
              </Route>
              <Route path="applications" element={<Applications />} />
              <Route path="user-profile" element={<Profile />} />
              <Route path="sharepoint/*" element={<SharepointLayout />}>
                <Route path=":messageId" element={<Sharepoint />} />
              </Route>
              <Route
                path="data-analysis/:dataAnalysisId"
                element={<BasicBusinessData />}
              />
              <Route
                path="access-configuration"
                element={<AccessConfiguration />}
              />
              <Route path="*" element={<Navigate to="home" />} />
            </Route>
            <Route path="*" element={<RedirectToCompany />} />
          </Route>
          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<Navigate to="/company" />} />
        </Route>
      </Route>
    </Routes>
  );
});
