import { observer } from "mobx-react";
import { useMemo, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ComposeMail from "../../components/Sharepoint/ComposeMail";
import Group from "../../components/Sharepoint/Group";
import SetupMailForwardingModal from "../../components/Sharepoint/SetupMailForwardingModal";
import Sidebar from "../../components/Sharepoint/Sidebar";
import Wrapper from "../../components/Sharepoint/Wrapper";
import {
  useFetchInboxMessages,
  useSendMessage,
  useSharepointRecipients,
  useFetchSentMessages,
  useSharepointFields,
  useDeleteMessage,
  useUpdateMessage,
  useDeleteAttachment,
  useMessagesBulkAction,
  useSharepointCCN,
  useSharepointSupervisionedUsers,
  useUserCompanyDistributionLists,
  useUpdateSharepointSupervisionedUser,
  useUploadAttachment,
} from "../../hooks/sharepoint.hooks";
import { useActiveUser, useActiveUserData } from "../../hooks/useActiveUser";
import useDebounce from "../../hooks/useDebounce";
import { useDisclosure } from "../../hooks/useDisclosure";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { messageToComposeMessage } from "../../services/utils";
import {
  ComposeMessage,
  Message,
  MessagesBulkAction,
  Screens,
  SharepointFilters,
  SupervisionedUser,
} from "../../types";

export const SharepointLayout: React.FC = observer(() => {
  const location = useLocation();
  const { session, ui, sharepoint: sharepointStore } = useStores();

  const config = useScreenConfig(Screens.SHAREPOINT);
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [checkedMessages, setCheckedMessages] = useState<number[]>([]);
  const debouncedQuery = useDebounce(search, 300);
  const { refetch: refetchSupervisionedUsers, data: supervisionedUsers = [] } =
    useSharepointSupervisionedUsers();
  const navigate = useNavigate();
  const activeUserId = useActiveUser();
  const activeUserData = useActiveUserData();
  const {
    close: closeSettingsModal,
    isOpen: isSettingsModalOpen,
    open: openSettingsModal,
  } = useDisclosure();
  const { mutateAsync: updateSupervisionedUser } =
    useUpdateSharepointSupervisionedUser();

  const { data: fields = [] } = useSharepointFields();

  const { data: sharepointRecipients = [] } = useSharepointRecipients(
    session.activeCompanyId || -1
  );
  const { isLoading: isSubmitting, mutateAsync: sendMessage } =
    useSendMessage();
  const { isLoading: isUpdating, mutateAsync: updateMessage } =
    useUpdateMessage();
  const { isLoading: isDeleting, mutateAsync: deleteMessage } =
    useDeleteMessage();
  const {
    data: inboxMessagesInfiniteData,
    isFetching: isFetchingInbox,
    isFetchingNextPage: isFetchingNextPageInbox,
    fetchNextPage: fetchNextPageInbox,
    hasNextPage: hasNextPageInbox = false,
    refetch: refetchInboxMessages,
  } = useFetchInboxMessages(debouncedQuery);
  const { mutateAsync: deleteAttachment } = useDeleteAttachment();
  const { isFetching: isFetchingCCN, data: ccnRecipients = [] } =
    useSharepointCCN();
  const { data: userCompanyDistributionLists = [] } =
    useUserCompanyDistributionLists(session.activeCompanyId || -1);

  const {
    data: sentMessagesInfiniteData,
    fetchNextPage: fetchNextPageSent,
    hasNextPage: hasNextPageSent = false,
    isFetching: isFetchingSent,
    isFetchingNextPage: isFetchingNextPageSent,
    refetch: refetchSentMessages,
  } = useFetchSentMessages(debouncedQuery);
  const { mutateAsync: performBulkAction } = useMessagesBulkAction();

  const { mutateAsync: uploadFile } = useUploadAttachment(
    sharepointStore.activeMessage?.Id || -1,
    sharepointStore.onUploadProgress
  );

  const filter = useMemo(() => {
    return sharepointStore.inboxFilter;
  }, [sharepointStore.inboxFilter]);

  const activeField = useMemo(() => {
    return location.hash && location.hash.length > 0
      ? location.hash.replace("#", "")
      : "inbox";
  }, [location.hash]);

  const fieldDescription = useMemo(() => {
    return ["inbox", "sent", "drafts"].includes(activeField)
      ? t(`components.sharepoint.${activeField}`)
      : fields.find((f) => f.Id === Number(activeField))?.Description || "";
  }, [activeField, fields, t]);

  const inboxMessages: Message[] = useMemo(() => {
    let filteredInboxMessages =
      inboxMessagesInfiniteData?.pages.map((page) => page.data).flat() || [];

    filteredInboxMessages =
      activeField === "inbox"
        ? filteredInboxMessages
        : filteredInboxMessages.filter(
            (message) => message.Field.Id === Number(activeField)
          );

    // if (filter === SharepointFilters.UNREADED) {
    //   filteredInboxMessages = filteredInboxMessages.filter(
    //     (message) =>
    //       message.Unreaded ||
    //       (sharepointStore.readingMessageId &&
    //         message.Id === sharepointStore.readingMessageId)
    //   );
    // }

    // Mark messages as readed (for the current session )
    filteredInboxMessages = filteredInboxMessages.map((message) =>
      sharepointStore.readedMessages.includes(message.Id)
        ? { ...message, Unreaded: false }
        : message
    );

    return filteredInboxMessages;
    // return debouncedQuery.length > 0
    //   ? filteredInboxMessages.filter((message) =>
    //       fuzzyMatch(message.Subject + message.Text, debouncedQuery)
    //     )
    //   : filteredInboxMessages;
  }, [inboxMessagesInfiniteData, activeField, sharepointStore.readedMessages]);

  const bulkActionInboxMessages = useMemo(() => {
    if (inboxMessages.length === 0 || checkedMessages.length === 0) {
      return undefined;
    }

    const checkedMessagesStatus = checkedMessages.map(
      (checkedMessageId) =>
        inboxMessages.find((message) => message.Id === checkedMessageId)
          ?.Unreaded || false
    );

    // Se alcuni messaggi sono da leggere, abilita la funzione 'da leggere', 'letti' altrimenti
    return checkedMessagesStatus.some((status) => !status)
      ? MessagesBulkAction.MARK_UNREADED
      : MessagesBulkAction.MARK_READED;
  }, [checkedMessages, inboxMessages]);

  const draftMessagesCount = useMemo(
    () =>
      (
        sentMessagesInfiniteData?.pages.map((page) => page.data).flat() || []
      ).filter((message: Message) => message.Status === "draft").length,
    [sentMessagesInfiniteData]
  );

  const sentMessages: Message[] = useMemo(() => {
    let sentMessages = (
      sentMessagesInfiniteData?.pages.map((page) => page.data).flat() || []
    ).filter((message: Message) =>
      activeField === "sent"
        ? message.Status === "sent"
        : message.Status === "draft"
    );

    return sentMessages;

    // return debouncedQuery.length > 0
    //   ? sentMessages.filter((message) =>
    //       fuzzyMatch(message.Subject + message.Text, debouncedQuery)
    //     )
    //   : sentMessages;
  }, [activeField, sentMessagesInfiniteData?.pages]);

  const bulkActionChangeVisibility = useMemo(() => {
    if (sentMessages.length === 0 || checkedMessages.length === 0) {
      return undefined;
    }

    const checkedMessagesAreVisibiles = checkedMessages.map(
      (checkedMessageId) =>
        sentMessages.find((message) => message.Id === checkedMessageId)
          ?.IsVisibile
    );

    // Se alcuni messaggi sono nascosti, abilita la funzione 'pubblica messaggi', 'nascondi' altrimenti
    return checkedMessagesAreVisibiles.some((visibile) => !visibile)
      ? MessagesBulkAction.MARK_AS_SENT
      : MessagesBulkAction.MARK_AS_HIDDEN;
  }, [checkedMessages, sentMessages]);

  useEffect(() => {
    ui.setActiveScreen(Screens.SHAREPOINT);
  }, []);

  useEffect(() => {
    if (session.user?.Internal) {
      refetchSupervisionedUsers();
    }
  }, [session.user]);

  useEffect(() => {
    if (["sent", "drafts"].includes(activeField)) {
      refetchSentMessages();
    } else {
      refetchInboxMessages();
    }
  }, [debouncedQuery, activeField]);

  const handleComposeMessageClick = useCallback(async () => {
    const newMessage = await sendMessage({
      Subject: "",
      Text: "",
    } as ComposeMessage);
    sharepointStore.setActiveMessage(messageToComposeMessage(newMessage));
    sharepointStore.openComposeModal();
  }, [sendMessage, sharepointStore]);

  const handleSubmitMessage = useCallback(
    async (message: ComposeMessage) => {
      if (message.Status === "draft") {
        toast.promise(
          updateMessage(message),
          {
            pending: t("components.sharepoint.savingMessage"),
            success: t("components.sharepoint.messageSaved"),
            error: "error",
          },
          {
            type: "default",
          }
        );
      } else {
        toast.promise(updateMessage(message), {
          pending: t("components.sharepoint.sendingMessage"),
          success: t("components.sharepoint.messageSent"),
          error: "error",
        });
      }

      sharepointStore.closeComposeModal();
    },
    [sharepointStore, updateMessage, t]
  );

  const handleMessageClick = useCallback(
    (message: Message) => {
      sharepointStore.setActiveMessage(messageToComposeMessage(message));
      sharepointStore.openComposeModal();
    },
    [sharepointStore]
  );

  const onDeleteMessage = useCallback(
    (id: ComposeMessage["Id"]) => {
      if (id) {
        sharepointStore.closeComposeModal();
        sharepointStore.setActiveMessage(null);
        toast.promise(deleteMessage(id), {
          pending: t("components.sharepoint.deletingMessage"),
          success: t("components.sharepoint.messageDeleted"),
          error: "error",
        });
      }
    },
    [deleteMessage, sharepointStore, t]
  );

  const handleChangeUser = (event: any) => {
    sharepointStore.setInboxFilter(
      !!event.target.value
        ? SharepointFilters.SUPERVISOR_UNREADED
        : SharepointFilters.UNREADED
    );
    sharepointStore.resetReadedMessages();
    navigate(`.?user=${event.target.value}`);
  };

  const handleCheckMessageChange = useCallback(
    (messageId: Message["Id"], checked: boolean) => {
      let list = [...checkedMessages];

      if (checked) {
        list.push(messageId);
      } else {
        list = list.filter((id) => messageId !== id);
      }

      setCheckedMessages(list);
    },
    [checkedMessages]
  );

  const handlePerformBulkAction = useCallback(() => {
    if (bulkActionInboxMessages) {
      sharepointStore.resetReadedMessages();
      performBulkAction({
        action: bulkActionInboxMessages,
        messageIds: checkedMessages,
      });

      setCheckedMessages([]);
    }
  }, [
    bulkActionInboxMessages,
    checkedMessages,
    performBulkAction,
    sharepointStore,
  ]);

  const handleBulkChangeVisibility = useCallback(() => {
    if (bulkActionChangeVisibility) {
      toast.promise(
        performBulkAction({
          action: bulkActionChangeVisibility,
          messageIds: checkedMessages,
        }),
        {
          pending: t("components.sharepoint.bulkActionInProgress"),
          success: t("components.sharepoint.bulkActionCompleted"),
          error: "error",
        },
        {
          type: "default",
        }
      );
      sharepointStore.resetReadedMessages();
      setCheckedMessages([]);
    }
  }, [
    bulkActionChangeVisibility,
    checkedMessages,
    performBulkAction,
    t,
    sharepointStore,
  ]);

  const handleChangeMailForwarding = useCallback(
    (supervisedId: SupervisionedUser["Id"], isEmailEnabled: boolean) => {
      toast.promise(
        updateSupervisionedUser({
          IsEmailEnabled: isEmailEnabled,
          SupervisedId: supervisedId,
        }),
        {
          pending: t("components.sharepoint.bulkActionInProgress"),
          success: t("components.sharepoint.bulkActionCompleted"),
          error: "error",
        },
        {
          type: "default",
        }
      );
    },
    [t, updateSupervisionedUser]
  );

  const handleCloseComposeModal = useCallback(() => {
    sharepointStore.closeComposeModal();
    refetchSentMessages();
  }, [refetchSentMessages, sharepointStore]);

  const handleAddFileToQueue = useCallback(
    async (files: File[]) => {
      const verifiedFiles: File[] = [];

      for (let file of files) {
        const fileAlreadyInQueue = sharepointStore.filesQueue.findIndex(
          (currentAttachment) => currentAttachment.name === file.name
        );

        if (fileAlreadyInQueue >= 0) {
          toast.warning(
            t("components.sharepoint.fileAlreadyAttached", {
              filename: file.name,
            })
          );
        } else {
          verifiedFiles.push(file);
        }
      }
      sharepointStore.addFileToQueue(verifiedFiles);

      for (let file of verifiedFiles) {
        console.log(file);
        if (file.type === "") {
          sharepointStore.setUploadError(
            file,
            t("components.sharepoint.alertInvalidFile").toString()
          );
        } else {
          uploadFile(file)
            .then((attachment) => sharepointStore.trackAttachment(attachment))
            .catch((err: any) => {
              console.error(err);
              // Axios response error
              const errorPayload = err.response.data;

              if (errorPayload.error_code) {
                sharepointStore.setUploadError(
                  file,
                  t(
                    `messages.sharepointCompose.uploadErrorCodes.${errorPayload.error_code}`
                  ).toString()
                );
              }
            });
        }
      }
    },
    [sharepointStore, t, uploadFile]
  );

  const handleDeleteAttachmentOnQueue = useCallback(
    async (file: File) => {
      sharepointStore.removeFileQueue(file);

      const attachment = sharepointStore.newAttachments.find(
        (attachment) => attachment.OriginalFileName === file.name
      );

      if (attachment) {
        await deleteAttachment(attachment);
      }
    },
    [deleteAttachment, sharepointStore]
  );

  return (
    <Wrapper>
      <Sidebar
        fields={fields}
        activeField={activeField}
        onComposeClick={handleComposeMessageClick}
        config={config}
        numDraftMessages={draftMessagesCount}
        isSubmitting={isSubmitting}
        isInternalUser={session.user?.Internal}
        companyUsers={supervisionedUsers}
        onUserChange={handleChangeUser}
        activeUserId={activeUserId}
        fileStorageDays={session.activeCompany?.FileStorageDays || 0}
        onSettingsClick={openSettingsModal}
      />
      {["sent", "drafts"].includes(activeField) ? (
        <Group
          messages={sentMessages}
          onMessageClick={
            activeField === "drafts" ? handleMessageClick : undefined
          }
          config={config}
          fieldDescription={fieldDescription}
          hasNextPage={hasNextPageSent}
          isFetchingNextPage={isFetchingSent || isFetchingNextPageSent}
          onLoadMore={fetchNextPageSent}
          search={search}
          checkedMessages={checkedMessages}
          onSearchChange={setSearch}
          onCheckChange={
            Boolean(session.user?.Internal) && activeField === "sent"
              ? handleCheckMessageChange
              : undefined
          }
          onBulkChangeVisibility={
            activeField === "sent" ? handleBulkChangeVisibility : undefined
          }
          bulkChangeVisibilityAction={bulkActionChangeVisibility}
          activeField={activeField}
        />
      ) : (
        <Group
          messages={inboxMessages}
          config={config}
          fieldDescription={fieldDescription}
          hasNextPage={hasNextPageInbox}
          isFetchingNextPage={isFetchingInbox || isFetchingNextPageInbox}
          onLoadMore={fetchNextPageInbox}
          search={search}
          onSearchChange={setSearch}
          filter={filter}
          onFilterChange={(e) => {
            sharepointStore.resetReadedMessages();
            sharepointStore.setInboxFilter(e.target.value);
          }}
          onCheckChange={handleCheckMessageChange}
          checkedMessages={checkedMessages}
          bulkAction={bulkActionInboxMessages}
          onPerformBulkAction={handlePerformBulkAction}
          activeField={activeField}
          impersonation={session.user?.Id !== activeUserId}
          activeUserData={activeUserData}
        />
      )}

      <Outlet />
      {sharepointStore.activeMessage && (
        <ComposeMail
          message={sharepointStore.activeMessage}
          show={sharepointStore.isComposeModalOpen}
          onClose={handleCloseComposeModal}
          fields={fields}
          defaultField={session.user?.Field}
          recipients={sharepointRecipients}
          ccnRecipients={ccnRecipients}
          isFetchingCCNRecipients={isFetchingCCN}
          isSubmitting={isSubmitting}
          userId={session.user?.Id || -1}
          companyId={session.activeCompanyId || -1}
          onSubmit={handleSubmitMessage}
          config={config}
          onDelete={onDeleteMessage}
          onDeleteAttachment={deleteAttachment}
          isInternalUser={session.user?.Internal || false}
          userCompanyDistributionLists={userCompanyDistributionLists}
          filesQueue={sharepointStore.filesQueue}
          addFileToQueue={handleAddFileToQueue}
          uploadProgress={sharepointStore.uploadProgress}
          uploadErrors={sharepointStore.uploadErrors}
          onDeleteAttachmentOnQueue={handleDeleteAttachmentOnQueue}
        />
      )}
      <SetupMailForwardingModal
        companyUsers={supervisionedUsers}
        config={config}
        onClose={closeSettingsModal}
        onChange={handleChangeMailForwarding}
        show={isSettingsModalOpen}
      />
    </Wrapper>
  );
});
