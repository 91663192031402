import { FC } from "react";
import { Message } from "../../../types";
import Heading from "../../Common/Heading";
import { StyledWrap, StyledContent } from "./style";

type Props = {
  message: Message;
};

const MailBody: FC<Props> = ({ message }) => {
  return (
    <StyledWrap>
      <Heading as="h5" mb="30px">
        {message.Subject}
      </Heading>
      {/* <Heading mb="0px" fontWeight={600}>
        Ms. Katherine Lumaad
      </Heading>
      <Text as="span" color="text3">
        CompanyName, Inc.
      </Text>
      <Text color="text3">San Francisco, CA, United States</Text> */}
      <StyledContent
        dangerouslySetInnerHTML={{
          __html: message.Text.replaceAll("\\r", "\r").replaceAll(
            "\\n",
            "<br/>"
          ),
        }}
      />
    </StyledWrap>
  );
};

export default MailBody;
