import { useEffect } from "react";
import Content from "../../components/Common/Content";
import { Col, Row } from "../../components/Common/Grid";
import { useStores } from "../../hooks/useStores";
import {
  CommunicationFilters,
  NotificationFilters,
  Screens,
} from "../../types";
import { chunk } from "../../utils/array";
import WrapperApplicationsWidget from "./ApplicationsWidget";
import WrapperBasicBusinessDataWidget from "./BasicBusinessDataWidget";
import WrapperCommunicationsWidget from "./CommunicationsWidget";
import WrapperNotificationsWidget from "./NotificationsWidget";
import WrapperSharepointWidget from "./SharepointWidget";

const WIDGETS = {
  Applications: WrapperApplicationsWidget,
  Notifications: WrapperNotificationsWidget,
  Communications: WrapperCommunicationsWidget,
  FileExchange: WrapperSharepointWidget,
  BasicBusinessData: WrapperBasicBusinessDataWidget,
};

export const Home: React.FC = () => {
  const { ui, session } = useStores();

  const widgetsPerRow = chunk(
    // @ts-ignore
    Object.keys(WIDGETS).map((key) => WIDGETS[key]),
    3
  );

  useEffect(() => {
    ui.setActiveScreen(Screens.HOME);
    // Reset dei filtri delle varie sezioni
    session.setCommunicationsFilter(CommunicationFilters.UNREADED);
    session.setNotificationsFilter(NotificationFilters.UNREADED);
  }, []);

  return (
    <Content>
      {widgetsPerRow.map((widgetsRow, index) => (
        <Row key={index} gutters={10}>
          {widgetsRow.map((Widget, cIndex) => (
            <Col key={cIndex} md={6} xl={4} mt="10px">
              <Widget />
            </Col>
          ))}
        </Row>
      ))}
    </Content>
  );
};
