import { useQuery } from "react-query";
import api from "../services/api";
import { useActiveCompany } from "./useActiveCompany";
import { useSessionActiveUser } from "./useSessionActiveUser";

export const useUserCompanyAbilities = () => {
  const userId = useSessionActiveUser();
  const companyId = useActiveCompany();

  return useQuery(
    [`user-company-abilities-${userId}-${companyId}`, companyId],
    () => api.fetchUserAbilities(userId || -1, companyId || -1),
    {
      enabled: !!userId && !!companyId,
    }
  );
};
