import React, { FC } from "react";
import { StyledForm } from "./style";
import { Select } from "../../Common/Forms";
import { Button } from "../../Common/Button";
import { User as FUser } from "react-feather";
import { User } from "../../../types";

type Props = {
  value: User["Id"] | null;
  users: User[];
  onChange: (e: React.ChangeEvent) => any;
};

const UserSelect: FC<Props> = ({ value, users, onChange }) => {
  return (
    <StyledForm>
      <Select value={value} onChange={onChange}>
        <option value="" />
        {users.map((user) => (
          <option key={user.Id} value={user.Id}>
            {user.Firstname} {user.Lastname}
          </option>
        ))}
      </Select>
      <Button variant="texted" iconButton size="md">
        <FUser />
      </Button>
    </StyledForm>
  );
};

export default UserSelect;
