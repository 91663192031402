import { FC } from "react";
import { ArrowLeft } from "react-feather";
import ScrollBar from "../../Common/Scrollbar";
import { StyledMain, StyledHeader, StyledBody, StyledToggleBtn } from "./style";
import Sender from "../Sender";
import MailBody from "../MailBody";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Communication,
  ScreenConfig,
  Attachment as TAttachment,
} from "../../../types";
import { Col, Row } from "../../Common/Grid";
import Attachment from "../Attachment";
import ToolbarIcons from "../ToolbarIcons";
import { Footer } from "../../Layout/Footer";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type Props = {
  communication: Communication;
  config: ScreenConfig;
  onDownloadAttachments: () => any;
  onAttachmentClick: (attachment: TAttachment) => void;
};

const Main: FC<Props> = ({
  communication,
  config,
  onDownloadAttachments,
  onAttachmentClick,
}) => {
  const navigate = useNavigate();
  const { search, hash } = useLocation();

  const handleBody = () => {
    navigate(`../${search}${hash}`);
  };
  return (
    <StyledMain>
      <StyledHeader>
        <StyledToggleBtn type="button" onClick={handleBody}>
          <ArrowLeft />
        </StyledToggleBtn>
        <Sender
          color={config.theme.primaryColor}
          name={
            communication.Field
              ? communication.Field.Description
              : communication.Subject
          }
          time={dayjs.utc(communication.CreatedAt).format("DD/MM/YYYY HH:mm")}
        />
        <ToolbarIcons
          onDownloadAttachments={onDownloadAttachments}
          hasAttachments={
            Boolean(communication.Attachments) &&
            // @ts-ignore
            communication.Attachments?.length > 0
          }
        />
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <MailBody communication={communication} />

          <Row paddingX={"30px"}>
            <Col>
              {communication.Attachments?.map((attachment: TAttachment) => (
                <Attachment
                  key={attachment.FileName}
                  files={0}
                  link={attachment.FileURL}
                  size={String(attachment.Size)}
                  title={attachment.OriginalFileName}
                  onAttachmentClick={() => onAttachmentClick(attachment)}
                />
              ))}
            </Col>
          </Row>
        </StyledBody>
        <Footer />
      </ScrollBar>
    </StyledMain>
  );
};

export default Main;
