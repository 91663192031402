import { FC, useCallback } from "react";
import { StyledHeader, StyledFooter, StyledFooterLink } from "./style";
import { Card } from "../../Common/Card";
import { Company, Notification, ScreenConfig } from "../../../types";
import SectionTitle from "../../Common/SectionTitle";
import { ListGroup, ListGroupItem } from "../../Common/ListGroup";
import { useTranslation } from "react-i18next";
import { Row } from "../../Common/Grid";
import Item from "./Item";
import { useNavigate } from "react-router-dom";
import { NotificationsEmptyState } from "../../Notifications/EmptyState";

type Props = {
  notifications: Notification[];
  companyId: Company["Id"];
  config: ScreenConfig;
  enabled?: boolean;
};

export const NotificationsWidget: FC<Props> = ({
  notifications,
  companyId,
  config,
  enabled = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Icon } = config;

  const handleItemClick = useCallback(
    (notificationId: Notification["Id"]) => {
      navigate(`/company/${companyId}/notifications/${notificationId}`);
    },
    [companyId, navigate]
  );

  return (
    <Card height="100%">
      <StyledHeader color={config.theme.primaryColor}>
        <Row alignItems="center" paddingLeft="1rem">
          <Icon color={config.theme.primaryColor} />
          <SectionTitle title={t("components.menu.notifications")} />
        </Row>
      </StyledHeader>
      {enabled ? (
        <>
          <ListGroup flush>
            {notifications.length === 0 && (
              <NotificationsEmptyState
                message={t("components.notifications.emptyStateWidget")}
              />
            )}
            {notifications.map((notification) => (
              <ListGroupItem
                key={notification.Id}
                display="flex"
                px={[null, "20px"]}
              >
                <Item notification={notification} onClick={handleItemClick} />
              </ListGroupItem>
            ))}
          </ListGroup>
          <StyledFooter>
            <StyledFooterLink path={`/company/${companyId}/notifications`}>
              {t("components.notificationsWidget.viewAll")}
              <i className="icon ion-md-arrow-down mg-l-5" />
            </StyledFooterLink>
          </StyledFooter>
        </>
      ) : (
        <NotificationsEmptyState
          message={t("components.notifications.disabled")}
        />
      )}
    </Card>
  );
};
