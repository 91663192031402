import { FC } from "react";
import { Communication, Notification } from "../../../types";
import { Avatar, AvatarInitial } from "../../Common/Avatar";

import {
  StyledListMiddle,
  StyledListTitle,
  StyledListEnd,
  StyledListText,
  StyledContainer,
} from "./style";

type Props = {
  communication: Communication;
  onClick: (notificationId: Notification["Id"]) => void;
};

const Item: FC<Props> = ({ communication, onClick }) => {
  return (
    <StyledContainer onClick={() => onClick(communication.Id)}>
      <Avatar size="sm" shape="rounded">
        <AvatarInitial>{communication.Subject.charAt(0)}</AvatarInitial>
      </Avatar>
      <StyledListMiddle>
        <StyledListText>{communication.Field.Description}</StyledListText>
        <StyledListTitle>{communication.Subject}</StyledListTitle>
      </StyledListMiddle>
      <StyledListEnd>
        <StyledListText>
          {communication.CreatedAt.toLocaleDateString()}
        </StyledListText>
      </StyledListEnd>
    </StyledContainer>
  );
};

export default Item;
