import { FC, useCallback } from "react";
import { Eye, EyeOff, Mail, Search } from "react-feather";
import {
  StyledGroup,
  StyledHeader,
  StyledBody,
  StyledList,
  StyledReadMore,
  StyledTopDiv,
  StickyTopDiv,
} from "./style";
import ScrollBar from "../../Common/Scrollbar";
import SearchForm from "../SearchForm";
import GroupItem from "../GroupItem";
import {
  Message,
  MessagesBulkAction,
  ScreenConfig,
  User,
} from "../../../types";
import { Button } from "../../Common/Button";
import { useTranslation } from "react-i18next";
import Heading from "../../Common/Heading";
import FilterForm from "../FilterForm";
import { useLocation, useNavigate } from "react-router-dom";
import { SharepointEmptyState } from "../EmptyState";
import { Nav, NavLink } from "../../Common/Nav/nav";
import ReactTooltip from "react-tooltip";
import MailOpen from "../../UI/Icons/MailOpen";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type Props = {
  messages: Message[];
  config: ScreenConfig;
  isFetchingNextPage: boolean;
  search: string;
  filter?: string;
  hasNextPage: boolean;
  fieldDescription: string;
  checkedMessages?: number[];
  bulkAction?: "mark-readed" | "mark-unreaded";
  bulkChangeVisibilityAction?: "mark-as-hidden" | "mark-as-sent";
  activeField: string;
  impersonation?: boolean;
  activeUserData?: User | null;
  onLoadMore: () => void;
  onSearchChange: (value: string) => void;
  onFilterChange?: (e: any) => void;
  onMessageClick?: (message: Message) => void;
  onCheckChange?: (messageId: Message["Id"], checked: boolean) => void;
  onPerformBulkAction?: () => void;
  onBulkChangeVisibility?: () => void;
};

const Group: FC<Props> = ({
  messages,
  config,
  isFetchingNextPage,
  hasNextPage,
  fieldDescription,
  search,
  filter,
  checkedMessages,
  bulkAction,
  bulkChangeVisibilityAction,
  activeField,
  impersonation,
  activeUserData,
  onLoadMore,
  onFilterChange,
  onSearchChange,
  onMessageClick,
  onCheckChange,
  onPerformBulkAction,
  onBulkChangeVisibility,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash, search: locationSearch } = useLocation();

  const handleClick = useCallback(
    (message: Message) => {
      onMessageClick
        ? onMessageClick(message)
        : navigate(`./${message.Id}${locationSearch}${hash}`);
    },
    [hash, locationSearch, navigate, onMessageClick]
  );

  return (
    <StyledGroup>
      <StyledHeader>
        <Search className="search" />
        <SearchForm value={search} onChange={onSearchChange} />
        {filter && onFilterChange && (
          <FilterForm
            value={filter}
            onChange={onFilterChange}
            impersonation={impersonation}
            activeUserData={activeUserData}
          />
        )}
      </StyledHeader>
      <StickyTopDiv>
        <StyledTopDiv>
          <Heading tt="uppercase" fontWeight={600} mb="0px">
            {fieldDescription}
          </Heading>
          {checkedMessages &&
            checkedMessages.length > 0 &&
            onPerformBulkAction && (
              <Nav customStyle="icon">
                <NavLink
                  path="#"
                  data-for="tooltip-download-attachments"
                  data-tip={
                    bulkAction === MessagesBulkAction.MARK_UNREADED
                      ? t("components.sharepoint.markUnreaded")
                      : t("components.sharepoint.markReaded")
                  }
                  onClick={(e: any) => {
                    e.preventDefault();
                    onPerformBulkAction();
                  }}
                >
                  {bulkAction &&
                  bulkAction === MessagesBulkAction.MARK_UNREADED ? (
                    <Mail />
                  ) : (
                    <MailOpen />
                  )}
                </NavLink>
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  id="tooltip-download-attachments"
                />
              </Nav>
            )}
          {checkedMessages &&
            checkedMessages.length > 0 &&
            onBulkChangeVisibility && (
              <Nav customStyle="icon">
                <NavLink
                  path="#"
                  data-for="tooltip-change-visibility"
                  data-tip={
                    bulkChangeVisibilityAction ===
                    MessagesBulkAction.MARK_AS_HIDDEN
                      ? t("components.sharepoint.markAsHidden")
                      : t("components.sharepoint.markAsSent")
                  }
                  onClick={(e: any) => {
                    e.preventDefault();
                    onBulkChangeVisibility();
                  }}
                >
                  {bulkChangeVisibilityAction &&
                  bulkChangeVisibilityAction ===
                    MessagesBulkAction.MARK_AS_HIDDEN ? (
                    <EyeOff />
                  ) : (
                    <Eye />
                  )}
                </NavLink>
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  id="tooltip-change-visibility"
                />
              </Nav>
            )}
        </StyledTopDiv>
      </StickyTopDiv>
      <ScrollBar top="105px">
        <StyledBody>
          {/* <StyledLabel>Today</StyledLabel> */}
          <StyledList>
            {messages.length === 0 && <SharepointEmptyState />}
            {messages.map((message) => (
              <GroupItem
                id={message.Id}
                color={config.theme.primaryColor}
                name={
                  ["sent", "drafts"].includes(activeField)
                    ? message.Recipients && message.Recipients.length > 0
                      ? message.Recipients.map(
                          (r) => `${r.Firstname} ${r.Lastname}`
                        ).join(", ")
                      : t("components.sharepoint.draft")
                    : `${message.Author.Firstname} ${message.Author.Lastname}`
                }
                time={dayjs.utc(message.CreatedAt).format("DD/MM/YYYY HH:mm")}
                title={
                  message.Subject || t("components.sharepoint.noSubjectLabel")
                }
                excerpt={message.Text.replaceAll("\\n", " ").substring(0, 60)}
                status={message.Unreaded ? "unread" : "read"}
                onClick={() => handleClick(message)}
                isChecked={
                  checkedMessages && checkedMessages?.indexOf(message.Id) > -1
                }
                numAttachments={message.Attachments?.length || 0}
                numAttachmentsDownloaded={
                  (message.Attachments || []).filter(
                    (attachment) => attachment.Downloaded
                  ).length
                }
                onCheckChange={
                  onCheckChange
                    ? (checked: boolean) => onCheckChange(message.Id, checked)
                    : undefined
                }
                isVisibile={message.IsVisibile}
              />
            ))}
          </StyledList>

          {hasNextPage && (
            <StyledReadMore>
              <Button
                size="xs"
                color="light"
                fullwidth
                disabled={isFetchingNextPage}
                onClick={onLoadMore}
              >
                {t("components.sharepoint.loadMore")}
              </Button>
            </StyledReadMore>
          )}
        </StyledBody>
      </ScrollBar>
    </StyledGroup>
  );
};

export default Group;
