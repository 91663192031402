import styled, { themeGet, css } from "../../../../styled";
import { ModalBody, ModalHeader } from "../../../Common/Modal";

export const StyledHeader = styled(({ backgroundColor, ...rest }) => (
  <ModalHeader {...rest} />
))`
  background-color: ${(props) => props.backgroundColor};
  padding: 10px;
  color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const StyledTitle = styled.h6`
  margin-bottom: 0;
  font-weight: 400;
  position: relative;
  top: 1px;
  color: #fff;
`;

export const StyledBody = styled(({ ...rest }) => <ModalBody {...rest} />)`
  transition: all 0.3s;
  position: relative;
  height: 300px;

  .form-control {
    &:hover,
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  ${(props) =>
    props.theme.name === "dark" &&
    css`
      border-width: 0;
      background-color: ${themeGet("colors.darkdarken3")};
      box-shadow: none;
      color: ${themeGet("colors.gray500")};
      .form-control {
        background-color: transparent;
        color: ${themeGet("colors.gray300")};
        &:placeholder {
          color: ${themeGet("colors.gray700")};
        }
      }
    `}
`;
