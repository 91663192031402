import { FC } from "react";
import { ArrowLeft } from "react-feather";
import ScrollBar from "../../Common/Scrollbar";
import { StyledMain, StyledHeader, StyledBody, StyledToggleBtn } from "./style";
import Sender from "../Sender";
import MailBody from "../MailBody";
import { useLocation, useNavigate } from "react-router-dom";
import { Notification, ScreenConfig } from "../../../types";
import { Footer } from "../../Layout/Footer";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type Props = {
  notification: Notification;
  config: ScreenConfig;
};

const Main: FC<Props> = ({ notification, config }) => {
  const navigate = useNavigate();
  const { search, hash } = useLocation();

  const handleBody = () => {
    navigate(`../${search}${hash}`);
  };
  return (
    <StyledMain>
      <StyledHeader>
        <StyledToggleBtn type="button" onClick={handleBody}>
          <ArrowLeft />
        </StyledToggleBtn>
        <Sender
          color={config.theme.primaryColor}
          name={notification.Field.Description}
          time={dayjs.utc(notification.CreatedAt).format("DD/MM/YYYY HH:mm")}
        />
        {/* <ToolbarIcons /> */}
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <MailBody notification={notification} />
        </StyledBody>
        <Footer />
      </ScrollBar>
    </StyledMain>
  );
};

export default Main;
