import { Bell, Grid, Home, Inbox, Mail, BarChart, Users } from "react-feather";

export const menus = [
  {
    id: 0,
    key: "home",
    label: "components.menu.home",
    url: "/company/:companyId/home",
    Icon: Home,
    theme: {
      background: "#002E6B",
      foreground: "#fff",
    },
  },
  {
    id: 1,
    key: "communications",
    label: "components.menu.communications",
    url: "/company/:companyId/communications",
    Icon: Mail,
    theme: {
      background: "#CF5634",
      foreground: "#fff",
    },
  },
  {
    id: 2,
    key: "notifications",
    label: "components.menu.notifications",
    url: "/company/:companyId/notifications",
    Icon: Bell,
    theme: {
      background: "#CD60EE",
      foreground: "#fff",
    },
  },
  {
    id: 3,
    key: "applications",
    label: "components.menu.applications",
    url: "/company/:companyId/applications",
    Icon: Grid,
    theme: {
      background: "#0279D1",
      foreground: "#fff",
    },
  },
  {
    id: 4,
    key: "sharepoint",
    label: "components.menu.sharepoint",
    url: "/company/:companyId/sharepoint",
    Icon: Inbox,
    theme: {
      background: "#11854B",
      foreground: "#fff",
    },
  },
  {
    id: "5",
    key: "basic-business-data",
    label: "components.menu.basicBusinessData",
    url: "/company/:companyId/data-analysis/1",
    Icon: BarChart,
    theme: {
      background: "#6D777F",
      foreground: "#fff",
    },
  },
  {
    id: "6",
    key: "access-configuration",
    label: "components.menu.access-configuration",
    url: "/company/:companyId/access-configuration",
    Icon: Users,
    theme: {
      background: "#002E6B",
      foreground: "#fff",
    },
  },
];
