import { useMemo, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Communications/Sidebar";
import Wrapper from "../../components/Communications/Wrapper";
import { useCommunicationsFields } from "../../hooks/communicationField.hooks";
import { useCompanyUsers } from "../../hooks/companyUsers.hooks";
import { useStores } from "../../hooks/useStores";
import { Screens } from "../../types";

export const CommunicationsLayout: React.FC = () => {
  const location = useLocation();
  const { session, ui } = useStores();
  const { data: communicationsFields = [] } = useCommunicationsFields();
  const { refetch: refetchCompanyUsers, data: companyUsers = [] } =
    useCompanyUsers();
  const navigate = useNavigate();

  const activeField = useMemo(() => {
    return location.hash && location.hash.length > 0
      ? location.hash.replace("#", "").split("?")[0]
      : "inbox";
  }, [location.hash]);

  useEffect(() => {
    ui.setActiveScreen(Screens.COMMUNICATIONS);
  }, []);

  useEffect(() => {
    if (session.user?.Internal) {
      refetchCompanyUsers();
    }
  }, [session.user]);

  const handleChangeUser = (event: any) => {
    navigate(`.?user=${event.target.value}`);
  };

  return (
    <Wrapper>
      <Sidebar
        communicationsFields={communicationsFields}
        activeField={activeField}
        isInternalUser={session.user?.Internal}
        companyUsers={companyUsers || []}
        onUserChange={handleChangeUser}
      />
      <Outlet />
    </Wrapper>
  );
};
