import { makeAutoObservable } from "mobx";
import {
  CommunicationFilters,
  Company,
  NotificationFilters,
  SSOJwtPayload,
  Stores,
  User,
} from "../types";
import api from "../services/api";
import storage from "../services/storage";
import jwtDecode, { JwtPayload } from "jwt-decode";

export class SessionStore {
  stores: Stores;

  isInitialized = false;
  user: User | null = null;

  companies: Company[] = [];
  isFetchingCompanies = false;

  activeCompanyId: Company["Id"] | null = null;

  notificationStatusFilter: NotificationFilters = NotificationFilters.UNREADED;
  communicationStatusFilter: CommunicationFilters =
    CommunicationFilters.UNREADED;

  get isAuthenticated() {
    return !!this.user;
  }

  get activeCompany() {
    return this.activeCompanyId
      ? this.companies.find((company) => company.Id === this.activeCompanyId) ||
          null
      : null;
  }

  get defaultCompany() {
    return (
      this.companies.find((company) => company.Favorite) || this.companies[0]
    );
  }

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  initialize = async () => {
    try {
      const user = await api.getCurrentUser();

      if (user) {
        //this.restoreSession();
        this.user = user;
        this.refreshTokenSetup();
      } else {
        this.user = null;
      }
      await this.fetchUserCompanies();
    } catch (err) {
      console.log(err);
    } finally {
      this.isInitialized = true;
    }
  };

  logout = () => {
    api.destroySession();
    this.user = null;
    this.activeCompanyId = null;
    this.initialize();
  };

  refreshTokenSetup = () => {
    // Timing to renew access token
    const token = storage.getItem("accessToken") as
      | { accessToken: string }
      | undefined;
    if (token) {
      const decoded = jwtDecode<JwtPayload>(
        token.accessToken
      ) as unknown as SSOJwtPayload;
      // console.log(decoded);
      const exp = decoded.exp || decoded.iat + 1200; // for old version, exp not exists
      let refreshTiming = (exp - decoded.iat - 60) * 1000;

      const refreshToken = async () => {
        const newAccessToken = await api.refreshAccessToken();
        console.log(newAccessToken);
        storage.setItem("accessToken", newAccessToken);

        const decodedNewAccessToken = jwtDecode<JwtPayload>(
          newAccessToken.accessToken
        ) as unknown as SSOJwtPayload;

        refreshTiming =
          ((decodedNewAccessToken.exp || decoded.iat + 1200) -
            decodedNewAccessToken.iat -
            60) *
          1000;

        setTimeout(refreshToken, refreshTiming);
      };

      setTimeout(refreshToken, refreshTiming);
    }
  };

  fetchUserCompanies = async () => {
    if (this.user) {
      this.isFetchingCompanies = true;
      try {
        this.companies = await api.fetchUserCompanies(this.user.Id);
      } catch (err: any) {
        console.log(err);
      } finally {
        this.isFetchingCompanies = false;
      }
    }
  };

  setFavoriteCompany = async (companyId: Company["Id"]) => {
    if (this.user) {
      try {
        await api.setFavoriteCompany(this.user.Id, companyId);

        this.fetchUserCompanies();
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  setActiveCompanyId = (companyId: Company["Id"]) => {
    this.activeCompanyId = companyId;
  };

  refreshUserData = async () => {
    const user = await api.getCurrentUser();
    if (user) {
      this.user = user;
    }
  };

  setNotificationsFilter = (filter: NotificationFilters) => {
    this.notificationStatusFilter = filter;
  };

  setCommunicationsFilter = (filter: CommunicationFilters) => {
    this.communicationStatusFilter = filter;
  };
}
