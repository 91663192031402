import { FC } from "react";
import { AlertCircle, MoreVertical, Paperclip, X } from "react-feather";
import { classic } from "../../../styled/colors";
import { Button } from "../../Common/Button";
import { Progress } from "../../Common/Progress";
import { StyledErrorMessage, StyledMedia, StyledMediaBody } from "./style";

interface IProps {
  title: string;
  link?: string;
  files: number;
  size: string;
  progress?: number;
  error?: string;
  isInternalUser?: boolean;
  onDelete?: () => any;
  onAttachmentClick?: () => any;
  onMoreClick?: () => any;
}

const Attachment: FC<IProps> = ({
  title,
  link,
  files,
  size,
  progress,
  error,
  isInternalUser,
  onDelete,
  onAttachmentClick,
  onMoreClick,
}) => {
  return (
    <StyledMedia>
      {error ? <AlertCircle color={classic.danger} /> : <Paperclip />}
      <StyledMediaBody>
        <h6 onClick={onAttachmentClick}>{title}</h6>
        {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
        {/* <span>
          {files} files, {size}
        </span> */}
        {progress && progress < 100 && <Progress height="5px" now={progress} />}
      </StyledMediaBody>
      {(!progress || (progress && progress === 100)) && onDelete && (
        <Button color="info" variant="texted" label="Delete" onClick={onDelete}>
          <X size={16} />
        </Button>
      )}
      {!onDelete && onMoreClick && (
        <Button color="info" variant="texted" label="Delete" onClick={onDelete}>
          <MoreVertical size={16} onClick={onMoreClick} />
        </Button>
      )}
    </StyledMedia>
  );
};

export default Attachment;
