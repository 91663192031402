import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Company } from "../types";

export const useActiveCompany = () => {
  const params = useParams();

  const [activeCompanyId, setActiveCompanyId] = useState<
    Company["Id"] | undefined
  >(undefined);

  useEffect(() => {
    setActiveCompanyId(Number(params.companyId));
  }, [params.companyId]);

  return activeCompanyId;
};
