import {
  ChangeEvent,
  DragEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Paperclip, Trash2, X } from "react-feather";
import * as Yup from "yup";
import {
  StyledHeader,
  StyledTitle,
  StyledBody,
  StyledDropzone,
  StyledFooterToolbar,
  StyledShowAllRecipients,
} from "./style";
import { Modal } from "../../Common/Modal";
import { Nav, NavLink } from "../../Common/Nav/nav";
import { Col, Row } from "../../Common/Grid";
import { Input } from "../../Common/Forms";
import HRLine from "../../Common/HrLine";
import {
  ComposeMessage,
  Field,
  ScreenConfig,
  SharepointCCN,
  SharepointRecipient,
  UserCompanyDistributionLists,
} from "../../../types";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../../Common/Forms/CustomSelect";
import { useFormik } from "formik";
import { Button } from "../../Common/Button";
import Feedback from "../../Common/Forms/Feedback";
import { toast } from "react-toastify";
import { Attachment as TAttachment } from "../../../types";
import { useUploadAttachment } from "../../../hooks/sharepoint.hooks";
import Attachment from "../Attachment";
import { delay } from "../../../utils/methods";
import { ObservableMap } from "mobx";
import { observer } from "mobx-react";

interface IProps {
  message: ComposeMessage;
  show: boolean;
  recipients: SharepointRecipient[];
  fields: Field[];
  defaultField?: Field | null;
  isSubmitting: boolean;
  userId: number;
  companyId: number;
  config: ScreenConfig;
  isInternalUser?: boolean;
  ccnRecipients: SharepointCCN[];
  isFetchingCCNRecipients: boolean;
  onClose: () => any;
  onSubmit: (newMessage: ComposeMessage) => any;
  onDelete: (id: ComposeMessage["Id"]) => any;
  onDeleteAttachment: (attachment: TAttachment) => Promise<any>;
  onDeleteAttachmentOnQueue: (file: File) => Promise<any>;
  userCompanyDistributionLists: UserCompanyDistributionLists[];
  filesQueue: File[];
  addFileToQueue: (files: File[]) => void;
  uploadProgress: ObservableMap<string, number>;
  uploadErrors: ObservableMap<string, string>;
}

const ComposeMail: FC<IProps> = observer(
  ({
    message,
    show,
    recipients,
    fields,
    defaultField,
    isSubmitting,
    userId,
    companyId,
    config,
    isInternalUser,
    ccnRecipients,
    isFetchingCCNRecipients,
    onClose,
    onSubmit,
    onDelete,
    onDeleteAttachment,
    onDeleteAttachmentOnQueue,
    userCompanyDistributionLists = [],
    filesQueue,
    addFileToQueue,
    uploadProgress,
    uploadErrors,
  }) => {
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [hightlight, setHightLight] = useState(false);
    const [showAllRecipients, setShowAllRecipients] = useState(false);

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        Id: message.Id,
        Recipients: message?.Recipients || [],
        FieldId: message?.FieldId || defaultField?.Id || null,
        Subject: message?.Subject || "",
        Text:
          message && message.Text ? message.Text.replaceAll("\\n", "\n") : "",
        Attachments: message?.Attachments || [],
        Status: message?.Status || null,
        CCN: message.CCN || [],
      },
      validationSchema: Yup.object({
        Recipients: Yup.array()
          .min(1, t("messages.sharepointCompose.recipientsRequired"))
          .required(t("messages.sharepointCompose.recipientsRequired")),
        FieldId: Yup.number().required(
          t("messages.sharepointCompose.areaRequired")
        ),
        Subject: Yup.string()
          .min(2, t("messages.sharepointCompose.subjectRequired"))
          .required(t("messages.sharepointCompose.subjectRequired")),
        Text: Yup.string().required(
          t("messages.sharepointCompose.textRequired")
        ),
        Attachments: Yup.mixed().test({
          name: "AttachmentUploaded",
          test: (value: TAttachment[] | undefined) => {
            if ((value && value.length > 0) || filesQueue.length > 0) {
              return true;
            }

            return false;
          },
          message: t("messages.sharepointCompose.attachmentsRequired"),
        }),
        // Attachments: Yup.array().min(
        //   1,
        //   t("messages.sharepointCompose.attachmentsRequired")
        // ),
      }),
      onSubmit: (values: any) => {
        if (!isInternalUser) {
          setShowAllRecipients(false);
        }
        onSubmit({ ...values, Status: "sent" });
      },
    });

    const recipientsOptions = useMemo(() => {
      const _recipients = showAllRecipients
        ? recipients
        : recipients.filter((recipient) => recipient.DefaultRecipient);

      const recipientsList = _recipients.map((recipient) => ({
        value: recipient.Id,
        label: `${recipient.Firstname} ${recipient.Lastname}`,
      }));

      return userCompanyDistributionLists &&
        userCompanyDistributionLists.length > 0
        ? [
            {
              label: t("components.sharepoint.recipients"),
              options: recipientsList,
            },
            {
              label: t("components.sharepoint.groups"),
              options: userCompanyDistributionLists.map((list) => ({
                value: `GROUP-${list.Id}`,
                label: list.Description,
              })),
            },
          ]
        : recipientsList;
    }, [recipients, t, userCompanyDistributionLists, showAllRecipients]);

    const selectedRecipientsOptions = useMemo(() => {
      return formik.values.Recipients.map((recipientId) => {
        const recipient = recipients.find((r) => r.Id === recipientId);

        return {
          value: recipient?.Id,
          label: `${recipient?.Firstname} ${recipient?.Lastname}`,
        };
      });
    }, [formik.values.Recipients, recipients]);

    const ccnOptions = useMemo(() => {
      return ccnRecipients.map((recipient) => ({
        value: recipient.RoleId,
        label: recipient.Description,
      }));
    }, [ccnRecipients]);

    const selectedCCNRecipientsOptions = useMemo(() => {
      return formik.values.CCN.map((recipientId) => {
        const recipient = ccnRecipients.find((r) => r.RoleId === recipientId);

        return {
          value: recipient?.RoleId,
          label: recipient?.Description,
        };
      });
    }, [ccnRecipients, formik.values.CCN]);

    const fieldsOptions = useMemo(() => {
      return fields.map((field) => ({
        value: field.Id,
        label: field.Description,
      }));
    }, [fields]);

    const selectedFieldOptions = useMemo(() => {
      if (!formik.values.FieldId) return null;

      const field = fields.find(
        (field) => field.Id === Number(formik.values.FieldId)
      );

      return {
        value: field?.Id,
        label: field?.Description,
      };
    }, [fields, formik.values.FieldId]);

    useEffect(() => {
      if (isInternalUser) {
        setShowAllRecipients(true);
      }
    }, [isInternalUser]);

    useEffect(() => {
      if (!formik.values.FieldId && formik.values.Recipients.length > 0) {
        const recipient = recipients.find(
          (r) => r.Id === formik.values.Recipients[0]
        );

        if (recipient) {
          formik.setFieldValue("FieldId", recipient.Field?.Id);
        }
      }
    }, [formik.values.Recipients, formik.values.FieldId, recipients]);

    const handleUploadFiles = useCallback(
      async (files: File[]) => {
        const verifiedFiles: File[] = [];

        for (let file of files) {
          const fileAlreadyAttached = formik.values.Attachments.findIndex(
            (attachment) => attachment.OriginalFileName === file.name
          );

          if (fileAlreadyAttached >= 0) {
            toast.warning(
              t("components.sharepoint.fileAlreadyAttached", {
                filename: file.name,
              })
            );
          } else {
            verifiedFiles.push(file);
          }
        }

        addFileToQueue(verifiedFiles);
        setHightLight(false);
      },
      [addFileToQueue, formik.values.Attachments, t]
    );

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setHightLight(true);
    };

    const onDragLeave = () => {
      setHightLight(false);
    };

    const onDrop = useCallback(
      (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        // e.dataTransfer.files is a FileList (object)
        handleUploadFiles(Array.from(e.dataTransfer.files));
      },
      [handleUploadFiles]
    );

    const onSelectedFile = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        handleUploadFiles(Array.from(event.currentTarget.files || []));
      },
      [handleUploadFiles]
    );

    const handleOnClose = useCallback(
      (e?: any) => {
        e && e.preventDefault();
        if (!isInternalUser) {
          setShowAllRecipients(false);
        }
        if (formik.dirty && formik.values.Id) {
          // @ts-ignore TODO
          onSubmit({ ...formik.values, Status: "draft" });
        } else {
          onClose();
        }
        // Clean component state
        // setFilesQueue([]);
        // setUploadProgress({});
        // setUploadErrors({});
      },
      [formik.dirty, formik.values, onClose, onSubmit, isInternalUser]
    );

    const handleDeleteAttachment = useCallback(
      async (attachment: TAttachment) => {
        await onDeleteAttachment(attachment);
        formik.setFieldValue(
          "Attachments",
          formik.values.Attachments.filter(
            (currentAttacment) => currentAttacment.Id !== attachment.Id
          )
        );
      },
      [formik, onDeleteAttachment]
    );

    const handleDeleteAttachmentOnQueue = useCallback(
      (file: File) => {
        onDeleteAttachmentOnQueue(file);
      },
      [onDeleteAttachmentOnQueue]
    );

    const handleChangeRecipients = useCallback(
      (options: unknown) => {
        const opts = options as { value: number; label: string }[];
        const recipients = opts
          .filter((opt) => String(opt.value).indexOf("GROUP") < 0)
          .map((option) => option.value);

        const selectedGroups = opts.filter(
          (opt) => String(opt.value).indexOf("GROUP") >= 0
        );

        for (let selectedGroup of selectedGroups) {
          const list = userCompanyDistributionLists.find(
            (list) =>
              list.Id ===
              Number(String(selectedGroup.value).replace("GROUP-", ""))
          );

          if (list) {
            for (let recipient of list.Recipients) {
              if (
                !formik.values.Recipients.find(
                  (recipientId) => recipientId === recipient.Id
                )
              ) {
                recipients.push(recipient.Id);
              }
            }
          }
        }

        formik.setFieldValue("Recipients", recipients);
      },
      [formik, userCompanyDistributionLists]
    );

    const RecipientsSelectFooter = showAllRecipients ? undefined : (
      <StyledShowAllRecipients onClick={() => setShowAllRecipients(true)}>
        {t("components.sharepoint.showAllRecipients")}
      </StyledShowAllRecipients>
    );

    return (
      <Modal
        show={show}
        onClose={handleOnClose}
        size="lg"
        closeOnClickBackdrop={false}
      >
        <StyledHeader backgroundColor={config.theme.primaryColor}>
          <StyledTitle>{t("components.sharepoint.newMessage")}</StyledTitle>
          <Nav customStyle="icon">
            <NavLink path="#!" onClick={handleOnClose}>
              <X />
            </NavLink>
          </Nav>
        </StyledHeader>
        <StyledBody>
          <form onSubmit={formik.handleSubmit}>
            <div onDragOver={onDragOver}>
              <StyledDropzone
                hightlight={hightlight}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
              />
              <Row alignItems="center">
                <Col sm>{t("components.sharepoint.recipients")}:</Col>
                <Col sm={10}>
                  <CustomSelect
                    options={recipientsOptions}
                    value={selectedRecipientsOptions}
                    placeholder={t(
                      "components.sharepoint.recipientsPlaceholder"
                    )}
                    isMulti
                    onChange={handleChangeRecipients}
                    MenuListFooter={RecipientsSelectFooter}
                  />
                  {formik.touched.Recipients && formik.errors.Recipients && (
                    <Feedback state={"error"} showState={true} showErrorOnly>
                      {formik.errors.Recipients}
                    </Feedback>
                  )}
                </Col>
              </Row>
              <HRLine my="5px" />
              {isInternalUser && (
                <>
                  <Row alignItems="center">
                    <Col sm>{t("components.sharepoint.ccn")}:</Col>
                    <Col sm={10}>
                      <CustomSelect
                        options={ccnOptions}
                        value={selectedCCNRecipientsOptions}
                        placeholder={t(
                          "components.sharepoint.recipientsPlaceholder"
                        )}
                        isMulti
                        onChange={(options: unknown) =>
                          formik.setFieldValue(
                            "CCN",
                            (options as { value: number; label: string }[]).map(
                              (option) => option.value
                            )
                          )
                        }
                        isLoading={isFetchingCCNRecipients}
                      />
                      {formik.touched.CCN && formik.errors.CCN && (
                        <Feedback
                          state={"error"}
                          showState={true}
                          showErrorOnly
                        >
                          {formik.errors.CCN}
                        </Feedback>
                      )}
                    </Col>
                  </Row>
                  <HRLine my="5px" />
                </>
              )}

              <Row alignItems="center">
                <Col sm>{t("components.sharepoint.field")}:</Col>
                <Col sm={10}>
                  <CustomSelect
                    options={fieldsOptions}
                    value={selectedFieldOptions}
                    placeholder={t("components.sharepoint.fieldPlaceholder")}
                    onChange={(option: unknown) =>
                      formik.setFieldValue(
                        "FieldId",
                        (option as { value: number; label: string }).value
                      )
                    }
                  />
                  {formik.touched.FieldId && formik.errors.FieldId && (
                    <Feedback state={"error"} showState={true} showErrorOnly>
                      {formik.errors.FieldId}
                    </Feedback>
                  )}
                </Col>
              </Row>
              <HRLine my="5px" />
              <Row alignItems="center">
                <Col sm>{t("components.sharepoint.subject")}:</Col>
                <Col sm={10}>
                  <Input
                    id="Subject"
                    placeholder={t("components.sharepoint.subjectPlaceholder")}
                    px="0px"
                    borderWidth="0px"
                    {...formik.getFieldProps("Subject")}
                    feedbackText={formik.getFieldMeta("Subject").error}
                    state={
                      formik.getFieldMeta("Subject").error ? "error" : "success"
                    }
                    showState={
                      !!formik.getFieldMeta("Subject").error &&
                      formik.touched.Subject
                    }
                  />
                </Col>
              </Row>
              <Row alignItems="center">
                <Col marginTop={10}>
                  <textarea
                    id="Text"
                    {...formik.getFieldProps("Text")}
                    rows={5}
                  />
                  {formik.touched.Text && formik.errors.Text && (
                    <Feedback state={"error"} showState={true} showErrorOnly>
                      {formik.errors.Text}
                    </Feedback>
                  )}
                </Col>
              </Row>
              <Row>
                <input
                  ref={fileInputRef}
                  id="file"
                  name="file"
                  type="file"
                  onChange={onSelectedFile}
                  style={{ display: "none" }}
                  multiple
                />
                <Col marginTop={10}>
                  {formik.values.Attachments.map((attachment: TAttachment) => (
                    <Attachment
                      key={attachment.FileName}
                      files={0}
                      link=""
                      size={String(attachment.Size)}
                      title={attachment.OriginalFileName}
                      onDelete={() => handleDeleteAttachment(attachment)}
                    />
                  ))}
                  {filesQueue.map((file: File) => (
                    <Attachment
                      key={file.name}
                      files={0}
                      link=""
                      size={String(file.size)}
                      title={file.name}
                      progress={uploadProgress.get(file.name)}
                      error={uploadErrors.get(file.name)}
                      onDelete={() => handleDeleteAttachmentOnQueue(file)}
                    />
                  ))}
                  {formik.touched.Attachments && formik.errors.Attachments && (
                    <Feedback state={"error"} showState={true} showErrorOnly>
                      {formik.errors.Attachments}
                    </Feedback>
                  )}
                </Col>
              </Row>
            </div>
            <Row>
              <StyledFooterToolbar
                marginTop={10}
                color={config.theme.primaryColor}
              >
                <div>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="sendBtt"
                  >
                    {t("components.sharepoint.send")}
                  </Button>
                  <Button
                    iconButton
                    variant="texted"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <Paperclip />
                  </Button>
                </div>
                <div>
                  <Button
                    iconButton
                    variant="texted"
                    onClick={() => onDelete(message.Id)}
                  >
                    <Trash2 />
                  </Button>
                </div>
              </StyledFooterToolbar>
            </Row>
          </form>
        </StyledBody>
      </Modal>
    );
  }
);

export default ComposeMail;
