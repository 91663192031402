import { FC } from "react";
import { Card, CardBody } from "../../Common/Card";
import SectionTitle from "../../Common/SectionTitle";
import { StyledHeader } from "./style";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Tooltip,
  Legend,
} from "devextreme-react/chart";
import { currencyFormatter } from "../../../utils/number";

type Props = {
  data: { month: string; prevYear: number; currYear?: number }[];
  prevYear: string;
  currYear: string;
  title: string;
};

function customizeTooltip(pointInfo: any) {
  return {
    html: `<div>
      <div class="tooltip-header" style="margin-bottom: 5px;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5px;
      border-bottom: 1px solid #c5c5c5;" >${pointInfo.argumentText}</div>
      <div class="tooltip-body" style="width: 170px;">
        <div class="series-name" style="font-weight: normal;
        opacity: 0.6;
        display: inline-block;
        line-height: 1.5;
        padding-right: 10px;
        width: 60px;">
          <span class='top-series-name'>${
            pointInfo.points[0].seriesName
          }</span>:
        </div>
        <div class="value-text" style="display: inline-block;
        line-height: 1.5;
        width: 100px;">
          <span class='top-series-value'>${currencyFormatter.format(
            pointInfo.points[0].valueText
          )}</span>
        </div>
        <div class="series-name"  style="font-weight: normal;
        opacity: 0.6;
        display: inline-block;
        line-height: 1.5;
        padding-right: 10px;
        width: 60px;">
          <span class='bottom-series-name'>${
            pointInfo.points[1] ? `${pointInfo.points[1].seriesName}: ` : ""
          }</span>
        </div>
        <div class="value-text" style="display: inline-block;
        line-height: 1.5;
        width: 100px;">
          <span class='bottom-series-value'>${
            pointInfo.points[1]
              ? `${currencyFormatter.format(pointInfo.points[1].valueText)} `
              : ""
          }</span>
        </div>
      </div>
    </div>`,
  };
}

const TotalFundDistributionWidget: FC<Props> = ({
  data,
  prevYear,
  currYear,
  title,
}) => {
  return (
    <Card height={[null, null, null, "100%"]}>
      <StyledHeader>
        <SectionTitle title={title} />
      </StyledHeader>
      <CardBody p={["10px", "10px"]}>
        <Chart id="chart" dataSource={data}>
          <CommonSeriesSettings
            argumentField="month"
            type="bar"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          >
            {/* <Label visible={true}>
              <Format type="fixedPoint" precision={0} />
            </Label> */}
          </CommonSeriesSettings>
          <Series valueField="prevYear" name={prevYear} />
          <Series valueField="currYear" name={currYear} />
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
          ></Legend>
          <Tooltip
            enabled={true}
            shared={true}
            customizeTooltip={customizeTooltip}
          />
        </Chart>
      </CardBody>
    </Card>
  );
};

export default TotalFundDistributionWidget;
