import { FC, useMemo } from "react";
import MonthlyWorkingHoursWidget from "../../components/BasicBusinessData/MonthlyWorkingHoursWidget";
import { Col } from "../../components/Common/Grid";
import { BIMonthlyData, BusinessChart, BusinessCharts } from "../../types";

type Props = {
  monthlyData: BIMonthlyData[];
  charts: BusinessChart[];
};

const RowThree: FC<Props> = ({ monthlyData, charts }) => {
  const years: { value: string; name: string }[] = useMemo(() => {
    const list: { value: string; name: string }[] = [];

    for (let sample of monthlyData) {
      if (!list.find((item) => item.value === String(sample.Anno))) {
        list.push({
          value: String(sample.Anno),
          name: String(sample.Anno),
        });
      }
    }
    return list;
  }, [monthlyData]);

  const activeChart = useMemo(() => {
    return charts.find(
      (chart) => chart.Name === BusinessCharts.MONTHLY_WORKING_HOURS
    );
  }, [charts]);

  const data: ({ month: string } & { [year: string]: number })[] =
    useMemo(() => {
      const list: ({ month: string } & { [year: string]: number })[] = [];

      for (let i = 0; i < monthlyData.length; i++) {
        const itemIndex = list.findIndex(
          (item) => item.month === monthlyData[i].DescrizioneMese
        );

        if (itemIndex < 0) {
          const record = {
            month: monthlyData[i].DescrizioneMese,
          };
          // @ts-ignore
          record[String(monthlyData[i].Anno)] = monthlyData[i].OreLavorate;
          // @ts-ignore
          list.push(record);
        } else {
          list[itemIndex][String(monthlyData[i].Anno)] =
            monthlyData[i].OreLavorate;
        }
      }

      return list;
    }, [monthlyData]);

  return (
    <>
      <Col lg={12} xl={12} mt="10px">
        <MonthlyWorkingHoursWidget
          years={years}
          data={data}
          title={activeChart?.Title || ""}
        />
      </Col>
    </>
  );
};

export default RowThree;
