import React, { useState } from "react";
import { User, LogOut } from "react-feather";
import { useTranslation } from "react-i18next";
import { CONFIG_EXTERNAL_LINKS } from "../../../../config/externalLinks";
import { classicTheme } from "../../../../styled";
import { Company, ExternalLink, User as TUser } from "../../../../types";
import { getUserInitials } from "../../../../utils/user";
import { AvatarInitial, Avatar } from "../../../Common/Avatar";
import { Dropdown, DropdownToggle } from "../../../Common/Dropdown";
import {
  StyledDropMenu,
  StyledAuthorName,
  StyledDropItem,
  StyledDivider,
  StyledAvatar,
} from "./style";

type Props = {
  user: TUser;
  company: Company | null;
  externalLinks: ExternalLink[];
};

const ProfileDropdown: React.FC<Props> = ({ user, company, externalLinks }) => {
  const { t } = useTranslation();
  const [imageNotFound, setImageNotFound] = useState(false);
  return (
    <Dropdown direction="down" className="dropdown-profile">
      <DropdownToggle variant="texted">
        <StyledAvatar size="sm" shape="circle">
          {user.Picture && user.Picture.length > 0 && !imageNotFound ? (
            <img
              src={user.Picture}
              alt={user.Firstname}
              onError={() => setImageNotFound(true)}
            />
          ) : (
            <AvatarInitial backgroundColor={classicTheme.colors.primary}>
              {getUserInitials(user)}
            </AvatarInitial>
          )}
        </StyledAvatar>
      </DropdownToggle>
      <StyledDropMenu>
        <Avatar size="lg" shape="circle">
          {user.Picture && user.Picture.length > 0 && !imageNotFound ? (
            <img
              src={user.Picture}
              alt={user.Firstname}
              onError={() => setImageNotFound(true)}
            />
          ) : (
            <AvatarInitial backgroundColor={classicTheme.colors.primary}>
              {getUserInitials(user)}
            </AvatarInitial>
          )}
        </Avatar>
        <StyledAuthorName>
          {user.Firstname} {user.Lastname}
        </StyledAuthorName>
        {company && (
          <StyledDropItem path={`/company/${company.Id}/user-profile`}>
            <User size="24" />
            {t("components.profileDropdown.profile")}
          </StyledDropItem>
        )}
        <StyledDivider />
        {externalLinks &&
          externalLinks.map((link) => {
            // @ts-ignore
            const Icon = CONFIG_EXTERNAL_LINKS[link.Name].icon;

            return link.Description.length > 0 ? (
              <StyledDropItem path={link.Description}>
                <Icon size="24" />
                {t(`components.externalLinks.${link.Name}`)}
              </StyledDropItem>
            ) : null;
          })}
        {externalLinks && externalLinks.length > 0 && <StyledDivider />}
        <StyledDropItem path="/logout" mt="10px">
          <LogOut size="24" />
          {t("components.menu.signOut")}
        </StyledDropItem>
      </StyledDropMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
