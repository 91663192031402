import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchAttachmentActivities } from "../../../hooks/sharepoint.hooks";
import { classicTheme } from "../../../styled";
import { Attachment, AttachmentActivity, Message } from "../../../types";
import { getUserInitials } from "../../../utils/user";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import Heading from "../../Common/Heading";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  ModalTitle,
} from "../../Common/Modal";
import TimelineBody from "../../Common/Timeline/TimelineBody";
import TimelineGroup from "../../Common/Timeline/TimelineGroup";
import TimelineItem from "../../Common/Timeline/TimelineItem";
import TimelineTime from "../../Common/Timeline/TimelineTime";
import { StyledEmptyStateText, StyledHeaderWrapper } from "./style";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type Props = {
  attachment: Attachment;
  show: boolean;
  messageId: Message["Id"];
  onClose: () => any;
};

export const AttachmentActivitiesModal: React.FC<Props> = ({
  attachment,
  show,
  messageId,
  onClose,
}) => {
  const { t } = useTranslation();

  const { data: activities = [] } = useFetchAttachmentActivities(
    messageId,
    attachment.Id
  );

  return (
    <Modal show={show} onClose={onClose} centered>
      <ModalHeader>
        <ModalTitle>{attachment.OriginalFileName}</ModalTitle>
        <ModalClose onClose={onClose}>x</ModalClose>
      </ModalHeader>
      <ModalBody>
        {activities.length === 0 ? (
          <StyledEmptyStateText>
            {t("components.sharepoint.noActivities")}
          </StyledEmptyStateText>
        ) : (
          <TimelineGroup>
            {activities.map((activity) => (
              <TimelineItem key={activity.Id}>
                <TimelineTime>
                  {dayjs
                    .utc(activity.ActivityDate)
                    .format("DD/MM/YYYY HH:mm:ss")}
                </TimelineTime>
                <StyledTimelineBody activity={activity} />
              </TimelineItem>
            ))}
          </TimelineGroup>
        )}
      </ModalBody>
    </Modal>
  );
};

type StyleTimelineBodyProps = {
  activity: AttachmentActivity;
};

const StyledTimelineBody: React.FC<StyleTimelineBodyProps> = ({ activity }) => {
  const [imageNotFound, setImageNotFound] = useState(false);

  return (
    <TimelineBody>
      <StyledHeaderWrapper>
        <Avatar size="sm" shape="circle" marginRight="0.5rem">
          {activity.Picture && activity.Picture.length > 0 && !imageNotFound ? (
            <img
              src={activity.Picture}
              alt={activity.Firstname}
              onError={() => setImageNotFound(true)}
            />
          ) : (
            <AvatarInitial backgroundColor={classicTheme.colors.primary}>
              {/* @ts-ignore */}
              {getUserInitials({
                Firstname: activity.Firstname,
                Lastname: activity.Lastname,
              })}
            </AvatarInitial>
          )}
        </Avatar>
        <Heading mb={0}>
          {activity.Firstname} {activity.Lastname}
        </Heading>
      </StyledHeaderWrapper>
    </TimelineBody>
  );
};
