import { FC } from "react";
import { Notification } from "../../../types";
import { Avatar, AvatarInitial } from "../../Common/Avatar";

import {
  StyledListMiddle,
  StyledListTitle,
  StyledListEnd,
  StyledListText,
  StyledContainer,
} from "./style";

type Props = {
  notification: Notification;
  onClick: (notificationId: Notification["Id"]) => void;
};

const Item: FC<Props> = ({ notification, onClick }) => {
  return (
    <StyledContainer onClick={() => onClick(notification.Id)}>
      <Avatar size="sm" shape="rounded">
        <AvatarInitial>
          {notification.Field.Description.charAt(0)}
        </AvatarInitial>
      </Avatar>
      <StyledListMiddle>
        <StyledListText>{notification.Field.Description}</StyledListText>
        <StyledListTitle>{notification.Subject}</StyledListTitle>
      </StyledListMiddle>
      <StyledListEnd>
        <StyledListText>
          {notification.CreatedAt.toLocaleDateString()}
        </StyledListText>
      </StyledListEnd>
    </StyledContainer>
  );
};

export default Item;
