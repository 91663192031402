import { Home } from "react-feather";
import { useTranslation } from "react-i18next";
import { Company, Screens } from "../../../../types";
import { Nav, NavLink } from "../../../Common/Nav/nav";
import { StyledWrapper } from "./style";

type Props = {
  activeScreen: Screens;
  activeCompany: Company | null;
};

export const Breadcrumbs: React.FC<Props> = ({
  activeScreen,
  activeCompany,
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <Nav customStyle={"with-icon"} fontSize="13px">
        <NavLink path={activeCompany ? `/company/${activeCompany.Id}` : `/`}>
          <Home />
        </NavLink>
      </Nav>
      <span>/</span>
      <span>{t(`components.menu.${activeScreen}`)}</span>
    </StyledWrapper>
  );
};
