import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import PageHeader from "../../components/AccessConfiguration/PageHeader";
import Content from "../../components/Common/Content";
import { useApplications } from "../../hooks/applications.hooks";
import { useStores } from "../../hooks/useStores";
import { Company, Screens } from "../../types";
import { useTranslation } from "react-i18next";
import { Row } from "../../components/Common/Grid";
import { Main } from "../../components/AccessConfiguration/Main";
import { useCompanyUsers } from "../../hooks/companyUsers.hooks";
import { useNavigate } from "react-router-dom";
import { useActiveUser } from "../../hooks/useActiveUser";
import {
  useConfirmUserAccessConfiguration,
  useRevokeUserAccessConfiguration,
  useUserAccessConfiguration,
} from "../../hooks/accessConfigurations.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useActiveCompany } from "../../hooks/useActiveCompany";

export const AccessConfiguration: React.FC = observer(() => {
  const { session, ui } = useStores();
  const { data: accessConfiguration = null } = useUserAccessConfiguration();
  const { refetch: refetchCompanyUsers, data: companyUsers = [] } =
    useCompanyUsers(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const activeUser = useActiveUser();
  const config = useScreenConfig(Screens.ACCESS_CONFIGURATION);
  const {
    isLoading: isSubmitting,
    mutateAsync: onConfirmUserAccessConfiguration,
  } = useConfirmUserAccessConfiguration();
  const activeCompanyId = useActiveCompany();

  const { isLoading, mutateAsync: onRevokeUserAccessConfiguration } =
    useRevokeUserAccessConfiguration();

  useEffect(() => {
    ui.setActiveScreen(Screens.ACCESS_CONFIGURATION);
  }, []);

  useEffect(() => {
    const activeCompany: Company | undefined = session.companies.find(
      (c) => c.Id === Number(activeCompanyId)
    );

    if (
      activeCompany &&
      !(Boolean(activeCompany.IsMasterUser) || Boolean(session.user?.Internal))
    ) {
      navigate("/");
    }
  }, [session.companies, activeCompanyId, navigate, session.user]);

  useEffect(() => {
    if (session.user?.Internal) {
      refetchCompanyUsers();
    }
  }, [session.user]);

  const handleChangeUser = (event: any) => {
    navigate(`.?user=${event.target.value}`);
  };

  return (
    <>
      <Content borderBottomWidth="1px">
        <PageHeader
          prev={[]}
          title=""
          wcText={t("components.menu.access-configuration")}
          isMasterUser={
            Boolean(session.activeCompany?.IsMasterUser) ||
            Boolean(session.user?.Internal)
          }
          isInternalUser={session.user?.Internal}
          companyUsers={companyUsers || []}
          onUserChange={handleChangeUser}
          activeUser={activeUser}
        />
      </Content>
      <Content mt={[null, null, null, "0px"]}>
        <Row>
          {accessConfiguration && (
            <Main
              userAccessConfiguration={accessConfiguration}
              onConfirmUserAccessConfiguration={
                onConfirmUserAccessConfiguration
              }
              onRevokeUserAccessConfiguration={onRevokeUserAccessConfiguration}
              isSubmitting={isSubmitting || isLoading}
              config={config}
              isMasterUser={Boolean(session.activeCompany?.IsMasterUser)}
            />
          )}
        </Row>
      </Content>
    </>
  );
});
