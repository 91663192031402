import { FC, useCallback } from "react";
import { StyledHeader, StyledFooter, StyledFooterLink } from "./style";
import { Card } from "../../Common/Card";
import { Company, Message, ScreenConfig } from "../../../types";
import SectionTitle from "../../Common/SectionTitle";
import { ListGroup, ListGroupItem } from "../../Common/ListGroup";
import { useTranslation } from "react-i18next";
import { Row } from "../../Common/Grid";
import Item from "./Item";
import { useNavigate } from "react-router-dom";
import { SharepointEmptyState } from "../../Sharepoint/EmptyState";

type Props = {
  messages: Message[];
  companyId: Company["Id"];
  config: ScreenConfig;
};

export const SharepointWidget: FC<Props> = ({
  messages,
  companyId,
  config,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Icon } = config;

  const handleItemClick = useCallback(
    (id: Message["Id"]) => {
      navigate(`/company/${companyId}/sharepoint/${id}`);
    },
    [companyId, navigate]
  );

  return (
    <Card height="100%">
      <StyledHeader color={config.theme.primaryColor}>
        <Row alignItems="center" paddingLeft="1rem">
          <Icon color={config.theme.primaryColor} />
          <SectionTitle title={t("components.menu.sharepoint")} />
        </Row>
      </StyledHeader>
      <ListGroup flush>
        {messages.length === 0 && (
          <SharepointEmptyState
            message={t("components.sharepoint.emptyStateWidget")}
          />
        )}
        {messages.map((message) => (
          <ListGroupItem key={message.Id} display="flex" px={[null, "20px"]}>
            <Item message={message} onClick={handleItemClick} />
          </ListGroupItem>
        ))}
      </ListGroup>
      <StyledFooter>
        <StyledFooterLink path={`/company/${companyId}/sharepoint`}>
          {t("components.sharepoint.viewAll")}
          <i className="icon ion-md-arrow-down mg-l-5" />
        </StyledFooterLink>
      </StyledFooter>
    </Card>
  );
};
