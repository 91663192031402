import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ScreenConfig,
  SectionAbility,
  UserAccessConfiguration,
} from "../../../types";
import { Col, Row } from "../../Common/Grid";
import Heading from "../../Common/Heading";
import { Card } from "../Card";
import ConfirmUserBtn from "../ConfirmUserBtn";
import { ProfileConfiguration } from "../ProfileConfiguration";
import RevokeUserBtn from "../RevokeUserBtn";
import { UserAvatar } from "../UserAvatar";

type Props = {
  userAccessConfiguration: UserAccessConfiguration;
  onConfirmUserAccessConfiguration: () => void;
  onRevokeUserAccessConfiguration: () => void;
  isSubmitting: boolean;
  config: ScreenConfig;
  isMasterUser: boolean;
};

export const Main: React.FC<Props> = ({
  userAccessConfiguration,
  isSubmitting,
  onConfirmUserAccessConfiguration,
  onRevokeUserAccessConfiguration,
  config,
  isMasterUser,
}) => {
  const { t } = useTranslation();

  const { User, Applications, Communications, DataAnalysis, Notifications } =
    userAccessConfiguration;

  const applicationsAbilties: SectionAbility[] = useMemo(() => {
    return Applications.map((application) => ({
      key: application.Id,
      title: application.Description,
      isEnabled: application.UserIsEnabled,
    }));
  }, [Applications]);

  const communicationsAbilties: SectionAbility[] = useMemo(() => {
    return Communications.map((communication) => ({
      key: communication.Id,
      title: communication.Description,
      isEnabled: communication.UserIsEnabled,
    }));
  }, [Communications]);

  const notificationsAbilties: SectionAbility[] = useMemo(() => {
    return Notifications.map((notification) => ({
      key: notification.Id,
      title: notification.Description,
      isEnabled: notification.UserIsEnabled,
    }));
  }, [Notifications]);

  const dataAnalysisAbilties: SectionAbility[] = useMemo(() => {
    return DataAnalysis.map((dataAnalysis) => ({
      key: dataAnalysis.Id,
      title: `${dataAnalysis.Description} ${dataAnalysis.Field.Description}`,
      isEnabled: dataAnalysis.UserIsEnabled,
    }));
  }, [DataAnalysis]);

  return (
    <Col lg={9} margin="0 auto">
      <Row gutters={10}>
        <div>
          <UserAvatar user={User} />
          {User.Confirmed ? (
            <RevokeUserBtn
              isSubmitting={isSubmitting}
              backgroundColor={"#dc3545"}
              onClick={onRevokeUserAccessConfiguration}
              disabled={!isMasterUser}
            />
          ) : (
            <ConfirmUserBtn
              isSubmitting={isSubmitting}
              backgroundColor={config.theme.primaryColor}
              onClick={onConfirmUserAccessConfiguration}
              disabled={!isMasterUser}
            />
          )}
          {User.IsMasterUser ? <p>Utente Master</p> : null}
        </div>
        <div style={{ flexGrow: 1 }}>
          <Heading tt="uppercase" fontWeight="600" mb="16px">
            {t("components.profile.title")}
          </Heading>
          <ProfileConfiguration user={User} />
          {!(Boolean(User.IsMasterUser) && Boolean(User.Internal)) && (
            <>
              <Heading tt="uppercase" fontWeight="600" mb="16px">
                {t("components.accessConfiguration.listTitle")}
              </Heading>
              <Card
                abilities={applicationsAbilties}
                title={t("components.menu.applications")}
              />
              <Card
                abilities={communicationsAbilties}
                title={t("components.menu.communications")}
              />
              <Card
                abilities={notificationsAbilties}
                title={t("components.menu.notifications")}
              />
              <Card
                abilities={dataAnalysisAbilties}
                title={t("components.menu.basic-business-data")}
              />
            </>
          )}
        </div>
      </Row>
    </Col>
  );
};
