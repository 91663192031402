import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataAnalysis } from "../types";

export const useActiveDataAnalysis = () => {
  const params = useParams();

  const [activeDataAnalysisId, setActiveDataAnalysisId] = useState<
    DataAnalysis["Id"] | undefined
  >(undefined);

  useEffect(() => {
    setActiveDataAnalysisId(Number(params.dataAnalysisId));
  }, [params.dataAnalysisId]);

  return activeDataAnalysisId;
};
