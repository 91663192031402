import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button";
import { Spinner } from "../../Common/Spinner";
import { StyledWrap } from "./style";

type Props = {
  backgroundColor: string;
  isSubmitting: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const RevokeUserBtn: FC<Props> = ({
  backgroundColor,
  isSubmitting,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <StyledWrap backgroundColor={backgroundColor}>
      <Button
        onClick={onClick}
        fullwidth
        hasIcon={isSubmitting}
        disabled={isSubmitting || disabled}
      >
        {isSubmitting && <Spinner size="xs" />}
        {t("components.accessConfiguration.revokeUser")}
      </Button>
    </StyledWrap>
  );
};

export default RevokeUserBtn;
