import styled, { device } from "../../../styled";

export const StyledWrap = styled.div`
  ${device.large} {
    width: 260px;
  }
`;

export const StyledName = styled.h5`
  letter-spacing: -0.5px;
  margin-bottom: 2px;
`;

export const StyledAvatarWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

export const StyledChangePictureCTA = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
