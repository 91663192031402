import { observer } from "mobx-react";
import { SharepointWidget } from "../../components/Home/SharepointWidget";
import { useUserUnreadedMessages } from "../../hooks/sharepoint.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Screens } from "../../types";

const WrapperNotificationsWidget: React.FC = observer(() => {
  const { session } = useStores();
  const config = useScreenConfig(Screens.SHAREPOINT);
  const { data: unreadedMessages } = useUserUnreadedMessages(
    session.user?.Id || -1,
    session.activeCompanyId || -1,
    3
  );

  return (
    <SharepointWidget
      messages={unreadedMessages?.data || []}
      companyId={session.activeCompanyId || -1}
      config={config}
    />
  );
});

export default WrapperNotificationsWidget;
