import { FC } from "react";
import { Message } from "../../../types";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import dayjs from "dayjs";

import {
  StyledListMiddle,
  StyledListTitle,
  StyledListEnd,
  StyledListText,
  StyledContainer,
} from "./style";

type Props = {
  message: Message;
  onClick: (id: Message["Id"]) => void;
};

const Item: FC<Props> = ({ message, onClick }) => {
  return (
    <StyledContainer onClick={() => onClick(message.Id)}>
      <Avatar size="sm" shape="rounded">
        <AvatarInitial>{message.Subject.charAt(0)}</AvatarInitial>
      </Avatar>
      <StyledListMiddle>
        <StyledListText>{message.Field.Description}</StyledListText>
        <StyledListTitle>{message.Subject}</StyledListTitle>
      </StyledListMiddle>
      <StyledListEnd>
        <StyledListText>
          {dayjs(message.CreatedAt).format("DD/MM/YY HH:mm")}
        </StyledListText>
      </StyledListEnd>
    </StyledContainer>
  );
};

export default Item;
