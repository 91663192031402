import { StyledFooter } from "./style";

export const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <div>
        FEINAR SRL - Servizi per l'Impresa - Copyright © 2022 - Tutti i diritti
        riservati -{" "}
        <a
          href="https://www.iubenda.com/privacy-policy/50852772"
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a>{" "}
        -{" "}
        <a
          href="https://www.iubenda.com/privacy-policy/50852772/cookie-policy"
          target="_blank"
          rel="noreferrer"
        >
          Cookie policy
        </a>
      </div>
      <div>
        Via Guido Rossa, 101 - Loc. Mier - 32100 Belluno (BL) - Italy - Tel. +39
        0437 2121 - PEC: feinar@legalmail.it - Cod.Fiscale - P.IVA - N.
        iscrizione Registro Imprese TV-BL: 00091610253 - R.E.A. BL 75956
      </div>
    </StyledFooter>
  );
};
