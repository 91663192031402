import { FC } from "react";
import { EyeOff, Paperclip } from "react-feather";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import { Checkbox } from "../../Common/Forms/Checkbox";
import Heading from "../../Common/Heading";
import Text from "../../Common/Text";
import { MediaBody } from "../../UI/Media";
import {
  StyledHiddenIcon,
  StyledMedia,
  StyledMetaWrapper,
  StyledNameWrapper,
  StyledTop,
} from "./style";

interface IProps {
  id: number;
  color?: string;
  image?: string;
  name: string;
  time: string;
  title: string;
  excerpt: string;
  status?: "read" | "unread";
  numAttachments?: number;
  numAttachmentsDownloaded?: number;
  isChecked?: boolean;
  onClick?: () => void;
  onCheckChange?: (checked: boolean) => any;
  isVisibile: boolean;
}

const GroupItem: FC<IProps> = ({
  id,
  color,
  image,
  name,
  time,
  title,
  excerpt,
  status,
  numAttachments,
  numAttachmentsDownloaded,
  isChecked,
  onClick,
  onCheckChange,
  isVisibile,
}) => {
  return (
    <StyledMedia className={status} onClick={onClick}>
      <div style={{ zIndex: 20 }}>
        <Avatar>
          {!image && isVisibile && (
            <AvatarInitial bg={color}>{name.substring(0, 1)}</AvatarInitial>
          )}
          {image && isVisibile && <img src={image} alt="name" />}
          {!isVisibile && (
            <StyledHiddenIcon>
              <EyeOff />
            </StyledHiddenIcon>
          )}
        </Avatar>
        {onCheckChange && (
          <div
            style={{ marginTop: "0.2rem", marginLeft: "0.15rem" }}
            onClick={(event) => event.stopPropagation()}
          >
            <Checkbox
              label=""
              name={`check-${id}`}
              id={`check-${id}`}
              checked={isChecked}
              color={color}
              onClick={(event) => {
                event.stopPropagation();
                // @ts-ignore
                onCheckChange(event.target.checked);
              }}
            />
          </div>
        )}
      </div>
      <MediaBody ml="15px">
        <StyledTop>
          <StyledNameWrapper>
            <Text as="span" fontSize="12px">
              {name}
            </Text>
          </StyledNameWrapper>
          <StyledMetaWrapper>
            <Text as="span" fontSize="11px">
              {numAttachments && numAttachments > 0 && (
                <span>
                  {numAttachments} /{" "}
                  {numAttachments - (numAttachmentsDownloaded || 0)}{" "}
                  <Paperclip size={"11px"} />
                </span>
              )}{" "}
              {time}
            </Text>
          </StyledMetaWrapper>
        </StyledTop>
        <Heading fontSize="13px" fontWeight={status === "unread" ? 600 : 400}>
          {title}
        </Heading>
        <Text fontSize="12px" color="text3">
          {excerpt}
        </Text>
      </MediaBody>
    </StyledMedia>
  );
};

GroupItem.defaultProps = {
  status: "read",
};

export default GroupItem;
