import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataAnalysis } from "../../../types";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import { Button } from "../../Common/Button";

import { StyledListMiddle, StyledListTitle, StyledListEnd } from "./style";

type Props = {
  workspace: DataAnalysis;
  defaultColor: string;
  onClick: () => void;
};

const Item: FC<Props> = ({ workspace, defaultColor, onClick }) => {
  const { t } = useTranslation();
  const [imageNotFound, setImageNotFound] = useState(false);

  return (
    <>
      <Avatar size="sm" shape="rounded">
        {workspace.Logo && !imageNotFound && (
          <img
            src={workspace.Logo}
            alt={workspace.Description}
            onError={() => setImageNotFound(true)}
          />
        )}
        {(!workspace.Logo || (workspace.Logo && imageNotFound)) && (
          <AvatarInitial backgroundColor={defaultColor}>
            {workspace.Description.charAt(0)}
          </AvatarInitial>
        )}
      </Avatar>
      <StyledListMiddle>
        <StyledListTitle>{workspace.Description}</StyledListTitle>
      </StyledListMiddle>
      <StyledListEnd>
        <Button variant="outlined" color={"white"} onClick={onClick}>
          {t("components.workspaceCard.open")}
        </Button>
      </StyledListEnd>
    </>
  );
};

export default Item;
