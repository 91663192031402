import { observer } from "mobx-react";
import { useMemo } from "react";
import { CommunicationsWidget } from "../../components/Home/CommunicationsWidget";
import { useUserCompanyAbilities } from "../../hooks/abilties.hook";
import { useUserUnreadedCommunications } from "../../hooks/communications.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Communication, Screens } from "../../types";

const WrapperCommunicationsWidget: React.FC = observer(() => {
  const { session } = useStores();
  const config = useScreenConfig(Screens.COMMUNICATIONS);
  const { data: abilties } = useUserCompanyAbilities();
  const { data } = useUserUnreadedCommunications(
    session.user?.Id || -1,
    session.activeCompanyId || -1
  );

  const unreadedCommunications = useMemo(
    () => (data?.data || []).slice(0, 3) as Communication[],
    [data]
  );

  return (
    <CommunicationsWidget
      communications={unreadedCommunications}
      companyId={session.activeCompanyId || -1}
      config={config}
      enabled={abilties?.communications}
    />
  );
});

export default WrapperCommunicationsWidget;
