import { FC, useMemo } from "react";
import ConversionWidget from "../../components/BasicBusinessData/ConversionWidget";
import { Col } from "../../components/Common/Grid";
import {
  BIYearlyData,
  BusinessChart,
  BusinessCharts,
  IConversion,
} from "../../types";
import dayjs from "dayjs";
import { currencyFormatter } from "../../utils/number";

type Props = {
  yearlyData: BIYearlyData[];
  charts: BusinessChart[];
};

const RowOne: FC<Props> = ({ yearlyData, charts }) => {
  const conversions: IConversion[] = useMemo(() => {
    const list = [];
    const currentYear = dayjs().year();
    const currentYearData = yearlyData.find(
      (data) => data.Anno === currentYear
    );
    const prevYearData = yearlyData.find(
      (data) => data.Anno === currentYear - 1
    );

    const conversionChart = charts.find(
      (chart) => chart.Name === BusinessCharts.COST_CONVERSION
    );

    if (currentYearData && prevYearData && conversionChart) {
      const { CostoTotale, DeltaCostoTotalePercent, DeltaCostoTotale } =
        currentYearData;
      list.push({
        id: 1,
        title: conversionChart.Title,
        rate: currencyFormatter.format(CostoTotale),
        change: {
          delta: currencyFormatter.format(DeltaCostoTotale),
          percentage: `${
            DeltaCostoTotalePercent > 0 ? "+" : ""
          }${DeltaCostoTotalePercent} %`,
          growth: CostoTotale > prevYearData.CostoTotale ? "up" : "down",
          time: String(currentYear),
        },
      } as IConversion);
    }
    return list;
  }, [yearlyData, charts]);

  return (
    <>
      {conversions.map((data) => (
        <Col sm={6} lg={3} mt={["10px", null, null, "0px"]} key={data.id}>
          <ConversionWidget
            title={data.title}
            rate={data.rate}
            change={data.change}
          />
        </Col>
      ))}
    </>
  );
};

export default RowOne;
