import React from "react";
import { Link } from "react-router-dom";
import { Bell } from "react-feather";
import Notification from "../DropdownItem";
import {
  StyledDropMenu,
  StyledDropHeader,
  StyledDropItem,
  StyledDropFooter,
  StyledBadge,
} from "../HeaderDropdownElements";
import { Dropdown, DropdownToggle } from "../../../Common/Dropdown";
import { Company, Notification as TNotification } from "../../../../types";
import { useTranslation } from "react-i18next";

type Props = {
  activeCompanyId: Company["Id"];
  unreadedNotifications: TNotification[];
};

const NotificationDropdown: React.FC<Props> = ({
  activeCompanyId,
  unreadedNotifications,
}) => {
  const { t } = useTranslation();
  return (
    <Dropdown direction="down">
      <DropdownToggle variant="texted">
        <Bell className="header-icon" />
        {unreadedNotifications.length > 0 && (
          <StyledBadge>{unreadedNotifications.length}</StyledBadge>
        )}
      </DropdownToggle>
      <StyledDropMenu>
        <StyledDropHeader>
          {t("components.menu.notifications").toUpperCase()}
        </StyledDropHeader>
        {(unreadedNotifications.slice(0, 5) as TNotification[]).map(
          (notification) => (
            <StyledDropItem
              key={notification.Id}
              path={`/company/${activeCompanyId}/notifications/${notification.Id}`}
            >
              <Notification notification={notification} />
            </StyledDropItem>
          )
        )}
        <StyledDropFooter>
          <Link to={`/company/${activeCompanyId}/notifications`}>
            {t("components.notifications.viewAll")}
          </Link>
        </StyledDropFooter>
      </StyledDropMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
