import { useMutation, useQueryClient } from "react-query";
import api from "../services/api";
import { UserProfileConfig } from "../types";
import { useActiveUser } from "./useActiveUser";

export const useSetUserProfileConfigs = () => {
  const queryClient = useQueryClient();
  const userId = useActiveUser();

  return useMutation((data: UserProfileConfig) => api.updateUserProfile(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`user-company-configs-${userId}`);
    },
  });
};

export const useUploadPicture = () => {
  const userId = useActiveUser();

  return useMutation((file: File) => api.uploadPicture(userId || -1, file));
};
