import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Group from "../../components/Notifications/Group";
import { useNotificationsFields } from "../../hooks/notificationField.hooks";
import { useNotifications } from "../../hooks/notifications.hooks";
import useDebounce from "../../hooks/useDebounce";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Notification, NotificationFilters, Screens } from "../../types";
import { fuzzyMatch } from "../../utils/string";

export const Notifications: React.FC = observer(() => {
  const location = useLocation();
  const { session } = useStores();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const debouncedQuery = useDebounce(search, 300);
  const config = useScreenConfig(Screens.NOTIFICATIONS);

  const {
    isError,
    error,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage = false,
  } = useNotifications();

  const { data: notificationsFields = [] } = useNotificationsFields();

  const activeField = useMemo(() => {
    return location.hash && location.hash.length > 0
      ? location.hash.replace("#", "")
      : "inbox";
  }, [location.hash]);

  const fieldDescription = useMemo(() => {
    return activeField === "inbox"
      ? t("components.notifications.inbox")
      : notificationsFields.find((f) => f.Id === Number(activeField))
          ?.Description || "";
  }, [activeField, notificationsFields, t]);

  const notifications: Notification[] = useMemo(() => {
    return data?.pages.map((page) => page.data).flat() || [];
  }, [data]);

  const filteredNotifications = useMemo(() => {
    let filteredNotifications =
      activeField === "inbox"
        ? notifications
        : notifications.filter(
            (notification) => notification.Field.Id === Number(activeField)
          );

    if (session.notificationStatusFilter === NotificationFilters.UNREADED) {
      filteredNotifications = filteredNotifications.filter(
        (notification) => notification.Unreaded
      );
    }

    return debouncedQuery.length > 0
      ? filteredNotifications.filter((notification) =>
          fuzzyMatch(notification.Subject, debouncedQuery)
        )
      : filteredNotifications;
  }, [
    activeField,
    debouncedQuery,
    notifications,
    session.notificationStatusFilter,
  ]);

  return (
    <Group
      notifications={filteredNotifications}
      isFetchingNextPage={isFetchingNextPage}
      search={search}
      onLoadMore={fetchNextPage}
      hasNextPage={hasNextPage}
      onSearchChange={setSearch}
      fieldDescription={fieldDescription}
      filter={session.notificationStatusFilter}
      onFilterChange={(e) => session.setNotificationsFilter(e.target.value)}
      config={config}
    />
  );
});
