import { FC } from "react";
import { Folder, Inbox } from "react-feather";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationField, User } from "../../../types";
import UserSelect from "../../Applications/UserSelect";
import { Badge } from "../../Common/Badge";
import { Nav, NavLink } from "../../Common/Nav/nav";
import Label from "../Label";
import { StyledWrap } from "./style";

type Props = {
  notificationsFields: NotificationField[];
  activeField: string | "inbox" | null;
  isInternalUser: boolean | undefined;
  companyUsers: User[];
  onUserChange: (event: React.ChangeEvent) => any;
};

const MainNav: FC<Props> = ({
  notificationsFields,
  activeField,
  isInternalUser,
  companyUsers,
  onUserChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();

  const handleNavigateTo = (hash: string) => {
    navigate(`./${locationSearch}#${hash}`);
  };

  return (
    <StyledWrap>
      <Nav customStyle="sidebar" fontSize="13px">
        {isInternalUser && (
          <div style={{ marginBottom: "1rem" }}>
            <UserSelect
              value={null}
              users={companyUsers}
              onChange={onUserChange}
            />
          </div>
        )}

        <NavLink
          path={"#inbox"}
          active={activeField === "inbox"}
          onClick={(e) => {
            // Override navigation to clean previous state on history
            // @ts-ignore
            e.preventDefault();
            handleNavigateTo("inbox");
          }}
        >
          <Inbox />
          <span>{t("components.notifications.inbox")}</span>
        </NavLink>
        <Label marginTop="1rem" pl="10px">
          {t("components.notifications.fields")}
        </Label>

        {notificationsFields.map((field) => (
          <NavLink
            key={field.Id}
            path={`#${field.Id}`}
            active={activeField === String(field.Id)}
            onClick={(e) => {
              // Override navigation to clean previous state on history
              // @ts-ignore
              e.preventDefault();
              handleNavigateTo(String(field.Id));
            }}
          >
            <Folder />
            <span>{field.Description}</span>
            {field.TotalUnreaded > 0 && (
              <Badge color="danger" variant="contained">
                {field.TotalUnreaded}
              </Badge>
            )}
          </NavLink>
        ))}
      </Nav>
    </StyledWrap>
  );
};

export default MainNav;
