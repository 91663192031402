import { FC } from "react";
import { StyledHeader, StyledFooter, StyledFooterLink } from "./style";
import { Card } from "../../Common/Card";
import { Application, Company, ScreenConfig } from "../../../types";
import SectionTitle from "../../Common/SectionTitle";
import { ListGroup, ListGroupItem } from "../../Common/ListGroup";
import { useTranslation } from "react-i18next";
import { Row } from "../../Common/Grid";
import Item from "./Item";
import { ApplicationsEmptyState } from "../../Applications/EmptyState";

type Props = {
  applications: Application[];
  companyId: Company["Id"];
  config: ScreenConfig;
  enabled?: boolean;
};

export const ApplicationsWidget: FC<Props> = ({
  applications,
  companyId,
  config,
  enabled = false,
}) => {
  const { t } = useTranslation();
  const { Icon } = config;
  return (
    <Card height="100%">
      <StyledHeader color={config.theme.primaryColor}>
        <Row alignItems="center" paddingLeft="1rem">
          <Icon color={config.theme.primaryColor} />
          <SectionTitle title={t("components.menu.applications")} />
        </Row>
      </StyledHeader>
      {enabled ? (
        <>
          <ListGroup flush>
            {applications.length === 0 && <ApplicationsEmptyState />}
            {applications.map((application) => (
              <ListGroupItem
                key={application.Id}
                display="flex"
                px={[null, "20px"]}
              >
                <Item application={application} />
              </ListGroupItem>
            ))}
          </ListGroup>
          <StyledFooter>
            <StyledFooterLink path={`/company/${companyId}/applications`}>
              {t("components.applicationsWidget.viewAll")}
              <i className="icon ion-md-arrow-down mg-l-5" />
            </StyledFooterLink>
          </StyledFooter>
        </>
      ) : (
        <ApplicationsEmptyState
          message={t("components.application.disabled")}
        />
      )}
    </Card>
  );
};
