import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { QUERY_CONFIGS } from "../config/query";
import api from "../services/api";
import {
  Attachment,
  Company,
  ComposeMessage,
  Message,
  MessagesBulkActionPayload,
  SharepointFilters,
  UpdateSharepointSupervisedUsers,
  User,
} from "../types";
import { useActiveCompany } from "./useActiveCompany";
import { useActiveUser } from "./useActiveUser";
import { useSessionActiveUser } from "./useSessionActiveUser";
import { useStores } from "./useStores";

export const useSharepointRecipients = (companyId: Company["Id"]) =>
  useQuery([`sharepoint-recipients-${companyId}`, companyId], () =>
    api.fetchSharepointRecipients(companyId)
  );

export const useUserCompanyDistributionLists = (companyId: Company["Id"]) =>
  useQuery([`user-company-distribution-lists-${companyId}`, companyId], () =>
    api.fetchUserCompanyDistributionLists(companyId)
  );

export const useSharepointCCN = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`sharepoint-cccn-${activeCompany}`, activeCompany],
    () => api.fetchSharepointCCN(activeCompany || -1),
    {
      enabled: !!activeCompany,
    }
  );
};

export const useSharepointSupervisionedUsers = () => {
  const activeCompany = useActiveCompany();

  return useQuery(
    [`sharepoint-supervisioned-users-${activeCompany}`, activeCompany],
    () => api.fetchSharepointSupervisionedUsers(activeCompany || -1),
    {
      enabled: !!activeCompany,
    }
  );
};

export const useUpdateSharepointSupervisionedUser = () => {
  const queryClient = useQueryClient();
  const activeCompany = useActiveCompany();

  return useMutation(
    (data: UpdateSharepointSupervisedUsers) =>
      api.updateSharepointSupervisionedUsers(activeCompany || -1, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `sharepoint-supervisioned-users-${activeCompany}`
        );
      },
    }
  );
};

export const useSharepointFields = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();
  const { sharepoint } = useStores();

  return useQuery(
    [
      `sharepoint-fields-${userId}-${activeCompany}-${
        sharepoint.inboxFilter || ""
      }`,
      userId,
      activeCompany,
      sharepoint.inboxFilter,
    ],
    () =>
      api.fetchSharepointFields(
        userId || -1,
        activeCompany || -1,
        sharepoint.inboxFilter
      ),
    {
      enabled: !!userId && !!activeCompany,
    }
  );
};
export const useSendMessage = () => {
  const queryClient = useQueryClient();
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useMutation(
    (data: ComposeMessage) =>
      api.sendMessage(userId || -1, activeCompany || -1, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `sent-messages-${userId}-${activeCompany}`
        );
      },
    }
  );
};

export const useUpdateMessage = () => {
  const queryClient = useQueryClient();
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useMutation(
    (data: ComposeMessage) =>
      api.updateMessage(userId || -1, activeCompany || -1, data.Id || -1, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `sent-messages-${userId}-${activeCompany}`
        );
      },
    }
  );
};

export const useUserUnreadedMessages = (
  userId: User["Id"],
  companyId: Company["Id"],
  limit: number
) =>
  useQuery(
    [`unreaded-messages-${userId}-${companyId}`, userId, companyId, limit],
    () => api.fetchInboxMessages(userId, companyId, 0, limit, true)
  );

export const useFetchInboxMessages = (query?: string) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();
  const { sharepoint } = useStores();

  return useInfiniteQuery(
    [
      `inbox-messages-${userId}-${activeCompany}-${sharepoint.inboxFilter}`,
      userId,
      activeCompany,
      query,
      sharepoint.inboxFilter,
    ],
    ({ pageParam = 0 }) =>
      api.fetchInboxMessages(
        userId || -1,
        activeCompany || -1,
        pageParam,
        QUERY_CONFIGS.limit,
        false,
        query,
        sharepoint.inboxFilter
      ),
    {
      enabled: !!userId && !!activeCompany,
      keepPreviousData: true,
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.length > 0 ? lastPage.nextCursor : false,
    }
  );
};

export const useFetchInboxMessage = (messageId: Message["Id"]) => {
  const userId = useActiveUser();
  const sessionUserId = useSessionActiveUser();
  const activeCompany = useActiveCompany();
  const { sharepoint } = useStores();

  return useQuery(
    [
      `inbox-message-${userId}-${activeCompany}-${messageId}`,
      userId,
      activeCompany,
      messageId,
    ],
    () =>
      api.fetchInboxMessage(
        Number(userId),
        Number(activeCompany),
        Number(messageId),
        [SharepointFilters.SUPERVISOR_UNREADED].includes(sharepoint.inboxFilter)
          ? Number(sessionUserId)
          : undefined
      ),
    {
      enabled: false,
    }
  );
};

export const useDownloadInboxMessageAttachments = (
  messageId: Message["Id"]
) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();
  return useQuery(
    [`attachments-message-${userId}-${activeCompany}-${messageId}`, messageId],
    () =>
      api.downloadInboxMessageAttachments(
        userId || -1,
        activeCompany || -1,
        messageId
      ),
    {
      enabled: false,
    }
  );
};

export const useFetchSentMessages = (query?: string) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    [`sent-messages-${userId}-${activeCompany}`, userId, activeCompany, query],
    ({ pageParam = 0 }) =>
      api.fetchSentMessages(
        userId || -1,
        activeCompany || -1,
        pageParam,
        QUERY_CONFIGS.limit,
        query
      ),
    {
      enabled: !!userId && !!activeCompany,
      keepPreviousData: true,
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.length > 0 ? lastPage.nextCursor : false,
    }
  );
};

export const useFetchSentMessage = (messageId: Message["Id"]) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [`sent-message-${userId}-${activeCompany}-${messageId}`, messageId],
    () =>
      api.fetchSentMessage(userId || -1, activeCompany || -1, messageId || -1),
    {
      enabled: false,
    }
  );
};

export const useUploadAttachment = (
  messageId: Message["Id"],
  onUploadProgress: (event: ProgressEvent, file: File) => any
) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();
  return useMutation((file: File) =>
    api.uploadAttachment(
      userId || -1,
      activeCompany || -1,
      messageId,
      file,
      onUploadProgress
    )
  );
};

export const useTrakReadedMessage = () => {
  const sessionUserId = useSessionActiveUser();
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();
  const { sharepoint } = useStores();

  return useMutation(
    (messageId: Message["Id"]) =>
      api.trackMessageActivity(
        Number(sessionUserId),
        Number(activeCompany),
        messageId
      ),
    {
      onSuccess: (data, variables, context) => {
        // queryClient.invalidateQueries(
        //   `inbox-messages-${userId}-${activeCompany}-${sharepoint.inboxFilter}`
        // );
        sharepoint.addReadedMessage(variables);

        queryClient.invalidateQueries(
          `sharepoint-fields-${sessionUserId}-${activeCompany}-${
            sharepoint.inboxFilter || ""
          }`
        );
        queryClient.invalidateQueries(
          `unreaded-messages-${sessionUserId}-${activeCompany}`
        );
        queryClient.invalidateQueries(
          `sharepoint-fields-${userId}-${activeCompany}-${
            sharepoint.inboxFilter || ""
          }`
        );
        queryClient.invalidateQueries(
          `unreaded-messages-${userId}-${activeCompany}`
        );
      },
    }
  );
};

export const useTrakDownloadedAttachments = (messageId: Message["Id"]) => {
  const userId = useSessionActiveUser();
  const companyId = useActiveCompany();
  const { sharepoint } = useStores();

  const queryClient = useQueryClient();
  return useMutation(
    (attachmentIds: Attachment["Id"][]) =>
      api.trackAttachmentsActivity(
        userId || -1,
        companyId || -1,
        messageId,
        attachmentIds
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `inbox-messages-${userId}-${companyId}-${sharepoint.inboxFilter}`
        );
      },
    }
  );
};

export const useDeleteMessage = () => {
  const queryClient = useQueryClient();
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useMutation(
    (id: Message["Id"]) =>
      api.deleteMessage(userId || -1, activeCompany || -1, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `sent-messages-${userId}-${activeCompany}`
        );
      },
    }
  );
};

export const useDeleteAttachment = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();
  const queryClient = useQueryClient();

  return useMutation(
    (attachment: Attachment) =>
      api.deleteAttachment(
        userId || -1,
        activeCompany || -1,
        attachment.MessageId,
        attachment.Id
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `sent-messages-${userId}-${activeCompany}`
        );
      },
    }
  );
};

export const useMessagesBulkAction = () => {
  const userId = useActiveUser();
  const companyId = useActiveCompany();
  const { sharepoint } = useStores();

  const queryClient = useQueryClient();
  return useMutation(
    (action: MessagesBulkActionPayload) =>
      api.performMessagesBulkAction(userId || -1, companyId || -1, action),
    {
      onSuccess: () => {
        Promise.all([
          queryClient.invalidateQueries(
            `sharepoint-fields-${userId}-${companyId}-${
              sharepoint.inboxFilter || ""
            }`
          ),
          queryClient.invalidateQueries(
            `inbox-messages-${userId}-${companyId}`
          ),
          queryClient.invalidateQueries(
            `unreaded-messages-${userId}-${companyId}`
          ),
          queryClient.invalidateQueries(`sent-messages-${userId}-${companyId}`),
        ]);
      },
    }
  );
};

export const useFetchAttachmentActivities = (
  messageId: Message["Id"],
  attachmentId: Attachment["Id"]
) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [
      `attachment-activities-${userId}-${activeCompany}-${attachmentId}`,
      attachmentId,
    ],
    () =>
      api.fetchAttachmentActivities(
        userId || -1,
        activeCompany || -1,
        messageId,
        attachmentId
      )
  );
};
