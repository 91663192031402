import React from "react";
import { Notification } from "../../../../types";
import { Media } from "../../../UI/Media";
import { StyledMediaBody, StyledStrong, StyledText, StyledSpan } from "./style";

type Props = {
  notification: Notification;
};

const DropdownItem: React.FC<Props> = ({ notification }) => {
  return (
    <Media>
      <StyledMediaBody>
        <StyledStrong>{notification.Subject}</StyledStrong>
        <StyledText>{notification.Field.Description}</StyledText>
        <StyledSpan>{notification.CreatedAt.toLocaleDateString()}</StyledSpan>
      </StyledMediaBody>
    </Media>
  );
};

export default DropdownItem;
