import React, { useState } from "react";
import { ChevronDown, Star } from "react-feather";
import { useDisclosure } from "../../../../hooks/useDisclosure";
import { Company } from "../../../../types";
import { Avatar } from "../../../Common/Avatar";
import { Icon } from "../../../Common/Icon";
import { Media, MediaLeft } from "../../../UI/Media";
import AdvancedSearchCompany from "../AdvancedSearchCompany";
import {
  StyledAdvancedSwitchContainer,
  StyledMediaBody,
  StyledStrong,
} from "./style";

const CompanyItem: React.FC<{
  company: Company;
  color?: string;
  onSetFavorite: (companyId: Company["Id"]) => any;
}> = ({ company, color, onSetFavorite }) => {
  const [imageNotFound, setImageNotFound] = useState(false);
  return (
    <Media alignItems="center" paddingRight="1rem">
      <MediaLeft>
        <Avatar size="sm" shape="rounded">
          {company.Logo && company.Logo.length > 0 && !imageNotFound ? (
            <img
              src={company.Logo}
              alt={company.BusinessName}
              onError={() => setImageNotFound(true)}
            />
          ) : (
            <Icon name="HiOfficeBuilding" color={color} />
          )}
        </Avatar>
      </MediaLeft>
      <StyledMediaBody>
        <StyledStrong color={color}>{company.BusinessName}</StyledStrong>
      </StyledMediaBody>
      <span style={{ marginLeft: "1rem" }} onClick={(e) => e.stopPropagation()}>
        {company.Favorite ? (
          <Star
            fill={color}
            stroke="0"
            onClick={(e) => {
              e.stopPropagation();
              onSetFavorite(company.Id);
            }}
          />
        ) : (
          <Star
            onClick={(e) => {
              e.stopPropagation();
              onSetFavorite(company.Id);
            }}
          />
        )}
      </span>
    </Media>
  );
};

type Props = {
  companies: Company[];
  activeCompany: Company | null;
  primaryColor: string;
  onSetFavorite: (companyId: Company["Id"]) => any;
};

const CompanyAdvancedSwitch: React.FC<Props> = ({
  activeCompany,
  companies,
  primaryColor,
  onSetFavorite,
}) => {
  const { isOpen, open, close } = useDisclosure();
  return (
    <>
      <StyledAdvancedSwitchContainer onClick={open}>
        {activeCompany && (
          <CompanyItem company={activeCompany} onSetFavorite={onSetFavorite} />
        )}
        {companies.length > 1 && <ChevronDown />}
      </StyledAdvancedSwitchContainer>

      <AdvancedSearchCompany
        handleOnClose={close}
        show={isOpen}
        primaryColor={primaryColor}
        companies={companies}
      />
    </>
  );
};

export default CompanyAdvancedSwitch;
