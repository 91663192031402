import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "../../../hooks/useDisclosure";
import {
  CompanyConfig,
  SetCompanyConfig,
  User,
  UserProfileConfig,
} from "../../../types";
import { Col, Row } from "../../Common/Grid";
import Heading from "../../Common/Heading";
import ChangePictureModal from "../ChangePictureModal";
import { CompanyConfiguration } from "../CompanyConfiguration";
import { ProfileConfiguration } from "../ProfileConfiguration";
import { UserAvatar } from "../UserAvatar";

type Props = {
  user: User;
  companyConfigs: CompanyConfig[];
  onUpdateCompanyConfig: (data: SetCompanyConfig) => any;
  onUpdateProfileConfig: (data: UserProfileConfig) => any;
  onUploadPicture: (data: any) => any;
};

export const Main: React.FC<Props> = ({
  user,
  companyConfigs,
  onUpdateCompanyConfig,
  onUpdateProfileConfig,
  onUploadPicture,
}) => {
  const { t } = useTranslation();
  const { isOpen, open, close } = useDisclosure();

  const handleUploadPicture = useCallback(
    async (data: any) => {
      await onUploadPicture(data);

      close();
    },
    [close, onUploadPicture]
  );

  return (
    <Col lg={9} margin="0 auto">
      <Row gutters={10}>
        <UserAvatar user={user} onEditClick={open} />
        <div style={{ flexGrow: 1 }}>
          <Heading tt="uppercase" fontWeight="600" mb="16px">
            {t("components.profile.title")}
          </Heading>
          <ProfileConfiguration onSubmit={onUpdateProfileConfig} user={user} />
          {companyConfigs.length > 1 && (
            <>
              <Heading tt="uppercase" fontWeight="600" mb="16px">
                {t("components.companyConfiguration.title")}
              </Heading>

              {companyConfigs.map((config) => (
                <CompanyConfiguration
                  companyConfig={config}
                  onSubmit={onUpdateCompanyConfig}
                />
              ))}
            </>
          )}
        </div>
      </Row>
      <ChangePictureModal
        show={isOpen}
        onClose={close}
        onSubmit={handleUploadPicture}
      />
    </Col>
  );
};
