import { FC } from "react";
import { Search } from "react-feather";
import {
  StyledGroup,
  StyledHeader,
  StyledBody,
  StyledTopDiv,
  StyledList,
  StyledReadMore,
} from "./style";
import { Button } from "../../Common/Button";
import Heading from "../../Common/Heading";
import ScrollBar from "../../Common/Scrollbar";
import SearchForm from "../SearchForm";
import GroupItem from "../GroupItem";
import { useLocation, useNavigate } from "react-router-dom";
import { Notification, ScreenConfig } from "../../../types";
import { useTranslation } from "react-i18next";
import FilterForm from "../FilterForm";
import { NotificationsEmptyState } from "../EmptyState";
import { Footer } from "../../Layout/Footer";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type Props = {
  notifications: Notification[];
  isFetchingNextPage: boolean;
  search: string;
  filter: string;
  hasNextPage: boolean;
  fieldDescription: string;
  config: ScreenConfig;
  onLoadMore: () => void;
  onSearchChange: (value: string) => void;
  onFilterChange: (e: any) => void;
};

const Group: FC<Props> = ({
  notifications,
  isFetchingNextPage,
  search,
  filter,
  hasNextPage,
  fieldDescription,
  config,
  onLoadMore,
  onSearchChange,
  onFilterChange,
}) => {
  const navigate = useNavigate();
  const { hash, search: locationSearch } = useLocation();
  const { t } = useTranslation();

  const handleClick = (id: Notification["Id"]) => {
    navigate(`./${id}${locationSearch}${hash}`);
  };

  return (
    <StyledGroup>
      <StyledHeader>
        <Search className="search" />
        <SearchForm value={search} onChange={onSearchChange} />
        <FilterForm value={filter} onChange={onFilterChange} />
      </StyledHeader>
      <ScrollBar top="55px">
        <StyledBody>
          <StyledTopDiv>
            <Heading tt="uppercase" fontWeight={600} mb="0px">
              {fieldDescription}
            </Heading>
          </StyledTopDiv>
          <StyledList>
            {notifications.length === 0 && <NotificationsEmptyState />}
            {notifications.map((notification) => (
              <GroupItem
                key={notification.Id}
                color={config.theme.primaryColor}
                name={notification.Field.Description}
                time={dayjs
                  .utc(notification.CreatedAt)
                  .format("DD/MM/YYYY HH:mm")}
                title={notification.Subject}
                excerpt=""
                status={notification.Unreaded ? "unread" : "read"}
                onClick={() => handleClick(notification.Id)}
              />
            ))}
          </StyledList>
          {hasNextPage && (
            <StyledReadMore>
              <Button
                size="xs"
                color="light"
                fullwidth
                disabled={isFetchingNextPage}
                onClick={onLoadMore}
              >
                {t("components.notifications.loadMore")}
              </Button>
            </StyledReadMore>
          )}
          <Footer />
        </StyledBody>
      </ScrollBar>
    </StyledGroup>
  );
};

export default Group;
