import { observer } from "mobx-react";
import { useMemo } from "react";
import { ApplicationsWidget } from "../../components/Home/ApplicationsWidget";
import { useUserCompanyAbilities } from "../../hooks/abilties.hook";
import { useApplications } from "../../hooks/applications.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Screens } from "../../types";

const WrapperApplicationsWidget: React.FC = observer(() => {
  const { session } = useStores();
  const config = useScreenConfig(Screens.APPLICATIONS);
  const { data: remoteApplications = [] } = useApplications();
  const { data: abilties } = useUserCompanyAbilities();

  const enabledApplications = useMemo(() => {
    return remoteApplications
      .filter((application) => application.Enabled)
      .slice(0, 3);
  }, [remoteApplications]);

  return (
    <ApplicationsWidget
      applications={enabledApplications}
      companyId={session.activeCompanyId || -1}
      config={config}
      enabled={abilties?.applications}
    />
  );
});

export default WrapperApplicationsWidget;
