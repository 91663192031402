import { useQuery } from "react-query";
import api from "../services/api";
import { useActiveCompany } from "./useActiveCompany";
import { useActiveUser } from "./useActiveUser";

export const useDataAnalysis = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [`data-analysis-${userId}-${activeCompany}`, userId, activeCompany],
    () => api.fetchDataAnalysis(userId || -1, activeCompany || -1),
    {
      enabled: !!userId && !!activeCompany,
    }
  );
};

export const useBiMonthlyData = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [`bi-monthly-data-${userId}-${activeCompany}`, userId, activeCompany],
    () => api.fetchBiMonthlyData(userId || -1, activeCompany || -1),
    {
      enabled: !!userId && !!activeCompany,
    }
  );
};

export const useBiYearlyData = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [`bi-yearly-data-${userId}-${activeCompany}`, userId, activeCompany],
    () => api.fetchBiYearlyData(userId || -1, activeCompany || -1),
    {
      enabled: !!userId && !!activeCompany,
    }
  );
};
