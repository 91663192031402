import React, { FC } from "react";
import { StyledForm } from "./style";
import { useTranslation } from "react-i18next";
import { Input } from "../../Common/Forms";
import { Button } from "../../Common/Button";
import { Search } from "react-feather";

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent) => any;
};

const SearchForm: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <Input
        type="text"
        id="application-search"
        name="application-search"
        placeholder={t("components.applicationCard.searchPlaceholder")}
        value={value}
        onChange={onChange}
      />
      <Button variant="texted" iconButton size="md">
        <Search />
      </Button>
    </StyledForm>
  );
};

export default SearchForm;
