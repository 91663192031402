import { makeAutoObservable } from "mobx";
import { Screens, Stores } from "../types";

export class UIStore {
  stores: Stores;

  activeScreen: Screens | null = null;

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  setActiveScreen = (screen: Screens) => {
    this.activeScreen = screen;
  };
}
