const it = {
  common: {
    confirm: "Conferma",
  },
  components: {
    menu: {
      home: "Home",
      communications: "Comunicazioni e Notizie",
      notifications: "Notifiche",
      applications: "Applicazioni",
      profile: "Profilo",
      sharepoint: "File Exchange",
      signOut: "Esci",
      changeCompany: "Ricerca azienda",
      searchPlaceholder: "Ricerca...",
      basicBusinessData: "Dati Aziendali Base",
      "basic-business-data": "Dati Aziendali Base",
      "access-configuration": "Configurazione accessi",
    },
    application: {
      emptyState: "Nessuna applicazione",
      disabled: "Nessuna applicazione disponibile",
    },
    applicationCard: {
      login: "Accedi",
      notAvailable: "Applicazione non disponibile",
      info: "Informazioni",
      searchPlaceholder: "Ricerca applicazione",
      filterAll: "Tutte le applicazioni",
      filterEnabled: "Applicazioni abilitate",
    },
    profileDropdown: {
      profile: "Profilo",
    },
    sharepoint: {
      bttCompose: "Nuovo",
      newMessage: "Nuovo messaggio",
      recipients: "Destinatari",
      recipientsPlaceholder: "Indica uno o più destinatari",
      field: "Area",
      fieldPlaceholder: "Seleziona l'area",
      subject: "Oggetto",
      subjectPlaceholder: "Oggetto..",
      send: "Invia",
      sent: "Inviati",
      inbox: "Messaggi in arrivo",
      drafts: "Bozze",
      draft: "Bozza",
      fileAlreadyAttached: "{{filename}} già allegato",
      inboxNav: "Inbox",
      loadMore: "Carica più messaggi",
      searchPlaceholder: "Ricerca...",
      viewAll: "Visualizza tutti i messaggi",
      filterAll: "Tutti",
      filterUnreaded: "Da leggere",
      filterSupervisorUnreaded: "Da leggere per me",
      filterSupervisedUnreaded: "Da leggere per {{name}}",
      filterAllUnreaded: "Da leggere per tutti",
      filterReadedMixed: "Letti da qualcuno",
      emptyState: "Nessun messaggio",
      emptyStateWidget: "Nessun nuovo messaggio",
      savingMessage: "Salvataggio in corso...",
      messageSaved: "Messaggio salvato in bozze",
      deletingMessage: "Cancellazione in corso...",
      messageDeleted: "Messaggio cancellato",
      sendingMessage: "Invio in corso...",
      messageSent: "Messaggio inviato",
      noSubjectLabel: "(nessun oggetto)",
      reply: "Rispondi",
      downloadAttachments: "Scarica tutti gli allegati",
      downloadingAttachments: "Preparazione dell'archivio in corso...",
      downloadCompleted: "Archivio salvato correttamente",
      ccn: "Conoscenza",
      markUnreaded: "Segna come Da leggere",
      markReaded: "Segna come già letto",
      markAsHidden: "Nascondi i messaggi",
      markAsSent: "Pubblica i messaggi",
      noActivities: "Nessuna attività su questo allegato",
      searchAttachments: "Ricerca allegati...",
      emptySearchAttachments: "Nessun allegato individuato",
      attachmentsDisclaimer:
        "Gli allegati sono disponibili per {{days}} giorni",
      bulkActionInProgress: "Aggiornamento in corso...",
      bulkActionCompleted: "Modifica eseguita correttamente",
      groups: "Gruppi",
      settings: "Impostazioni",
      mailForwarding: "Notifiche ricezione messaggi",
      alertInvalidFile:
        "Formato del file non ammesso. Se si sta cercando di caricare una cartella, deve essere trasformata in un file zip contenente tutti i documenti e rieffettuare il caricamento.",
      showAllRecipients: "Visualizza tutti i contatti",
      settingsSupervied: "Supervisionato",
      settingsEnabeld: "Abilitato",
      settingsTotMessageUnreadedSupervisor: "Messaggi da leggere per me",
      settingsTotMessageUndereadSupervised:
        "Messaggi da leggere dal supervisionato",
      settingsTotMessageUnreaded: "Messaggi da leggere per tutti",
    },
    notifications: {
      fields: "Aree",
      loadMore: "Carica più notifiche",
      inbox: "Tutte le notifiche",
      searchPlaceholder: "Ricerca...",
      viewAll: "Visualizza tutte le notifiche",
      filterAll: "Tutte",
      filterUnreaded: "Da leggere",
      emptyState: "Nessuna notifica",
      emptyStateWidget: "Nessuna nuova notifica",
      disabled: "Notifiche non disponibili",
    },
    communications: {
      fields: "Aree",
      loadMore: "Carica più comunicazioni",
      inbox: "Tutte le comunicazioni",
      searchPlaceholder: "Ricerca...",
      viewAll: "Visualizza tutte le comunicazioni",
      emptyState: "Nessuna comunicazione",
      emptyStateWidget: "Nessuna nuova comunicazione",
      disabled: "Comunicazioni non disponibili",
    },
    applicationsWidget: {
      viewAll: "Vai alle Applicazioni",
    },
    notificationsWidget: {
      viewAll: "Vai alle Notifiche",
    },
    communicationsWidget: {
      viewAll: "Vai alle Comunicazioni e Notizie",
    },
    basicBusinessDataWidget: {
      viewAll: "Visualizza i dati",
    },
    basicBusinessData: {
      emptyState: "Dati aziendali base non disponibili",
      disabled: "Dati aziendali base non disponibili",
      charts: {
        labels: {
          men: "Maschi",
          women: "Femmine",
          total: "Totale",
        },
      },
    },
    workspaceCard: {
      open: "Accedi",
      searchPlaceholder: "Ricerca",
      filterAll: "Tutte le aree",
    },
    companyConfiguration: {
      title: "Configurazione Notifiche",
      email: "Email",
      updatingConfiguration: "Aggiornamento in corso...",
      configurationUpdated: "Aggiornamento eseguito correttamente",
      mobileNumber: "Cellulare",
    },
    profile: {
      title: "Profilo",
      userData: "Dati utente",
      email: "Email",
      fiscalCode: "Codice Fiscale",
      alias: "Alias",
      phoneNumber: "Telefono",
      mobileNumber: "Cellulare",
      otpChannel: "Ricezione OTP",
      updatingConfiguration: "Aggiornamento in corso...",
      profileUpdated: "Aggiornamento eseguito correttamente",
      changeProfilePicture: "Cambia foto profilo",
      dndMessage: "Trascina la tua foto qui",
      or: "Oppure",
      uploadPicture: "Carica una foto",
      upload: "Carica",
      uploadingPicture: "Caricamento in corso...",
      pictureUpdated: "Aggiornamento eseguito correttamente",
      otpChannels: {
        email: "Email",
        pn: "Notifica push",
      },
    },
    externalLinks: {
      USER_MANUAL: "Manuale d'uso",
      VIDEO_TUTORIAL: "Video tutorial",
    },
    accessConfiguration: {
      listTitle: "Abilitazioni",
      email: "Email",
      confirmUser: "Abilita utente",
      revokeUser: "Disabilita utente",
    },
    forbidden: {
      message: "Non disponi delle autorizzazioni per accedere",
    },
    companyListGroup: {
      checkRole: "Ruolo definito",
    },
  },
  messages: {
    sharepointCompose: {
      subjectRequired: "L'oggetto deve essere minimo di 2 caratteri",
      recipientsRequired: "Specificare almeno un destinatario",
      areaRequired: "Specificare l'area",
      textRequired: "Scrivere il messaggio",
      attachmentsRequired: "Devi aggiungere almeno un allegato",
      uploadErrorCodes: {
        InvalidFileError: "Tipologia di file non permessa",
        Error: "Sì è verificato un errore in fase di caricamento",
      },
    },
    companyConfiguration: {
      invalidEmail: "Indirizzo email non valido",
    },
    profileConfiguration: {
      invalidAlias:
        "L'alias deve essere composto da almeno 8 caratteri alfanumerici",
      invalidPhone: "Il numero deve essere senza il prefisso nazionale",
    },
  },
};

export default it;
