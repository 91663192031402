import { FC } from "react";
import { IConversion } from "../../../types";
import { Card, CardBody } from "../../Common/Card";
import {
  StyledCardTitle,
  StyledCardBottom,
  StyledCardRate,
  StyledCardText,
  StyledChangePercent,
} from "./style";

type IProps = Omit<IConversion, "id">;

const ConversionWidget: FC<IProps> = ({ title, rate, change }) => {
  return (
    <Card>
      <CardBody>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardBottom>
          <StyledCardRate>{rate}</StyledCardRate>
          <StyledCardText>
            <StyledChangePercent
              $growth={change.growth === "up" ? "down" : "up"}
            >
              {change?.delta} {change?.percentage}{" "}
              {change?.growth === "up" && <i className="fa fa-arrow-up" />}
              {change?.growth === "down" && (
                <i className="fa fa-arrow-down" />
              )}{" "}
            </StyledChangePercent>
            {change?.time && <>{change?.time}</>}
          </StyledCardText>
        </StyledCardBottom>
      </CardBody>
    </Card>
  );
};

export default ConversionWidget;
