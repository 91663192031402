import React, { FC } from "react";
import { StyledForm } from "./style";
import { useTranslation } from "react-i18next";
import { Select } from "../../Common/Forms";
import { Button } from "../../Common/Button";
import { Filter } from "react-feather";
import { Field } from "../../../types";

type Props = {
  value: string;
  options: Field[];
  onChange: (e: React.ChangeEvent) => any;
};

const FilterForm: FC<Props> = ({ value, options, onChange }) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <Select value={value} onChange={onChange}>
        <option value="all">{t("components.workspaceCard.filterAll")}</option>
        {options.map((option) => (
          <option key={option.Id} value={String(option.Id)}>
            {option.Description}
          </option>
        ))}
      </Select>
      <Button variant="texted" iconButton size="md">
        <Filter />
      </Button>
    </StyledForm>
  );
};

export default FilterForm;
