import { useFormik } from "formik";
import React from "react";
import { CompanyConfig, SetCompanyConfig } from "../../../types";
import { CardBody } from "../../Common/Card";
import Heading from "../../Common/Heading";
import { Media, MediaBody } from "../../UI/Media";
import CardFooter from "../CardFooter";
import Header from "../Header";
import { StyledCard } from "./style";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Col, Row } from "../../Common/Grid";
import { Input } from "../../Common/Forms";
import { Button } from "../../Common/Button";

type Props = {
  companyConfig: CompanyConfig;
  onSubmit: (data: SetCompanyConfig) => any;
};

export const CompanyConfiguration: React.FC<Props> = ({
  companyConfig,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Id: companyConfig.Id,
      Email: companyConfig.Email,
      MobileNumber: companyConfig.MobileNumber || "",
    },
    validationSchema: Yup.object({
      Email: Yup.string()
        .email(t("messages.companyConfiguration.invalidEmail"))
        .nullable(),
    }),
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledCard mb={["20px", null, null, "25px"]}>
        <Header>
          <Heading tt="uppercase" fontWeight="600" mb="0px">
            {companyConfig.BusinessName}
          </Heading>
        </Header>
        <CardBody p={["20px", "20px", null, "25px"]}>
          <Media display={["block", "flex"]}>
            <MediaBody mt={["25px", 0]}>
              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>{t("components.companyConfiguration.email")}:</Col>
                <Col sm={9}>
                  <Input
                    id="Email"
                    px="0px"
                    borderWidth="0px"
                    placeholder="Email"
                    {...formik.getFieldProps("Email")}
                    feedbackText={formik.getFieldMeta("Email").error}
                    state={
                      formik.getFieldMeta("Email").error ? "error" : "success"
                    }
                    showState={
                      !!formik.getFieldMeta("Email").error &&
                      formik.touched.Email
                    }
                  />
                </Col>
              </Row>

              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>
                  {t("components.companyConfiguration.mobileNumber")}:
                </Col>
                <Col sm={9}>
                  <Input
                    id="MobileNumber"
                    px="0px"
                    borderWidth="0px"
                    placeholder={t(
                      "components.companyConfiguration.mobileNumber"
                    )}
                    {...formik.getFieldProps("MobileNumber")}
                    feedbackText={formik.getFieldMeta("MobileNumber").error}
                    state={
                      formik.getFieldMeta("MobileNumber").error
                        ? "error"
                        : "success"
                    }
                    showState={
                      !!formik.getFieldMeta("MobileNumber").error &&
                      formik.touched.MobileNumber
                    }
                  />
                </Col>
              </Row>
            </MediaBody>
          </Media>
        </CardBody>
        <CardFooter>
          <Button type="submit" variant="texted" color="primary">
            {t("common.confirm")}
          </Button>
        </CardFooter>
      </StyledCard>
    </form>
  );
};
