import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import PageHeader from "../../components/Applications/PageHeader";
import Content from "../../components/Common/Content";
import { useApplications } from "../../hooks/applications.hooks";
import { useStores } from "../../hooks/useStores";
import { Screens } from "../../types";
import { useTranslation } from "react-i18next";
import { Row } from "../../components/Common/Grid";
import { MainContent } from "../../components/Applications/Main";
import useDebounce from "../../hooks/useDebounce";
import { fuzzyMatch } from "../../utils/string";
import { useCompanyUsers } from "../../hooks/companyUsers.hooks";
import { useNavigate } from "react-router-dom";
import { useActiveUser } from "../../hooks/useActiveUser";
import { useSessionActiveUser } from "../../hooks/useSessionActiveUser";

export const Applications: React.FC = observer(() => {
  const { session, ui } = useStores();
  const { data: remoteApplications = [] } = useApplications();
  const { refetch: refetchCompanyUsers, data: companyUsers = [] } =
    useCompanyUsers();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState<"all" | "enabled">("enabled");
  const debouncedQuery = useDebounce(searchQuery, 300);
  const navigate = useNavigate();
  const activeUserId = useActiveUser();
  const sessionUserId = useSessionActiveUser();

  const applications = useMemo(() => {
    const enabledApplications =
      filter === "enabled"
        ? remoteApplications.filter((application) => application.Enabled)
        : remoteApplications;

    const filteredApplications =
      debouncedQuery.length > 0
        ? enabledApplications.filter((application) =>
            fuzzyMatch(application.Description, debouncedQuery)
          )
        : enabledApplications;

    return activeUserId !== sessionUserId && session.user?.Internal
      ? filteredApplications.map((application) => ({
          ...application,
          Url: application.Url.replace(
            `users/${activeUserId}`,
            `users/${sessionUserId}`
          ),
        }))
      : filteredApplications;
  }, [
    activeUserId,
    debouncedQuery,
    filter,
    remoteApplications,
    session.user,
    sessionUserId,
  ]);

  useEffect(() => {
    ui.setActiveScreen(Screens.APPLICATIONS);
  }, []);

  useEffect(() => {
    if (session.user?.Internal) {
      refetchCompanyUsers();
    }
  }, [session.user]);

  const handleSearchChange = useCallback(
    (event: any) => {
      setSearchQuery(event.target.value);
    },
    [setSearchQuery]
  );

  const handleFilterChage = useCallback(
    (event: any) => {
      setFilter(event.target.value);
    },
    [setFilter]
  );

  const handleChangeUser = (event: any) => {
    navigate(`.?user=${event.target.value}`);
  };

  return (
    <>
      <Content borderBottomWidth="1px">
        <PageHeader
          prev={[]}
          title=""
          wcText={t("components.menu.applications")}
          searchValue={searchQuery}
          onSearchChange={handleSearchChange}
          filterValue={filter}
          onFilterChange={handleFilterChage}
          isInternalUser={session.user?.Internal}
          companyUsers={companyUsers || []}
          onUserChange={handleChangeUser}
        />
      </Content>
      <Content mt={[null, null, null, "0px"]}>
        <Row>
          <MainContent applications={applications} />
        </Row>
      </Content>
    </>
  );
});
