import { FC } from "react";
import { File, Folder, Inbox, Send } from "react-feather";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SharepointField } from "../../../types";
import { Badge } from "../../Common/Badge";
import { Nav, NavLink } from "../../Common/Nav/nav";
import Label from "../Label";
import { StyledDisclaimer, StyledWrap } from "./style";

type Props = {
  fields: SharepointField[];
  activeField: string | "sent" | "inbox" | null;
  numDraftMessages: number;
  fileStorageDays: number;
};

const MainNav: FC<Props> = ({
  fields,
  activeField,
  numDraftMessages,
  fileStorageDays,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();

  const handleNavigateTo = (hash: string) => {
    navigate(`./${locationSearch}#${hash}`);
  };

  return (
    <StyledWrap>
      <Nav customStyle="sidebar" fontSize="13px">
        <NavLink
          path={"#inbox"}
          active={activeField === "inbox"}
          onClick={(e) => {
            // Override navigation to clean previous state on history
            // @ts-ignore
            e.preventDefault();
            handleNavigateTo("inbox");
          }}
        >
          <Inbox />
          <span>{t("components.sharepoint.inbox")}</span>
        </NavLink>

        <NavLink
          path={"#sent"}
          active={activeField === "sent"}
          onClick={(e) => {
            // Override navigation to clean previous state on history
            // @ts-ignore
            e.preventDefault();
            handleNavigateTo("sent");
          }}
        >
          <Send />
          <span>{t("components.sharepoint.sent")}</span>
        </NavLink>
        <NavLink
          path={"#drafts"}
          active={activeField === "drafts"}
          onClick={(e) => {
            // Override navigation to clean previous state on history
            // @ts-ignore
            e.preventDefault();
            handleNavigateTo("drafts");
          }}
        >
          <File />
          <span>{t("components.sharepoint.drafts")}</span>
          {numDraftMessages > 0 && (
            <Badge color="info" variant="texted">
              {numDraftMessages}
            </Badge>
          )}
        </NavLink>

        <Label marginTop="1rem" pl="10px">
          {t("components.sharepoint.inboxNav")}
        </Label>
        {fields.map((field) => (
          <NavLink
            key={field.Id}
            path={`#${field.Id}`}
            active={Number(activeField) === field.Id}
            onClick={(e) => {
              // Override navigation to clean previous state on history
              // @ts-ignore
              e.preventDefault();
              handleNavigateTo(String(field.Id));
            }}
          >
            <Folder />
            <span>{field.Description}</span>
            {field.TotalUnreaded > 0 && (
              <Badge color="danger" variant="contained">
                {field.TotalUnreaded}
              </Badge>
            )}
          </NavLink>
        ))}
      </Nav>
      <StyledDisclaimer>
        {t("components.sharepoint.attachmentsDisclaimer", {
          days: fileStorageDays,
        })}
      </StyledDisclaimer>
    </StyledWrap>
  );
};

export default MainNav;
