import { Youtube, FileText } from "react-feather";

export const CONFIG_EXTERNAL_LINKS = {
  USER_MANUAL: {
    icon: FileText,
  },
  VIDEO_TUTORIAL: {
    icon: Youtube,
  },
};
