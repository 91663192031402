import dayjs from "dayjs";
import { FC, useMemo } from "react";
import TotalEmployeesWidget from "../../components/BasicBusinessData/TotalEmployeesWidget";
import TotalFundDistributionWidget from "../../components/BasicBusinessData/TotalFundDistributionWidget";
import { Col } from "../../components/Common/Grid";
import {
  BIMonthlyData,
  BIYearlyData,
  BusinessChart,
  BusinessCharts,
} from "../../types";

type Props = {
  yearlyData: BIYearlyData[];
  monthlyData: BIMonthlyData[];
  charts: BusinessChart[];
};

const RowFour: FC<Props> = ({ yearlyData, monthlyData, charts }) => {
  const prevYear = dayjs().year() - 1,
    currYear = dayjs().year();

  const totalEmployessData = useMemo(() => {
    return yearlyData.map((item) => ({
      year: String(item.Anno),
      men: item.TotMaschi,
      women: item.TotFemmine,
    }));
  }, [yearlyData]);

  const totalFoundChart = useMemo(() => {
    return charts.find(
      (chart) => chart.Name === BusinessCharts.TOTAL_FUND_DISTRIBUTION
    );
  }, [charts]);

  const totalEmployeeChart = useMemo(() => {
    return charts.find(
      (chart) => chart.Name === BusinessCharts.TOTAL_EMPLOYEES
    );
  }, [charts]);

  const totalFundData: {
    month: string;
    prevYear: number;
    currYear?: number;
  }[] = useMemo(() => {
    const list: {
      month: string;
      prevYear: number;
      currYear?: number;
    }[] = [];

    for (let i = 0; i < monthlyData.length; i++) {
      const itemIndex = list.findIndex(
        (item) => item.month === monthlyData[i].DescrizioneMese
      );

      if (itemIndex < 0) {
        const record = {
          month: monthlyData[i].DescrizioneMese,
        };

        if (monthlyData[i].Anno === prevYear) {
          // @ts-ignore
          record["prevYear"] = monthlyData[i].FondoFeriePermessiBancaOre;
        } else {
          // @ts-ignore
          record["currYear"] = monthlyData[i].FondoFeriePermessiBancaOre;
        }
        // @ts-ignore
        list.push(record);
      } else {
        if (monthlyData[i].Anno === prevYear) {
          // @ts-ignore
          list[itemIndex]["prevYear"] =
            monthlyData[i].FondoFeriePermessiBancaOre;
        } else {
          // @ts-ignore
          list[itemIndex]["currYear"] =
            monthlyData[i].FondoFeriePermessiBancaOre;
        }
      }
    }

    return list;
  }, [monthlyData, prevYear]);

  return (
    <>
      <Col lg={8} xl={7} mt="10px">
        <TotalFundDistributionWidget
          data={totalFundData}
          prevYear={String(prevYear)}
          currYear={String(currYear)}
          title={totalFoundChart?.Title || ""}
        />
      </Col>
      <Col lg={4} xl={5} mt="10px">
        <TotalEmployeesWidget
          data={totalEmployessData}
          title={totalEmployeeChart?.Title || ""}
        />
      </Col>
    </>
  );
};

export default RowFour;
/*
const funds = [
  {
    month: "gennaio",
    prevYear: 1458,
    currYear: 2041,
  },
  {
    month: "febbraio",
    prevYear: 1458,
    currYear: 2041,
  },
  {
    month: "marzo",
    prevYear: 1458,
    currYear: 2041,
  },
  {
    month: "aprile",
    prevYear: 1458,
    currYear: 2041,
  },
  {
    month: "maggio",
    prevYear: 1458,
    currYear: 2041,
  },
  {
    month: "giugno",
    prevYear: 1458,
    currYear: 2041,
  },
  {
    month: "luglio",
    prevYear: 1458,
    currYear: 2041,
  },
  {
    month: "agosto",
    prevYear: -8750,
    currYear: -12250,
  },
  {
    month: "settembre",
    prevYear: 1458,
  },
  {
    month: "ottobre",
    prevYear: 1548,
  },
  {
    month: "novembre",
    prevYear: 1458,
  },
  {
    month: "dicembre",
    prevYear: 1548,
  },
];
*/
