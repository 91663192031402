import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import Main from "../../components/Communications/Main";
import {
  useCommunication,
  useDownloadCommunicationAttachments,
  useTrakReadedCommunication,
} from "../../hooks/communications.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Attachment, Screens } from "../../types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const CommunicationDetail: React.FC = () => {
  const { session } = useStores();
  const params = useParams();
  const config = useScreenConfig(Screens.COMMUNICATIONS);
  const { t } = useTranslation();

  const { isFetching, data: communication = null } = useCommunication(
    Number(params.communicationId) || -1
  );

  const { refetch: downloadCommunicationAttachments } =
    useDownloadCommunicationAttachments(Number(params.communicationId));

  const { isLoading: isSubmitting, mutateAsync: track } =
    useTrakReadedCommunication(
      session.user?.Id || -1,
      session.activeCompanyId || -1
    );

  useEffect(() => {
    if (communication && communication.Unreaded && !session.user?.Internal) {
      track(communication.Id);
    }
  }, [communication, session.user]);

  const onDownloadAttachments = useCallback(() => {
    toast.promise(
      downloadCommunicationAttachments(),
      {
        pending: t("components.sharepoint.downloadingAttachments"),
        success: t("components.sharepoint.downloadCompleted"),
        error: "error",
      },
      {
        type: "default",
      }
    );
  }, [downloadCommunicationAttachments, t]);

  const onAttachmentClick = useCallback((attachment: Attachment) => {
    if (attachment.FileURL) {
      const link = document.createElement("a");
      link.href = attachment.FileURL;
      link.setAttribute("download", attachment.OriginalFileName);
      link.setAttribute("target", "_blank");
      //  Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    }
  }, []);

  return communication ? (
    <Main
      communication={communication}
      config={config}
      onDownloadAttachments={onDownloadAttachments}
      onAttachmentClick={onAttachmentClick}
    />
  ) : null;
};
