import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { QUERY_CONFIGS } from "../config/query";
import api from "../services/api";
import { Company, Notification, User } from "../types";
import { useActiveCompany } from "./useActiveCompany";
import { useActiveUser } from "./useActiveUser";

export const useNotifications = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useInfiniteQuery(
    [`notifications-${userId}-${activeCompany}`, userId, activeCompany],
    ({ pageParam = 0 }) =>
      api.fetchNotifications(
        userId || -1,
        activeCompany || -1,
        pageParam,
        QUERY_CONFIGS.limit
      ),
    {
      enabled: !!userId && !!activeCompany,
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.length > 0 ? lastPage.nextCursor : false,
    }
  );
};

export const useUserUnreadedNotification = (
  userId: User["Id"],
  companyId: Company["Id"]
) =>
  useQuery(
    [`unreaded-notifications-${userId}-${companyId}`, userId, companyId],
    () => api.fetchNotifications(userId, companyId, 0, 999999, true)
  );

export const useNotification = (notificationId: Notification["Id"]) => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();

  return useQuery(
    [`notification-${notificationId}-${userId}`, notificationId, userId],
    () =>
      api.fetchNotification(userId || -1, activeCompany || -1, notificationId)
  );
};

export const useTrakReadedNotification = (
  userId: User["Id"],
  companyId: Company["Id"]
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (notificationId: Notification["Id"]) =>
      api.trackNotificationActivity(userId, companyId, notificationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`notifications-${userId}-${companyId}`);
        queryClient.invalidateQueries(
          `notifications-fields-${userId}-${companyId}`
        );
        queryClient.invalidateQueries(
          `unreaded-notifications-${userId}-${companyId}`
        );
      },
    }
  );
};
