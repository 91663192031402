import { EmptyStateContainer, StyledImg, StyledText } from "./style";
import ForbiddenStateImg from "../../../assets/images/padlock.png";
import { useTranslation } from "react-i18next";

type Props = {
  message?: string;
};

export const ForbiddenState: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <EmptyStateContainer>
      <StyledImg src={ForbiddenStateImg} alt="Forbiddend" />
      <StyledText>{message || t("components.forbidden.message")}</StyledText>
    </EmptyStateContainer>
  );
};
