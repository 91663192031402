import { useEffect, useState } from "react";
import { Circle } from "react-feather";
import { menus } from "../config/menu";
import { classicTheme } from "../styled";
import { ScreenConfig, Screens } from "../types";

const DEFAULT_CONFIG: ScreenConfig = {
  Icon: Circle,
  theme: {
    primaryColor: classicTheme.colors.primary,
  },
};

export const useScreenConfig = (key: Screens) => {
  const [config, setConfig] = useState<ScreenConfig>(DEFAULT_CONFIG);

  useEffect(() => {
    const menu = menus.find((menu) => menu.key === key);

    setConfig(
      menu
        ? { Icon: menu.Icon, theme: { primaryColor: menu.theme.background } }
        : DEFAULT_CONFIG
    );
  }, []);

  return config;
};
