import styled, { themeGet, css } from "../../../../styled";
import { MediaBody } from "../../../UI/Media";

export const StyledMediaBody = styled(({ ...rest }) => <MediaBody {...rest} />)`
  margin-left: 0.5rem;
  font-size: 13px;
  display: flex;
`;

export const StyledStrong = styled.strong<{ color?: string }>`
  font-weight: 500;
  color: ${(props) => props.color || themeGet("colors.text2")};

  ${(props) =>
    props.theme.name === "dark" &&
    css`
      color: ${themeGet("colors.white")};
    `}
`;
