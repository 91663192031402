import Content from "../../components/Common/Content";
import { ForbiddenState } from "../../components/Common/ForbiddenState";

export const Forbidden: React.FC = () => {
  return (
    <Content>
      <ForbiddenState />
    </Content>
  );
};
