// https://github.com/feathericons/react-feather/blob/master/src/icons/mail.js
import { forwardRef } from "react";
import PropTypes from "prop-types";

const MailOpen = forwardRef(
  // @ts-ignore
  ({ color = "currentColor", size = 24, ...rest }, ref) => {
    return (
      <svg
        // @ts-ignore
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <path d="M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6" />
        <path d="M22 6L12 13L2 6" />
        <path d="M2 6L12 1L22 6" />
      </svg>
    );
  }
);

MailOpen.propTypes = {
  // @ts-ignore
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MailOpen.displayName = "MailOpen";

export default MailOpen;
