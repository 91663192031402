import styled, { themeGet } from "../../../styled";

export const StyledWrap = styled.div<{ backgroundColor: string }>`
  margin-top: 1rem;
  button {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: ${themeGet("fonts.interUi")};
    font-weight: 500;
    max-width: 150px;
  }
  .composeBtn {
    background-color: ${(props) => props.backgroundColor};
    border-color: ${(props) => props.backgroundColor};
  }
`;
