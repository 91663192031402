import { FC } from "react";
import { User } from "../../../types";
import Breadcrumb from "../Breadcrumb";
import UserSelect from "../UserSelect";
import { StyledRight, StyledWrap } from "./style";

interface IProps {
  /**
   * Pass previous page's text and link
   */
  prev: Array<{
    text: string;
    link: string;
  }>;
  /**
   * Write page's title
   */
  title: string;
  /**
   * Write page's welcome text
   */
  wcText?: string;
  isInternalUser: boolean | undefined;
  isMasterUser: boolean | undefined;
  companyUsers: User[];
  onUserChange: (event: React.ChangeEvent) => any;
  activeUser: User["Id"] | null;
}

const PageHeader: FC<IProps> = ({
  prev,
  title,
  wcText,
  isInternalUser,
  isMasterUser,
  companyUsers,
  activeUser,
  onUserChange,
}) => {
  return (
    <StyledWrap>
      <div>
        <Breadcrumb prev={prev} title={title} wcText={wcText} />
      </div>
      <StyledRight>
        {(Boolean(isInternalUser) || Boolean(isMasterUser)) && (
          <UserSelect
            value={activeUser}
            users={companyUsers}
            onChange={onUserChange}
          />
        )}
      </StyledRight>
    </StyledWrap>
  );
};

export default PageHeader;
