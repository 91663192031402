import {
  ChangeEvent,
  DragEvent,
  FC,
  useCallback,
  useRef,
  useState,
} from "react";
import { X } from "react-feather";
import * as Yup from "yup";
import {
  StyledHeader,
  StyledTitle,
  StyledBody,
  StyledDropzone,
  StyledFooterToolbar,
  StyledEmptyStateImageWrapper,
  StyledEmptyStateImg,
  StyledEmptyStateContainer,
} from "./style";
import { Modal } from "../../Common/Modal";
import { Nav, NavLink } from "../../Common/Nav/nav";
import { Col, Row } from "../../Common/Grid";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Button } from "../../Common/Button";
import UploadImage from "../../../assets/images/upload.png";

interface IProps {
  show: boolean;
  onClose: () => any;
  onSubmit: (newMessage: any) => any;
}

const ChangePictureModal: FC<IProps> = ({ show, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [hightlight, setHightLight] = useState(false);

  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Picture: null,
    },
    validationSchema: Yup.object({
      Picture: Yup.mixed().required("File is required"),
    }),
    onSubmit,
  });

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      const file = files[0];

      formik.setFieldValue("Picture", file);
      setImagePreview(URL.createObjectURL(file));
      setHightLight(false);
    },
    [formik]
  );

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHightLight(true);
  };

  const onDragLeave = () => {
    setHightLight(false);
  };

  const onDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      // e.dataTransfer.files is a FileList (object)
      handleUploadFiles(Array.from(e.dataTransfer.files));
    },
    [handleUploadFiles]
  );

  const onSelectedFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleUploadFiles(Array.from(event.currentTarget.files || []));
    },
    [handleUploadFiles]
  );

  const handleOnClose = useCallback(
    (e?: any) => {
      e && e.preventDefault();
      onClose();
      // Clean component state
      setImagePreview(null);
    },
    [onClose]
  );

  return (
    <Modal
      show={show}
      onClose={handleOnClose}
      size="sm"
      closeOnClickBackdrop={false}
    >
      <StyledHeader backgroundColor={"#002E6B"}>
        <StyledTitle>
          {t("components.profile.changeProfilePicture")}
        </StyledTitle>
        <Nav customStyle="icon">
          <NavLink path="#!" onClick={handleOnClose}>
            <X />
          </NavLink>
        </Nav>
      </StyledHeader>
      <StyledBody>
        <form onSubmit={formik.handleSubmit}>
          <div onDragOver={onDragOver}>
            <StyledDropzone
              hightlight={hightlight}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            />

            <Row>
              <input
                ref={fileInputRef}
                id="file"
                name="file"
                type="file"
                onChange={onSelectedFile}
                style={{ display: "none" }}
              />
              <Col marginTop={10} textAlign="center">
                {imagePreview ? (
                  <img src={imagePreview} />
                ) : (
                  <EmptyState
                    onUploadClick={() => fileInputRef.current?.click()}
                  />
                )}
              </Col>
            </Row>
          </div>
          <Row>
            <StyledFooterToolbar marginTop={10} color={"#002E6B"}>
              <Button type="submit" className="sendBtt">
                {t("components.profile.upload")}
              </Button>
            </StyledFooterToolbar>
          </Row>
        </form>
      </StyledBody>
    </Modal>
  );
};

export default ChangePictureModal;

type EmptyStateProps = {
  onUploadClick: () => any;
};

const EmptyState: React.FC<EmptyStateProps> = ({ onUploadClick }) => {
  const { t } = useTranslation();
  return (
    <StyledEmptyStateContainer>
      <StyledEmptyStateImageWrapper>
        <StyledEmptyStateImg src={UploadImage} alt="upload" />
        <div>{t("components.profile.dndMessage")}</div>
      </StyledEmptyStateImageWrapper>
      <p style={{ marginTop: "0.5rem" }}>{t("components.profile.or")}</p>
      <Button iconButton variant="texted" onClick={onUploadClick}>
        {t("components.profile.uploadPicture")}
      </Button>
    </StyledEmptyStateContainer>
  );
};
