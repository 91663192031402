import axios, { AxiosRequestHeaders } from "axios";
import env from "../config/env";
import {
  AccessToken,
  Application,
  Attachment,
  AttachmentActivity,
  BIMonthlyData,
  BIYearlyData,
  BusinessChart,
  Communication,
  CommunicationField,
  CommunicationsResponse,
  Company,
  CompanyConfig,
  ComposeMessage,
  DataAnalysis,
  ExternalLink,
  Field,
  Message,
  MessagesBulkActionPayload,
  MessagesResponse,
  Notification,
  NotificationField,
  NotificationsResponse,
  SetCompanyConfig,
  SharepointCCN,
  SharepointField,
  SharepointRecipient,
  SupervisionedUser,
  UpdateSharepointSupervisedUsers,
  User,
  UserAccessConfiguration,
  UserCompanyAbilities,
  UserCompanyDistributionLists,
  UserProfileConfig,
} from "../types";
import storageServices from "./storage";
import {
  toApplication,
  toAttachment,
  toAttachmentActivity,
  toBIMonthlyData,
  toBIYearlyData,
  toBusinessChart,
  toCommunication,
  toCommunicationField,
  toCommunicationsResponse,
  toCompany,
  toCompanyConfig,
  toDataAnalysis,
  toExternalLinks,
  toField,
  toMessage,
  toMessagesResponse,
  toNotification,
  toNotificationField,
  toNotificationsResponse,
  toSharepointCCN,
  toSharepointField,
  toSharepointRecipient,
  toSupervisionedUser,
  toUser,
  toUserAccessConfiguration,
  toUserCompanyAbilities,
  toUserCompanyDistributionLists,
} from "./utils";

const axiosClient = axios.create();
axiosClient.defaults.baseURL = env.BACKEND_API_URL;
axiosClient.defaults.timeout = 100000;

const axiosSSOClient = axios.create({
  baseURL: env.SSO_BACKEND_API_URL,
  timeout: 2000,
});

const getHeaders = (): AxiosRequestHeaders => {
  const accessToken: any = storageServices.getItem("accessToken");

  const headers: AxiosRequestHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken.accessToken}`;
  }

  return headers;
};

axiosClient.interceptors.request.use(function (request) {
  request.headers = getHeaders();
  return request;
});

// Warning: Is the same of axiosSSOClient! Handling error 401
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // From axios github readme:
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const payload = error.response.data;
      console.log(payload);
      if (payload.redirect_to) {
        const currentLocation = window.location.href;
        const baseLocation = currentLocation.split("?")[0]; // Remove query params
        window.location.href = `${payload.redirect_to}?redirectTo=${baseLocation}`;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }

    throw error;
  }
);

axiosSSOClient.interceptors.request.use(function (request) {
  request.headers = getHeaders();
  return request;
});

// https://gist.github.com/saqueib/a495af17d7c0e2fd5c2316b0822ebac3#gistcomment-3448479
axiosSSOClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // From axios github readme:
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const payload = error.response.data;
      console.log(payload);
      if (payload.redirect_to) {
        const currentLocation = window.location.href;
        const baseLocation = currentLocation.split("?")[0]; // Remove query params
        window.location.href = `${payload.redirect_to}?redirectTo=${baseLocation}`;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }

    throw error;
  }
);

const getCurrentUser = async (): Promise<User | null> => {
  // const sessionData: any = storageServices.getItem("accessToken");

  //if (!sessionData) return null;
  //setSession(sessionData["token"]);
  const result = await axiosSSOClient.get(`auth/basic/me`, {
    headers: getHeaders(),
  });

  return toUser(result.data);
};

const refreshAccessToken = async (): Promise<AccessToken> => {
  const result = await axiosSSOClient.get(`auth/basic/refresh-token`);

  return result.data as AccessToken;
};

const destroySession = () => {
  storageServices.removeItem("accessToken");
};

const fetchUserCompanies = async (userId: User["Id"]): Promise<Company[]> => {
  const result = await axiosClient.get(`users/${userId}/companies`, {
    headers: getHeaders(),
  });

  return result.data.map((item: any) => toCompany(item));
};

const fetchCommunicationsFields = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<CommunicationField[]> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/communication-fields`,
    {
      headers: getHeaders(),
    }
  );

  return result.data.map((item: any) => toCommunicationField(item));
};

const fetchCommunications = async (
  userId: User["Id"],
  companyId: Company["Id"],
  cursor: number,
  limit: number,
  onlyUnreaded?: boolean
): Promise<CommunicationsResponse> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/communications?cursor=${cursor}&limit=${limit}&onlyUnreaded=${onlyUnreaded}`,
    {
      headers: getHeaders(),
    }
  );

  return toCommunicationsResponse(result.data);
};

const fetchCommunication = async (
  userId: User["Id"],
  companyId: Company["Id"],
  communicationId: Communication["Id"]
): Promise<Communication | null> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/communications/${communicationId}`,
    {
      headers: getHeaders(),
    }
  );

  return result.data ? toCommunication(result.data) : null;
};

const downloadCommunicationsAttachments = async (
  userId: User["Id"],
  companyId: Company["Id"],
  communicationId: Communication["Id"]
): Promise<Blob> => {
  const response = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/communications/${communicationId}/attachments`,
    {
      responseType: "blob",
      timeout: 60000,
    }
  );

  return response.data;
};

const trackCommunicationActivity = async (
  userId: User["Id"],
  companyId: Company["Id"],
  communicationId: Communication["Id"]
): Promise<void> => {
  await axiosClient.post(
    `/users/${userId}/companies/${companyId}/communications/${communicationId}/track`,
    {},
    {
      headers: getHeaders(),
    }
  );
};

const fetchNotificationsFields = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<NotificationField[]> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/notification-fields`,
    {
      headers: getHeaders(),
    }
  );

  return result.data.map((item: any) => toNotificationField(item));
};

const fetchNotifications = async (
  userId: User["Id"],
  companyId: Company["Id"],
  cursor: number,
  limit: number,
  onlyUnreaded?: boolean
): Promise<NotificationsResponse> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/notifications?cursor=${cursor}&limit=${limit}&onlyUnreaded=${onlyUnreaded}`,
    {
      headers: getHeaders(),
    }
  );

  return toNotificationsResponse(result.data);
};

const fetchNotification = async (
  userId: User["Id"],
  companyId: Company["Id"],
  notificationId: Notification["Id"]
): Promise<Notification | null> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/notifications/${notificationId}`,
    {
      headers: getHeaders(),
    }
  );

  return result.data ? toNotification(result.data) : null;
};

const trackNotificationActivity = async (
  userId: User["Id"],
  companyId: Company["Id"],
  notificationId: Notification["Id"]
): Promise<void> => {
  await axiosClient.post(
    `/users/${userId}/companies/${companyId}/notifications/${notificationId}/track`,
    {},
    {
      headers: getHeaders(),
    }
  );
};

const fetchApplications = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<Application[]> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/applications`,
    {
      headers: getHeaders(),
    }
  );

  return result.data.map((item: any) => toApplication(item));
};

const fetchFields = async (): Promise<Field[]> => {
  const result = await axiosClient.get(`/fields`, {
    headers: getHeaders(),
  });

  return result.data.map((item: any) => toField(item));
};

const fetchSharepointFields = async (
  userId: User["Id"],
  companyId: Company["Id"],
  filter?: string
): Promise<SharepointField[]> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/sharepoint-fields?${
      filter ? `filter=${filter}` : ""
    }`
  );

  return result.data.map(toSharepointField);
};

const fetchSharepointRecipients = async (
  companyId: Company["Id"]
): Promise<SharepointRecipient[]> => {
  const result = await axiosClient.get(
    `/companies/${companyId}/sharepoint-recipients`,
    {
      headers: getHeaders(),
    }
  );

  return result.data.map((item: any) => toSharepointRecipient(item));
};

const fetchSharepointSupervisionedUsers = async (
  companyId: Company["Id"]
): Promise<SupervisionedUser[]> => {
  const result = await axiosClient.get(
    `/companies/${companyId}/sharepoint-supervised-users`
  );

  return result.data.map(toSupervisionedUser);
};

const updateSharepointSupervisionedUsers = async (
  companyId: Company["Id"],
  payload: UpdateSharepointSupervisedUsers
): Promise<SupervisionedUser | null> => {
  const result = await axiosClient.patch(
    `/companies/${companyId}/sharepoint-supervised-users`,
    payload
  );

  return result.data ? toSupervisionedUser(result.data) : null;
};

const fetchSharepointCCN = async (
  companyId: Company["Id"]
): Promise<SharepointCCN[]> => {
  const result = await axiosClient.get(
    `/companies/${companyId}/sharepoint-ccn`
  );

  return result.data.map((item: any) => toSharepointCCN(item));
};

const fetchInboxMessages = async (
  userId: User["Id"],
  companyId: Company["Id"],
  cursor: number,
  limit: number,
  onlyUnreaded?: boolean,
  query?: string,
  filter?: string
): Promise<MessagesResponse> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/inbox-messages?cursor=${cursor}&limit=${limit}&onlyUnreaded=${onlyUnreaded}${
      query ? `&search=${query}` : ""
    }${filter ? `&filter=${filter}` : ""}`
  );

  return toMessagesResponse(result.data);
};

const fetchInboxMessage = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"],
  supervisorUserId?: User["Id"]
): Promise<Message> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/inbox-messages/${messageId}${
      supervisorUserId ? `?supervisorUserId=${supervisorUserId}` : ""
    }`
  );

  return toMessage(result.data);
};

const downloadInboxMessageAttachments = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"]
): Promise<Blob> => {
  const response = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/messages/${messageId}/attachments`,
    {
      responseType: "blob",
      timeout: 60000,
    }
  );

  return response.data;
};

const fetchSentMessages = async (
  userId: User["Id"],
  companyId: Company["Id"],
  cursor: number,
  limit: number,
  query?: string
): Promise<MessagesResponse> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/messages?cursor=${cursor}&limit=${limit}${
      query && `&search=${query}`
    }`
  );

  return toMessagesResponse(result.data);
};

const fetchSentMessage = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"]
): Promise<Message> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/messages/${messageId}`
  );

  return toMessage(result.data);
};

const deleteMessage = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"]
): Promise<boolean> => {
  await axiosClient.delete(
    `/users/${userId}/companies/${companyId}/messages/${messageId}`
  );

  return true;
};

const sendMessage = async (
  userId: User["Id"],
  companyId: Company["Id"],
  message: ComposeMessage
): Promise<Message> => {
  const result = await axiosClient.post(
    `/users/${userId}/companies/${companyId}/messages`,
    message
  );

  return toMessage(result.data);
};

const updateMessage = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"],
  message: ComposeMessage
): Promise<Message> => {
  const result = await axiosClient.patch(
    `/users/${userId}/companies/${companyId}/messages/${messageId}`,
    message
  );

  return toMessage(result.data);
};

const uploadAttachment = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"],
  file: File,
  onUploadProgress: (uploadProgress: ProgressEvent, file: File) => any
): Promise<Attachment> => {
  const data = new FormData();
  data.append("file", file);

  const handleUploadProgress = (uploadProgress: ProgressEvent) => {
    onUploadProgress(uploadProgress, file);
  };

  const result = await axiosClient.post(
    `/users/${userId}/companies/${companyId}/messages/${messageId}/attachments`,
    data,
    { onUploadProgress: handleUploadProgress, timeout: 3600000 } // timeout 1h
  );

  return toAttachment(result.data);
};

const deleteAttachment = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"],
  attachmentId: Attachment["Id"]
): Promise<boolean> => {
  await axiosClient.delete(
    `/users/${userId}/companies/${companyId}/messages/${messageId}/attachments/${attachmentId}`
  );

  return true;
};

const trackMessageActivity = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"]
): Promise<void> => {
  await axiosClient.post(
    `/users/${userId}/companies/${companyId}/inbox-messages/${messageId}/track`,
    {}
  );
};

const trackAttachmentsActivity = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"],
  attachmentIds: Attachment["Id"][]
): Promise<void> => {
  await axiosClient.post(
    `/users/${userId}/companies/${companyId}/inbox-messages/${messageId}/track-attachments`,
    {
      AttachmentIds: attachmentIds,
    }
  );
};

const fetchCompanyUsers = async (
  companyId: Company["Id"],
  internal?: number
): Promise<User[]> => {
  const result = await axiosClient.get(
    `/companies/${companyId}/users${internal ? "?internal=1" : ""}`
  );

  return result.data.map((item: any) => toUser(item));
};

const performMessagesBulkAction = async (
  userId: User["Id"],
  companyId: Company["Id"],
  action: MessagesBulkActionPayload
) => {
  await axiosClient.post(
    `/users/${userId}/companies/${companyId}/bulk-action`,
    action
  );
};

const fetchUserAbilities = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<UserCompanyAbilities> => {
  const response = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/abilities`
  );

  return toUserCompanyAbilities(response.data);
};

const setFavoriteCompany = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<boolean> => {
  await axiosClient.post(`/users/${userId}/company-config/set-favorite`, {
    companyId,
  });

  return true;
};

const fetchUserCompanyConfigs = async (
  userId: User["Id"]
): Promise<CompanyConfig[]> => {
  const response = await axiosClient.get(`/users/${userId}/company-config`);

  return response.data.map((item: any) => toCompanyConfig(item));
};

const updateCompanyConfig = async (
  userId: User["Id"],
  data: SetCompanyConfig
): Promise<boolean> => {
  const payload = {
    Email: data.Email,
    MobileNumber: data.MobileNumber,
  };
  await axiosClient.patch(
    `/users/${userId}/company-config/${data.Id}`,
    payload
  );

  return true;
};

const updateUserProfile = async (
  profileData: UserProfileConfig
): Promise<boolean> => {
  const payload = {
    Id: profileData.Id,
    Alias:
      profileData.Alias && profileData.Alias.length > 0
        ? profileData.Alias
        : null,
    PhoneNumber:
      profileData.PhoneNumber && profileData.PhoneNumber.length > 0
        ? profileData.PhoneNumber
        : null,
    MobileNumber:
      profileData.MobileNumber && profileData.MobileNumber.length > 0
        ? profileData.MobileNumber
        : null,
    OtpChannel: profileData.OtpChannel,
  };
  await axiosClient.patch(`/users/${profileData.Id}/profile`, payload);

  return true;
};

const uploadPicture = async (
  userId: User["Id"],
  file: File
): Promise<User["Picture"]> => {
  const data = new FormData();
  data.append("file", file);

  const response = await axiosClient.post(`users/${userId}/picture`, data);

  return response.data;
};

const fetchAttachmentActivities = async (
  userId: User["Id"],
  companyId: Company["Id"],
  messageId: Message["Id"],
  attachmentId: Attachment["Id"]
): Promise<AttachmentActivity[]> => {
  const response = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/messages/${messageId}/attachments/${attachmentId}/activities`
  );

  return response.data.map(toAttachmentActivity);
};

const fetchDataAnalysis = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<DataAnalysis[]> => {
  const response = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/bi`
  );

  return response.data.map(toDataAnalysis);
};

const fetchDataAnalysisFields = async (
  userId: User["Id"],
  companyId: Company["Id"],
  dataAnalysisId: DataAnalysis["Id"]
): Promise<Field[]> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/bi/${dataAnalysisId}/fields`
  );

  return result.data.map((item: any) => toField(item));
};

const fetchBusinessCharts = async (
  userId: User["Id"],
  companyId: Company["Id"],
  dataAnalysisId: DataAnalysis["Id"]
): Promise<BusinessChart[]> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/bi/${dataAnalysisId}/charts`
  );

  return result.data.map((item: any) => toBusinessChart(item));
};

const fetchBiMonthlyData = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<BIMonthlyData[]> => {
  const response = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/bi/monthly-data`
  );

  return response.data.map(toBIMonthlyData);
};

const fetchBiYearlyData = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<BIYearlyData[]> => {
  const response = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/bi/yearly-data`
  );

  return response.data.map(toBIYearlyData);
};

const fetchExternalLinks = async (): Promise<ExternalLink[]> => {
  const result = await axiosClient.get(`/external-links`, {
    headers: getHeaders(),
  });

  return result.data.map(toExternalLinks);
};

const fetchUserAccessConfiguration = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<UserAccessConfiguration> => {
  const result = await axiosClient.get(
    `/users/${userId}/companies/${companyId}/access-configuration`
  );

  return toUserAccessConfiguration(result.data);
};

const confirmUserAccessConfiguration = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<boolean> => {
  await axiosClient.post(
    `/users/${userId}/companies/${companyId}/access-configuration/confirm`
  );

  return true;
};

const revokeUserAccessConfiguration = async (
  userId: User["Id"],
  companyId: Company["Id"]
): Promise<boolean> => {
  await axiosClient.post(
    `/users/${userId}/companies/${companyId}/access-configuration/revoke`
  );

  return true;
};

const fetchUserCompanyDistributionLists = async (
  companyId: Company["Id"]
): Promise<UserCompanyDistributionLists[]> => {
  const result = await axiosClient.get(
    `/companies/${companyId}/user-company-distribution-lists`
  );

  return result.data.map(toUserCompanyDistributionLists);
};

const api = {
  getCurrentUser,
  destroySession,
  refreshAccessToken,
  fetchUserCompanies,
  fetchCommunicationsFields,
  fetchCommunications,
  fetchCommunication,
  downloadCommunicationsAttachments,
  trackCommunicationActivity,
  fetchNotificationsFields,
  fetchNotifications,
  fetchNotification,
  trackNotificationActivity,
  fetchApplications,
  fetchFields,
  fetchSharepointFields,
  fetchSharepointRecipients,
  fetchSharepointSupervisionedUsers,
  updateSharepointSupervisionedUsers,
  fetchSharepointCCN,
  fetchInboxMessages,
  downloadInboxMessageAttachments,
  fetchInboxMessage,
  fetchSentMessages,
  fetchSentMessage,
  deleteMessage,
  sendMessage,
  updateMessage,
  uploadAttachment,
  deleteAttachment,
  trackMessageActivity,
  trackAttachmentsActivity,
  fetchCompanyUsers,
  performMessagesBulkAction,
  fetchUserAbilities,
  setFavoriteCompany,
  fetchUserCompanyConfigs,
  updateCompanyConfig,
  updateUserProfile,
  uploadPicture,
  fetchAttachmentActivities,
  fetchDataAnalysis,
  fetchDataAnalysisFields,
  fetchBusinessCharts,
  fetchBiMonthlyData,
  fetchBiYearlyData,
  fetchExternalLinks,
  fetchUserAccessConfiguration,
  confirmUserAccessConfiguration,
  revokeUserAccessConfiguration,
  fetchUserCompanyDistributionLists,
};

export default api;
