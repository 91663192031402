import React from "react";
import RSelect, { Props, components } from "react-select";

export const Option = components.Option;
export const SingleValue = components.SingleValue;

const MenuList = (props: any) => {
  const { MenuListHeader = null, MenuListFooter = null } =
    props.selectProps.components;

  return (
    <components.MenuList {...props}>
      {MenuListHeader}
      {props.children}
      {MenuListFooter}
    </components.MenuList>
  );
};

// const MenuListFooter = <div>Showing partial results</div>;

type CustomSelectProps = Props & {
  MenuListFooter?: JSX.Element;
};

export const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      width: "100%",
      border: "0px",
      padding: "0px",
      margin: "0px",
    }),
    input: (styles: any) => ({
      ...styles,
      margin: "0px",
      padding: "0px",
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  return (
    <RSelect
      styles={selectStyles}
      // @ts-ignore
      components={{ MenuList, MenuListFooter: props.MenuListFooter }}
      {...props}
    />
  );
};
