import { FC } from "react";
import { Settings } from "react-feather";
import { ScreenConfig, SharepointField, User } from "../../../types";
import UserSelect from "../../Applications/UserSelect";
import { Nav, NavLink } from "../../Common/Nav/nav";
import Scrollbar from "../../Common/Scrollbar";
import ComposeBtn from "../ComposeBtn";
import MainNav from "../MainNav";
import {
  StyledSidebar,
  StyledBody,
  StyledActionGroup,
  StyledComposeBttWrapper,
} from "./style";

type Props = {
  fields: SharepointField[];
  activeField: string | null;
  config: ScreenConfig;
  numDraftMessages: number;
  isSubmitting: boolean;
  onComposeClick: () => void;
  isInternalUser: boolean | undefined;
  companyUsers: User[];
  onUserChange: (event: React.ChangeEvent) => any;
  activeUserId: User["Id"] | null;
  fileStorageDays: number;
  onSettingsClick: () => void;
};

const Sidebar: FC<Props> = ({
  fields,
  activeField,
  config,
  numDraftMessages,
  isSubmitting,
  onComposeClick,
  isInternalUser,
  companyUsers,
  onUserChange,
  activeUserId,
  fileStorageDays,
  onSettingsClick,
}) => {
  return (
    <StyledSidebar className="sidebar">
      <Scrollbar top="0px">
        <StyledBody>
          {isInternalUser && (
            <div
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <UserSelect
                value={activeUserId}
                users={companyUsers}
                onChange={onUserChange}
              />
            </div>
          )}
          <StyledActionGroup>
            <StyledComposeBttWrapper>
              <ComposeBtn
                onClick={onComposeClick}
                backgroundColor={config.theme.primaryColor}
                isSubmitting={isSubmitting}
              />
            </StyledComposeBttWrapper>
            {isInternalUser && companyUsers.length > 0 && (
              <Nav customStyle="icon" paddingRight={"0.5rem"}>
                <NavLink
                  path="#"
                  onClick={(e: any) => {
                    e.preventDefault();
                    onSettingsClick();
                  }}
                >
                  <Settings />
                </NavLink>
              </Nav>
            )}
          </StyledActionGroup>
          <MainNav
            fields={fields}
            activeField={activeField}
            numDraftMessages={numDraftMessages}
            fileStorageDays={fileStorageDays}
          />
        </StyledBody>
      </Scrollbar>
    </StyledSidebar>
  );
};

export default Sidebar;
