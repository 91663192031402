import { observer } from "mobx-react";
import { useMemo } from "react";
import { NotificationsWidget } from "../../components/Home/NotificationsWidget";
import { useUserCompanyAbilities } from "../../hooks/abilties.hook";
import { useUserUnreadedNotification } from "../../hooks/notifications.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Notification, Screens } from "../../types";

const WrapperNotificationsWidget: React.FC = observer(() => {
  const { session } = useStores();
  const config = useScreenConfig(Screens.NOTIFICATIONS);
  const { data } = useUserUnreadedNotification(
    session.user?.Id || -1,
    session.activeCompanyId || -1
  );
  const { data: abilties } = useUserCompanyAbilities();

  const unreadedNotifications = useMemo(
    () => (data?.data || []).slice(0, 3) as Notification[],
    [data]
  );

  return (
    <NotificationsWidget
      notifications={unreadedNotifications}
      companyId={session.activeCompanyId || -1}
      config={config}
      enabled={abilties?.notifications}
    />
  );
});

export default WrapperNotificationsWidget;
