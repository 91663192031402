import { FC } from "react";
import { TypographyProps } from "../../../styled";
import { StyledTitle, StyledDesc } from "./style";

interface IProps {
  title: string;
  desc?: string;
  descProps?: TypographyProps;
  titleProps?: TypographyProps;
}

const SectionTitle: FC<IProps> = ({ title, desc, descProps, titleProps }) => {
  return (
    <>
      <StyledTitle $hasDesc={Boolean(desc)} {...titleProps}>
        {title}
      </StyledTitle>
      {desc && (
        <StyledDesc
          {...descProps}
          dangerouslySetInnerHTML={{
            __html: desc.replaceAll("\\r", "\r").replaceAll("\\n", "<br/>"),
          }}
        />
      )}
    </>
  );
};

export default SectionTitle;
