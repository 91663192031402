import { SessionStore } from "./stores/Session";
import { FC, SVGAttributes } from "react";
import { UIStore } from "./stores/UI";
import { Icon } from "react-feather";
import { SharepointStore } from "./stores/Sharepoint";

/** DOAR */

export interface IconProps extends SVGAttributes<SVGElement> {
  color?: string;
  size?: string | number;
}

export interface ISubmenu {
  id: number;
  label: string;
  url: string;
  Icon?: FC<IconProps> | undefined;
}

export interface IMegamenu {
  id: number;
  title: string;
  submenu: ISubmenu[];
}

export interface IMenu {
  id: number;
  label: string;
  url: string;
  Icon?: FC<IconProps> | undefined;
  submenu?: ISubmenu[];
  megamenu?: IMegamenu[];
}

export interface IConversion {
  id: number;
  title: string;
  rate: string;
  change: {
    percentage: string;
    growth: "up" | "down";
    time: string;
    delta?: string;
  };
}

/** ./ DOAR */

export enum Screens {
  HOME = "home",
  COMMUNICATIONS = "communications",
  NOTIFICATIONS = "notifications",
  APPLICATIONS = "applications",
  PROFILE = "profile",
  SHAREPOINT = "sharepoint",
  BASIC_BUSINESS_DATA = "basic-business-data",
  ACCESS_CONFIGURATION = "access-configuration",
}

export enum NotificationFilters {
  ALL = "all",
  UNREADED = "unreaded",
}

export enum CommunicationFilters {
  ALL = "all",
  UNREADED = "unreaded",
}

export enum SharepointFilters {
  ALL = "all",
  UNREADED = "unreaded",
  // Supervisor filters
  SUPERVISOR_UNREADED = "supervisor_unreaded",
  SUPERVISED_UNREADED = "supervised_unreaded",
  ALL_UNREADED = "all_unreaded",
  READED_MIXES = "readed_mixed",
}

export enum MessagesBulkAction {
  MARK_UNREADED = "mark-unreaded",
  MARK_READED = "mark-readed",
  MARK_AS_HIDDEN = "mark-as-hidden",
  MARK_AS_SENT = "mark-as-sent",
}

export enum BusinessCharts {
  COST_CONVERSION = "COST_CONVERSION",
  MONTHLY_COST = "MONTHLY_COST",
  MONTHLY_WORKING_HOURS = "MONTHLY_WORKING_HOURS",
  TOTAL_EMPLOYEES = "TOTAL_EMPLOYEES",
  TOTAL_FUND_DISTRIBUTION = "TOTAL_FUND_DISTRIBUTION",
}

export type SSOJwtPayload = {
  alias: string;
  email: string;
  exp?: number;
  firstname: string;
  iat: number;
  lastname: string;
  sub: number;
};

export type AccessToken = {
  accessToken: string;
};

export type StorageKey = "accessToken";

export type Stores = {
  session: SessionStore;
  ui: UIStore;
  sharepoint: SharepointStore;
};

export type User = {
  Id: number;
  IsActive: boolean;
  Firstname: string;
  Lastname: string;
  LoginAlias: string;
  Email: string;
  Picture: string | null;
  DaysUntilPasswordExpirationDate: number;
  ExpirationDate: Date;
  Internal: boolean;
  Field?: Field | null;
  PhoneNumber?: string;
  MobileNumber?: string;
  Confirmed?: boolean;
  OtpChannel?: number;
  FiscalCode?: string;
  IsMasterUser?: boolean;
};

export type Company = {
  Id: number;
  BusinessName: string;
  Logo: string | null;
  FiscalCode: string;
  VatNumber: string;
  ClientType: string | null;
  Favorite?: boolean;
  IsMasterUser?: boolean;
  FileStorageDays: number;
  UnreadedMessages: number;
  UserRoleDefined?: boolean;
};

export type CommunicationField = {
  Id: number;
  Description: string;
  TotalUnreaded: number;
  CommunicationId: number;
  CommunicationDescription: string;
};

export type NotificationField = {
  Id: number;
  Description: string;
  TotalUnreaded: number;
};

export type Application = {
  Id: number;
  Description: string;
  ExtendedDescription: string;
  Url: string;
  Enabled: boolean;
  Color: string | null;
  Logo: string | null;
};

export type Field = {
  Id: number;
  Description: string;
};

export type SharepointField = Field & {
  TotalUnreaded: number;
};

export type SharepointRecipient = Pick<
  User,
  "Id" | "Firstname" | "Lastname" | "Email"
> & {
  Field?: Field;
  DefaultRecipient?: boolean;
};

export type Attachment = {
  Id: number;
  FileName: string;
  MimeType: string;
  OriginalFileName: string;
  Size: number;
  FileURL?: string;
  MessageId: number;
  Downloaded?: boolean;
};

export type Message = {
  Id: number;
  Subject: string;
  Text: string;
  Author: SharepointRecipient;
  Field: Field;
  CreatedAt: Date;
  Attachments?: Attachment[];
  Unreaded?: boolean;
  Status?: string;
  Recipients?: SharepointRecipient[];
  SentAt: Date | null;
  CCN?: SharepointCCN[];
  IsVisibile: boolean;
};

export type ComposeMessage = Pick<
  Message,
  "Subject" | "Text" | "Attachments" | "Status"
> & {
  Id?: Message["Id"];
  Recipients?: number[];
  FieldId?: number;
  CCN?: number[];
};

export type MessagesResponse = {
  data: Message[];
  nextCursor: number;
};

export type Notification = {
  Id: number;
  Subject: string;
  Text: string;
  Field: Field;
  CreatedAt: Date;
  Unreaded?: boolean;
};

export type NotificationsResponse = {
  data: Notification[];
  nextCursor: number;
};

export type Communication = {
  Id: number;
  Subject: string;
  Text: string;
  Field: Field;
  CreatedAt: Date;
  Unreaded?: boolean;
  Attachments?: Attachment[];
  CommunicationId: number;
  CommunicationDescription: string;
};

export type CommunicationsResponse = {
  data: Communication[];
  nextCursor: number;
};

export type ScreenConfig = {
  Icon: Icon;
  theme: {
    primaryColor: string;
  };
};

export type MessagesBulkActionPayload = {
  action: MessagesBulkAction;
  messageIds: number[];
};

export type UserCompanyAbilities = {
  applications: boolean;
  communications: boolean;
  notifications: boolean;
  dataAnalysis: boolean;
};

export type SharepointCCN = {
  RoleId: number;
  Description: string;
};

export type CompanyConfig = {
  Id: Company["Id"];
  BusinessName: Company["BusinessName"];
  Email: User["Email"];
  Favorite: boolean;
  MobileNumber: string | null;
};

export type SetCompanyConfig = Pick<
  CompanyConfig,
  "Id" | "Email" | "MobileNumber"
>;

export type UserProfileConfig = {
  Id: User["Id"];
  Alias: User["LoginAlias"];
  PhoneNumber: User["PhoneNumber"];
  MobileNumber: User["MobileNumber"];
  OtpChannel: number;
};

export type AttachmentActivity = Pick<
  User,
  "Id" | "Lastname" | "Firstname" | "Picture"
> & {
  AttachmentId: Attachment["Id"];
  ActivityDate: Date;
};

export type DataAnalysis = {
  Id: number;
  Description: string;
  ExtendedDescription: string;
  Logo: string | null;
};

export type BusinessChart = {
  Id: number;
  Name: string;
  Field: Field;
  Title: string;
};

export type BIMonthlyData = {
  Anno: number;
  Mese: number;
  DescrizioneMese: string;
  Costo: number;
  FondoFeriePermessiBancaOre: number;
  OreLavorate: number;
};

export type BIYearlyData = {
  Anno: number;
  TotMaschi: number;
  TotFemmine: number;
  CostoTotale: number;
  CostoTotaleAnnoPrec: number;
  DeltaCostoTotale: number;
  DeltaCostoTotalePercent: number;
};

export type ExternalLink = {
  Name: string;
  Description: string;
};

type UserIsEnabled = {
  UserIsEnabled: boolean;
};

export type ApplicationAccessConfiguration = Pick<
  Application,
  "Id" | "Description"
> &
  UserIsEnabled;

export type CommunicationAccessConfiguration = Field & UserIsEnabled;

export type NotificationAccessConfiguration = Field & UserIsEnabled;

export type DataAnalysisAccessConfiguration = DataAnalysis &
  UserIsEnabled & { Field: Field };

export type UserAccessConfiguration = {
  User: User;
  Applications: ApplicationAccessConfiguration[];
  Communications: CommunicationAccessConfiguration[];
  Notifications: NotificationAccessConfiguration[];
  DataAnalysis: DataAnalysisAccessConfiguration[];
};

export type SectionAbility = {
  key: number;
  title: string;
  isEnabled: boolean;
};

export type UserCompanyDistributionLists = {
  Id: number;
  Description: string;
  Recipients: SharepointRecipient[];
};

export type SupervisionedUser = User & {
  IsEmailEnabled: boolean;
  TotalSupervisedUnreaded: number;
  TotalSupervisorUnreaded: number;
  TotalUnreaded: number;
};

export type UpdateSharepointSupervisedUsers = {
  SupervisedId: SharepointRecipient["Id"];
  IsEmailEnabled: boolean;
};
