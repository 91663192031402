import { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import Layout from "../../components/Layout";
import { menus } from "../../config/menu";
import { useStores } from "../../hooks/useStores";
import { Outlet } from "react-router";
import { useUserUnreadedNotification } from "../../hooks/notifications.hooks";
import { Company, Notification } from "../../types";
import { useUserCompanyAbilities } from "../../hooks/abilties.hook";
import { useExternalLinks } from "../../hooks/externalLinks.hooks";

export const AppLayout: React.FC = observer(({ children }) => {
  const { session, ui } = useStores();
  const { data: abilties } = useUserCompanyAbilities();
  const { data: externalLinks = [] } = useExternalLinks();

  const authMenu = useMemo(() => {
    return session.activeCompanyId && session.activeCompany
      ? menus
          .map((menu) => ({
            ...menu,
            url: menu.url.replace(
              ":companyId",
              session.activeCompanyId?.toString() || ""
            ),
          }))
          .filter((menu) => {
            if (!abilties) return false;

            if (menu.key === "communications") {
              return !!abilties.communications;
            } else if (menu.key === "notifications") {
              return !!abilties.notifications;
            } else if (menu.key === "notifications") {
              return !!abilties.applications;
            } else if (menu.key === "basic-business-data") {
              return !!abilties.dataAnalysis;
            } else if (menu.key === "access-configuration") {
              return (
                Boolean(session.activeCompany?.IsMasterUser) ||
                Boolean(session.user?.Internal)
              );
            }

            return true;
          })
      : [];
  }, [abilties, session.activeCompany, session.activeCompanyId, session.user]);

  const { data } = useUserUnreadedNotification(
    session.user?.Id || -1,
    session.activeCompanyId || -1
  );

  const handleSetFavoriteCompany = useCallback(
    async (companyId: Company["Id"]) => {
      await session.setFavoriteCompany(companyId);
    },
    [session]
  );

  return (
    <Layout
      user={session.user}
      companies={session.companies}
      activeCompany={session.activeCompany}
      sidebarLayout={1}
      hasSidebar
      menu={authMenu}
      activeScreen={ui.activeScreen}
      notificationsEnabled={abilties?.notifications}
      unreadedNotifications={(data?.data || []) as Notification[]}
      onSetFavoriteCompany={handleSetFavoriteCompany}
      externalLinks={externalLinks}
    >
      <Outlet />
    </Layout>
  );
});
