import { FC } from "react";
import { Field, User } from "../../../types";
import Breadcrumb from "../Breadcrumb";
import FilterForm from "../FilterForm";
import UserSelect from "../UserSelect";
import { StyledRight, StyledWrap } from "./style";

interface IProps {
  /**
   * Pass previous page's text and link
   */
  prev: Array<{
    text: string;
    link: string;
  }>;
  /**
   * Write page's title
   */
  title: string;
  /**
   * Write page's welcome text
   */
  wcText?: string;
  filterValue: string;
  fields: Field[];
  onFilterChange: (event: React.ChangeEvent) => any;
  isInternalUser: boolean | undefined;
  companyUsers: User[];
  onUserChange: (event: React.ChangeEvent) => any;
}

const PageHeader: FC<IProps> = ({
  prev,
  title,
  wcText,
  filterValue,
  fields,
  onFilterChange,
  isInternalUser,
  companyUsers,
  onUserChange,
}) => {
  return (
    <StyledWrap>
      <div>
        <Breadcrumb prev={prev} title={title} wcText={wcText} />
      </div>
      <StyledRight>
        {isInternalUser && (
          <UserSelect
            value={null}
            users={companyUsers}
            onChange={onUserChange}
          />
        )}
        <FilterForm
          value={filterValue}
          onChange={onFilterChange}
          options={fields}
        />
      </StyledRight>
    </StyledWrap>
  );
};

export default PageHeader;
