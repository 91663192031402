import { makeAutoObservable, observable } from "mobx";
import api from "../services/api";
import { messageToComposeMessage } from "../services/utils";
import {
  Attachment,
  Company,
  ComposeMessage,
  Message,
  SharepointFilters,
  Stores,
} from "../types";

export class SharepointStore {
  stores: Stores;

  isComposeModalOpen = false;

  defaultSubject: string = "";
  defaultRecipients: number[] = [];

  activeMessage: ComposeMessage | null = null;
  readingMessageId: Message["Id"] | null = null;

  filesQueue: File[] = [];
  uploadProgress = observable.map(new Map<string, number>());
  uploadErrors = observable.map(new Map<string, string>());
  newAttachments: Attachment[] = [];

  inboxFilter: SharepointFilters = SharepointFilters.UNREADED;

  readedMessages: Message["Id"][] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  openComposeModal = () => {
    this.isComposeModalOpen = true;
  };

  closeComposeModal = () => {
    this.isComposeModalOpen = false;
    this.defaultRecipients = [];
    this.defaultSubject = "";
    this.filesQueue = [];
    this.uploadProgress.clear();
    this.uploadErrors.clear();
    this.newAttachments = [];
  };

  setActiveMessage = (message: ComposeMessage | null) => {
    this.activeMessage = message;
  };

  setReadingMessageId = (id: Message["Id"] | null) => {
    this.readingMessageId = id;
  };

  replayToMessage = async (message: Message, companyId: Company["Id"]) => {
    const data = {
      Subject: message.Subject,
      Text: "",
      Recipients: [message.Author.Id],
    } as ComposeMessage;

    const newMessage = await api.sendMessage(
      this.stores.session.user?.Id || -1,
      companyId,
      data
    );
    this.activeMessage = messageToComposeMessage(newMessage);

    this.isComposeModalOpen = true;
  };

  addFileToQueue = (files: File[]) => {
    this.filesQueue = this.filesQueue.slice().concat(files);
  };

  onUploadProgress = (event: ProgressEvent, file: File) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    this.uploadProgress.set(file.name, percentCompleted);
  };

  removeFileQueue = (file: File) => {
    this.filesQueue = this.filesQueue.filter((f) => f.name !== file.name);
  };

  setUploadError = (file: File, message: string) => {
    this.uploadErrors.set(file.name, message);
  };

  trackAttachment = (attachment: Attachment) => {
    this.newAttachments.push(attachment);
  };

  setInboxFilter = (filter: SharepointFilters) => {
    this.inboxFilter = filter;
  };

  resetReadedMessages = () => {
    this.readedMessages = [];
  };

  addReadedMessage = (id: Message["Id"]) => {
    this.readedMessages = [...this.readedMessages, id];
  };
}
