import styled, { themeGet, css } from "../../../styled";
import { Media, MediaBody } from "../../UI/Media";

export const StyledMedia = styled(({ ...rest }) => <Media {...rest} />)`
  position: relative;
  // border: 1px solid ${themeGet("colors.border")};
  padding: 10px 12px;
  background-color: ${themeGet("colors.gray100")};
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  margin-bottom: 5px;
  & > svg {
    width: 1.2rem;
    height: 1.2rem;
    stroke-width: 1.5px;
    color: ${themeGet("colors.primary")};
    opacity: 0.7;
  }
  ${(props) =>
    props.theme.name === "dark" &&
    css`
      border-width: 0;
      background-color: ${themeGet("colors.gray900")};
    `}
`;

export const StyledMediaBody = styled(({ ...rest }) => <MediaBody {...rest} />)`
  margin-left: 10px;
  h6 {
    margin-bottom: 1px;
    cursor: pointer;
  }
  span {
    font-size: 12px;
    color: ${themeGet("colors.text3")};
    display: block;
  }
`;

export const StyledErrorMessage = styled.span`
  color: ${themeGet("colors.danger")} !important;
`;
