import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../services/api";
import { SetCompanyConfig } from "../types";
import { useActiveUser } from "./useActiveUser";

export const useUserCompanyConfigs = () => {
  const userId = useActiveUser();

  return useQuery(
    [`user-company-configs-${userId}`, userId],
    () => api.fetchUserCompanyConfigs(userId || -1),
    {
      enabled: !!userId,
    }
  );
};

export const useSetUserCompanyConfigs = () => {
  const queryClient = useQueryClient();
  const userId = useActiveUser();

  return useMutation(
    (data: SetCompanyConfig) => api.updateCompanyConfig(userId || -1, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`user-company-configs-${userId}`);
      },
    }
  );
};
