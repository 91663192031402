import styled, { themeGet, css } from "../../../styled";
import { Card } from "../../Common/Card";

export const StyledCard = styled(({ ...rest }) => <Card {...rest} />)`
  flex-grow: 1;
  ${(props) =>
    props.theme.name === "dark" &&
    css`
      background-color: ${themeGet("colors.gray900")};
    `}
`;
