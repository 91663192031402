import React, { useState } from "react";
import { ChevronDown, Star } from "react-feather";
import { Company } from "../../../../types";
import { Avatar } from "../../../Common/Avatar";
import { Badge } from "../../../Common/Badge";
import { Dropdown, DropdownToggle } from "../../../Common/Dropdown";
import { Icon } from "../../../Common/Icon";
import { Media, MediaLeft } from "../../../UI/Media";
import {
  StyledDropMenu,
  StyledDropItem,
  StyledMediaBody,
  StyledStrong,
} from "./style";

const CompanyItem: React.FC<{
  company: Company;
  color?: string;
  onSetFavorite: (companyId: Company["Id"]) => any;
}> = ({ company, color, onSetFavorite }) => {
  const [imageNotFound, setImageNotFound] = useState(false);
  return (
    <Media alignItems="center" paddingRight="1rem">
      <MediaLeft>
        <Avatar size="sm" shape="rounded">
          {company.Logo && company.Logo.length > 0 && !imageNotFound ? (
            <img
              src={company.Logo}
              alt={company.BusinessName}
              onError={() => setImageNotFound(true)}
            />
          ) : (
            <Icon name="HiOfficeBuilding" color={color} />
          )}
        </Avatar>
      </MediaLeft>
      <StyledMediaBody>
        <StyledStrong color={color}>{company.BusinessName}</StyledStrong>
        {company.UnreadedMessages > 0 ? (
          <Badge color="danger" variant="contained" marginLeft="0.5rem">
            {company.UnreadedMessages}
          </Badge>
        ) : null}
      </StyledMediaBody>
      <span style={{ marginLeft: "1rem" }} onClick={(e) => e.stopPropagation()}>
        {company.Favorite ? (
          <Star
            fill={color}
            stroke="0"
            onClick={(e) => {
              e.stopPropagation();
              onSetFavorite(company.Id);
            }}
          />
        ) : (
          <Star
            onClick={(e) => {
              e.stopPropagation();
              onSetFavorite(company.Id);
            }}
          />
        )}
      </span>
    </Media>
  );
};

type Props = {
  companies: Company[];
  activeCompany: Company | null;
  onSetFavorite: (companyId: Company["Id"]) => any;
};

const CompanyDropdown: React.FC<Props> = ({
  activeCompany,
  companies,
  onSetFavorite,
}) => {
  return (
    <Dropdown direction="down" className="header-dropdown">
      <DropdownToggle variant="texted" disabled={companies.length === 1}>
        {activeCompany && (
          <>
            <CompanyItem
              company={activeCompany}
              onSetFavorite={onSetFavorite}
            />
            {companies.length > 1 && <ChevronDown />}
          </>
        )}
      </DropdownToggle>
      <StyledDropMenu>
        {companies.map((company) => (
          <StyledDropItem key={company.Id} path={`/company/${company.Id}`}>
            <CompanyItem
              company={company}
              key={company.Id}
              onSetFavorite={onSetFavorite}
            />
          </StyledDropItem>
        ))}
      </StyledDropMenu>
    </Dropdown>
  );
};

export default CompanyDropdown;
