import { FC } from "react";
import { NotificationField, User } from "../../../types";
import Scrollbar from "../../Common/Scrollbar";
import MainNav from "../MainNav";
import { StyledSidebar, StyledBody } from "./style";

type Props = {
  notificationsFields: NotificationField[];
  activeField: string | "inbox" | null;
  isInternalUser: boolean | undefined;
  companyUsers: User[];
  onUserChange: (event: React.ChangeEvent) => any;
};

const Sidebar: FC<Props> = ({
  notificationsFields,
  activeField,
  isInternalUser,
  companyUsers,
  onUserChange,
}) => {
  return (
    <StyledSidebar className="sidebar">
      <Scrollbar top="0px">
        <StyledBody>
          <MainNav
            notificationsFields={notificationsFields}
            activeField={String(activeField)}
            isInternalUser={isInternalUser}
            companyUsers={companyUsers}
            onUserChange={onUserChange}
          />
        </StyledBody>
      </Scrollbar>
    </StyledSidebar>
  );
};

export default Sidebar;
