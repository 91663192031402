import { useCallback, useState } from "react";
import { useDisclosure } from "../../../hooks/useDisclosure";
import storage from "../../../services/storage";
import { Application } from "../../../types";
import { Col, Row } from "../../Common/Grid";
import ApplicationCard from "../ApplicationCard";
import { ApplicationModal } from "../ApplicationModal";
import { ApplicationsEmptyState } from "../EmptyState";

type Props = {
  applications: Application[];
};

export const MainContent: React.FC<Props> = ({ applications }) => {
  const { isOpen, open, close } = useDisclosure();
  const [activeApplcation, setActiveApplication] = useState<Application | null>(
    null
  );

  const handleApplicationClick = useCallback(
    (id: Application["Id"]) => {
      const application = applications.find(
        (application) => application.Id === id
      );

      if (application && application.Enabled) {
        window.open(
          `${application.Url}?token=${
            // @ts-ignore
            storage.getItem("accessToken")?.accessToken
          }`,
          "_blank"
        );
      }
    },
    [applications]
  );

  const handleApplicationAboutClick = useCallback(
    (id: Application["Id"]) => {
      const application = applications.find(
        (application) => application.Id === id
      );

      if (application) {
        setActiveApplication(application);
        open();
      }
    },
    [applications, open]
  );

  const handleCloseModal = useCallback(() => {
    close();
    setActiveApplication(null);
  }, [close]);

  return (
    <Col lg={12} margin="0 auto">
      <Row gutters={10}>
        {applications.length === 0 && <ApplicationsEmptyState />}
        {applications.map((application) => (
          <Col key={application.Id} sm={6} md={3} mt="10px">
            <ApplicationCard
              isEnabled={application.Enabled}
              id={application.Id}
              name={application.Description}
              onClick={handleApplicationClick}
              onAboutClick={handleApplicationAboutClick}
              logo={application.Logo}
              color={application.Color}
            />
          </Col>
        ))}
      </Row>
      {activeApplcation && (
        <ApplicationModal
          show={isOpen}
          onClose={handleCloseModal}
          application={activeApplcation}
          onClick={handleApplicationClick}
        />
      )}
    </Col>
  );
};
