import { observer } from "mobx-react";
import { BasicBusinessDataWidget } from "../../components/Home/BasicBusinessDataWidget";
import { useUserCompanyAbilities } from "../../hooks/abilties.hook";
import { useDataAnalysis } from "../../hooks/dataAnalysis.hooks";
import { useScreenConfig } from "../../hooks/useScreenConfig";
import { useStores } from "../../hooks/useStores";
import { Screens } from "../../types";

const WrapperBasicBusinessDataWidget: React.FC = observer(() => {
  const { session } = useStores();
  const config = useScreenConfig(Screens.BASIC_BUSINESS_DATA);
  const { data = [] } = useDataAnalysis();
  const { data: abilties } = useUserCompanyAbilities();

  return (
    <BasicBusinessDataWidget
      workspaces={data}
      companyId={session.activeCompanyId || -1}
      config={config}
      enabled={abilties?.dataAnalysis}
    />
  );
});

export default WrapperBasicBusinessDataWidget;
