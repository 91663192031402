import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Anchor } from "../../Common/Anchor";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import { Button } from "../../Common/Button";
import {
  StyledCard,
  StyledCardBody,
  StyledName,
  StyledInner,
  StyledAbout,
} from "./style";

interface IProps {
  id: number;
  logo: string | null;
  color: string | null;
  name: string;
  isEnabled: boolean;
  onClick: (id: number) => any;
  onAboutClick: (id: number) => any;
}

const ApplicationCard: FC<IProps> = ({
  id,
  logo,
  color,
  name,
  isEnabled,
  onClick,
  onAboutClick,
}) => {
  const { t } = useTranslation();
  const [imageNotFound, setImageNotFound] = useState(false);

  const handleAboutClick = useCallback(
    (e) => {
      e.preventDefault();
      onAboutClick(id);
    },
    [id, onAboutClick]
  );

  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <StyledCard>
      <StyledCardBody color={color}>
        <StyledInner>
          <Avatar size="lg" shape="rounded">
            {logo && !imageNotFound && (
              <img
                src={logo}
                alt="card"
                onError={() => setImageNotFound(true)}
              />
            )}
            {(!logo || (logo && imageNotFound)) && (
              <AvatarInitial backgroundColor={color || undefined}>
                {name.charAt(0)}
              </AvatarInitial>
            )}
          </Avatar>

          <StyledName>{name}</StyledName>
          <StyledAbout>
            <Anchor path={"#"} variant="link2" onClick={handleAboutClick}>
              {t("components.applicationCard.info")}
            </Anchor>
          </StyledAbout>
          <Button
            color={isEnabled ? "primary" : "white"}
            fullwidth
            onClick={handleClick}
            disabled={!isEnabled}
          >
            {isEnabled
              ? t("components.applicationCard.login")
              : t("components.applicationCard.notAvailable")}
          </Button>
        </StyledInner>
      </StyledCardBody>
    </StyledCard>
  );
};

export default ApplicationCard;
