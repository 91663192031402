import React, { FC } from "react";
import { StyledForm } from "./style";
import { useTranslation } from "react-i18next";
import { Select } from "../../Common/Forms";
import { Button } from "../../Common/Button";
import { Filter } from "react-feather";
import { NotificationFilters } from "../../../types";

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent) => any;
};

const FilterForm: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <Select value={value} onChange={onChange}>
        <option value={NotificationFilters.ALL}>
          {t("components.notifications.filterAll")}
        </option>
        <option value={NotificationFilters.UNREADED}>
          {t("components.notifications.filterUnreaded")}
        </option>
      </Select>
      <Button variant="texted" iconButton size="md">
        <Filter />
      </Button>
    </StyledForm>
  );
};

export default FilterForm;
