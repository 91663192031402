import { FC } from "react";
import { Notification } from "../../../types";
import Heading from "../../Common/Heading";
import Text from "../../Common/Text";
import { StyledWrap, StyledContent } from "./style";

type Props = {
  notification: Notification;
};

const MailBody: FC<Props> = ({ notification }) => {
  return (
    <StyledWrap>
      <Heading as="h5" mb="30px">
        {notification.Subject}
      </Heading>
      {/* <Heading mb="0px" fontWeight={600}>
        Mario Rossi
      </Heading>
      <Text as="span" color="text3">
        Paghe
      </Text> */}
      <StyledContent
        dangerouslySetInnerHTML={{
          __html: notification.Text.replaceAll("\\r", "\r").replaceAll(
            "\\n",
            "<br/>"
          ),
        }}
      />
    </StyledWrap>
  );
};

export default MailBody;
