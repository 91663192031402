import { useTranslation } from "react-i18next";
import { Application } from "../../../types";
import { Button } from "../../Common/Button";
import {
  Modal,
  ModalBody,
  ModalClose,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../Common/Modal";

type Props = {
  application: Application;
  show: boolean;
  onClose: () => any;
  onClick: (id: Application["Id"]) => any;
};

export const ApplicationModal: React.FC<Props> = ({
  application,
  show,
  onClose,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onClose={onClose} centered>
      <ModalHeader>
        <ModalTitle>{application.Description}</ModalTitle>
        <ModalClose onClose={onClose}>x</ModalClose>
      </ModalHeader>
      <ModalBody>
        <p>{application.ExtendedDescription}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          color={application.Enabled ? "primary" : "white"}
          onClick={() => onClick(application.Id)}
          disabled={!application.Enabled}
        >
          {application.Enabled
            ? t("components.applicationCard.login")
            : t("components.applicationCard.notAvailable")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
