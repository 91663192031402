import logo from "../../assets/images/logo-FEINAR-hd.png";
import "../../App.css";

export const AppLoader: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Portale Aziende Feinar</p>
      </header>
    </div>
  );
};
