import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import storage from "../../../services/storage";
import { Application } from "../../../types";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import { Button } from "../../Common/Button";

import { StyledListMiddle, StyledListTitle, StyledListEnd } from "./style";

type Props = {
  application: Application;
};

const Item: FC<Props> = ({ application }) => {
  const { t } = useTranslation();
  const [imageNotFound, setImageNotFound] = useState(false);

  return (
    <>
      <Avatar size="sm" shape="rounded">
        {application.Logo && !imageNotFound && (
          <img
            src={application.Logo}
            alt={application.Description}
            onError={() => setImageNotFound(true)}
          />
        )}
        {(!application.Logo || (application.Logo && imageNotFound)) && (
          <AvatarInitial backgroundColor={application.Color || undefined}>
            {application.Description.charAt(0)}
          </AvatarInitial>
        )}
      </Avatar>
      <StyledListMiddle>
        <StyledListTitle>{application.Description}</StyledListTitle>
      </StyledListMiddle>
      <StyledListEnd>
        <Button
          variant="outlined"
          color={"white"}
          onClick={() =>
            window.open(
              `${application.Url}?token=${
                // @ts-ignore
                storage.getItem("accessToken")?.accessToken
              }`,
              "_blank"
            )
          }
        >
          {t("components.applicationCard.login")}
        </Button>
      </StyledListEnd>
    </>
  );
};

export default Item;
