/* eslint-disable react/react-in-jsx-scope */
import styled, { device, themeGet, css } from "../../../../styled";
import { MediaBody } from "../../../UI/Media";

export const StyledAdvancedSwitchContainer = styled.div`
  //width: 300px;
  padding: 0;
  margin-right: -20px;
  box-shadow: none;
  right: auto;
  left: 0;
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-right: -75px;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  cursor: pointer;
  ${device.small} {
    margin-right: -20px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    right: 25px;
    border-bottom: 10px solid ${themeGet("colors.border")};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    right: auto;
    left: 72px;
    ${device.small} {
      left: 20px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: -8.5px;
    right: 26px;
    border-bottom: 9px solid #fff;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    right: auto;
    left: 73px;
    ${device.small} {
      left: 21px;
    }
  }
`;

export const StyledMediaBody = styled(({ ...rest }) => <MediaBody {...rest} />)`
  margin-left: 0.5rem;
  font-size: 13px;
  display: flex;
`;

export const StyledStrong = styled.strong<{ color?: string }>`
  font-weight: 500;
  color: ${(props) => props.color || themeGet("colors.text2")};

  ${(props) =>
    props.theme.name === "dark" &&
    css`
      color: ${themeGet("colors.white")};
    `}
`;
