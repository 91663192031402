import { useState } from "react";
import { Company } from "../../../../types";
import { Avatar } from "../../../Common/Avatar";
import { Badge } from "../../../Common/Badge";
import { Icon } from "../../../Common/Icon";
import { Media, MediaLeft } from "../../../UI/Media";
import { StyledMediaBody, StyledStrong } from "./styled";

const CompanyGroupItem: React.FC<{ company: Company; color?: string }> = ({
  company,
  color,
}) => {
  const [imageNotFound, setImageNotFound] = useState(false);
  return (
    <Media alignItems="center" paddingX="1rem" paddingY="0.5rem">
      <MediaLeft>
        <Avatar size="sm" shape="rounded">
          {company.Logo && company.Logo.length > 0 && !imageNotFound ? (
            <img
              src={company.Logo}
              alt={company.BusinessName}
              onError={() => setImageNotFound(true)}
            />
          ) : (
            <Icon name="HiOfficeBuilding" color={color} />
          )}
        </Avatar>
      </MediaLeft>
      <StyledMediaBody>
        <StyledStrong color={color}>
          {company.Id} - {company.BusinessName}
          {company.ClientType ? ` (${company.ClientType})` : null}
          {company.UnreadedMessages > 0 ? (
            <Badge color="danger" variant="contained" marginLeft="0.5rem">
              {company.UnreadedMessages}
            </Badge>
          ) : null}
        </StyledStrong>
      </StyledMediaBody>
    </Media>
  );
};

export default CompanyGroupItem;
