import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import PageHeader from "../../components/BasicBusinessData/PageHeader";
import Content from "../../components/Common/Content";
import { useStores } from "../../hooks/useStores";
import { BusinessCharts, Screens } from "../../types";
import { useTranslation } from "react-i18next";
import { Row } from "../../components/Common/Grid";
import RowOne from "./RowOne";
import RowFour from "./RowFour";
import RowTwo from "./RowTwo";
import RowThree from "./RowThree";
import { useDataAnalysisFields } from "../../hooks/dataAnalysisField.hooks";
import { useBusinessCharts } from "../../hooks/businessCharts.hooks";
import {
  useBiMonthlyData,
  useBiYearlyData,
} from "../../hooks/dataAnalysis.hooks";
import { useCompanyUsers } from "../../hooks/companyUsers.hooks";
import { useNavigate } from "react-router-dom";

export const BasicBusinessData: React.FC = observer(() => {
  const { session, ui } = useStores();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<"all" | string>("all");
  const { data: fields = [] } = useDataAnalysisFields();
  const { data: charts = [] } = useBusinessCharts();
  const { data: monthlyData = [] } = useBiMonthlyData();
  const { data: yearlyData = [] } = useBiYearlyData();
  const { refetch: refetchCompanyUsers, data: companyUsers = [] } =
    useCompanyUsers();
  const navigate = useNavigate();
  // const activeUserId = useActiveUser();

  useEffect(() => {
    ui.setActiveScreen(Screens.BASIC_BUSINESS_DATA);
  }, []);

  useEffect(() => {
    if (session.user?.Internal) {
      refetchCompanyUsers();
    }
  }, [session.user]);

  const activeCharts = useMemo(() => {
    return filter === "all"
      ? charts
      : charts.filter((chart) => chart.Field.Id === Number(filter));
  }, [filter, charts]);

  const isRow1Enabled = useMemo(() => {
    return (
      activeCharts.filter(
        (chart) => chart.Name === BusinessCharts.COST_CONVERSION
      ).length > 0
    );
  }, [activeCharts]);

  const isRow2Enabled = useMemo(() => {
    return (
      activeCharts.filter((chart) => chart.Name === BusinessCharts.MONTHLY_COST)
        .length > 0
    );
  }, [activeCharts]);

  const isRow3Enabled = useMemo(() => {
    return (
      activeCharts.filter(
        (chart) => chart.Name === BusinessCharts.MONTHLY_WORKING_HOURS
      ).length > 0
    );
  }, [activeCharts]);

  const isRow4Enabled = useMemo(() => {
    return (
      activeCharts.filter(
        (chart) =>
          chart.Name === BusinessCharts.TOTAL_EMPLOYEES ||
          chart.Name === BusinessCharts.TOTAL_FUND_DISTRIBUTION
      ).length > 0
    );
  }, [activeCharts]);

  const handleFilterChage = useCallback(
    (event: any) => {
      setFilter(event.target.value);
    },
    [setFilter]
  );

  const handleChangeUser = (event: any) => {
    navigate(`.?user=${event.target.value}`);
  };

  return (
    <>
      <Content borderBottomWidth="1px">
        <PageHeader
          prev={[]}
          title=""
          wcText={t("components.menu.basicBusinessData")}
          fields={fields}
          filterValue={filter}
          onFilterChange={handleFilterChage}
          isInternalUser={session.user?.Internal}
          companyUsers={companyUsers || []}
          onUserChange={handleChangeUser}
        />
      </Content>
      <Content mt={[null, null, null, "0px"]}>
        {isRow1Enabled && (
          <Row gutters={10}>
            <RowOne yearlyData={yearlyData} charts={charts} />
          </Row>
        )}
        {isRow2Enabled && (
          <Row>
            <RowTwo monthlyData={monthlyData} charts={charts} />
          </Row>
        )}
        {isRow3Enabled && (
          <Row>
            <RowThree monthlyData={monthlyData} charts={charts} />
          </Row>
        )}
        {isRow4Enabled && (
          <Row>
            <RowFour
              yearlyData={yearlyData}
              monthlyData={monthlyData}
              charts={charts}
            />
          </Row>
        )}
      </Content>
    </>
  );
});
