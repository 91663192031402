import { useState } from "react";
import { classicTheme } from "../../../styled";
import { User } from "../../../types";
import { getUserInitials } from "../../../utils/user";
import { Avatar, AvatarInitial } from "../../Common/Avatar";
import { Col, Row } from "../../Common/Grid";
import { StyledAvatarWrapper, StyledName, StyledWrap } from "./style";

type Props = {
  user: User;
};

export const UserAvatar: React.FC<Props> = ({ user }) => {
  const [imageNotFound, setImageNotFound] = useState(false);
  return (
    <StyledWrap>
      <Row>
        <Col sm={3} md={2} lg={12}>
          <StyledAvatarWrapper>
            <Avatar shape="rounded" size="xxl">
              {user.Picture && user.Picture.length > 0 && !imageNotFound ? (
                <img
                  src={user.Picture}
                  alt={user.Firstname}
                  onError={() => setImageNotFound(true)}
                />
              ) : (
                <AvatarInitial backgroundColor={classicTheme.colors.primary}>
                  {getUserInitials(user)}
                </AvatarInitial>
              )}
            </Avatar>
          </StyledAvatarWrapper>
        </Col>
        <Col sm={8} md={7} lg={12} mt={["20px", "0px", null, "25px"]}>
          <StyledName>
            {user.Firstname} {user.Lastname}
          </StyledName>
        </Col>
      </Row>
    </StyledWrap>
  );
};
