import React from "react";
import {
  Company,
  User,
  Screens,
  Notification,
  ExternalLink,
} from "../../types";
import { Footer } from "./Footer";
import Header from "./Header";

interface IProps {
  user: User | null;
  companies: Company[];
  activeCompany: Company | null;
  hasSidebar?: boolean;
  sidebarLayout?: 1 | 2;
  menu: any;
  activeScreen: Screens | null;
  unreadedNotifications: Notification[];
  notificationsEnabled?: boolean;
  externalLinks: ExternalLink[];
  onSetFavoriteCompany: (companyId: Company["Id"]) => any;
}

const Layout: React.FC<IProps> = ({
  user,
  companies,
  activeCompany,
  children,
  hasSidebar,
  sidebarLayout,
  menu,
  activeScreen,
  unreadedNotifications,
  notificationsEnabled = false,
  externalLinks,
  onSetFavoriteCompany,
}) => {
  return (
    <>
      <Header
        user={user}
        hasSidebar={hasSidebar}
        sidebarLayout={sidebarLayout}
        companies={companies}
        activeCompany={activeCompany}
        menu={menu}
        activeScreen={activeScreen}
        unreadedNotifications={unreadedNotifications}
        notificationsEnabled={notificationsEnabled}
        onSetFavoriteCompany={onSetFavoriteCompany}
        externalLinks={externalLinks}
      />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
