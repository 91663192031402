import { useQuery } from "react-query";
import api from "../services/api";
import { useActiveCompany } from "./useActiveCompany";
import { useActiveDataAnalysis } from "./useActiveDataAnalysis";
import { useActiveUser } from "./useActiveUser";

export const useBusinessCharts = () => {
  const userId = useActiveUser();
  const activeCompany = useActiveCompany();
  const dataAnalysisId = useActiveDataAnalysis();

  return useQuery(
    [
      `business-charts-${userId}-${activeCompany}-${dataAnalysisId}`,
      userId,
      activeCompany,
      dataAnalysisId,
    ],
    () =>
      api.fetchBusinessCharts(
        userId || -1,
        activeCompany || -1,
        dataAnalysisId || -1
      ),
    {
      enabled: !!userId && !!activeCompany && !!dataAnalysisId,
    }
  );
};
