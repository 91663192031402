import { useFormik } from "formik";
import React from "react";
import { User, UserProfileConfig } from "../../../types";
import { CardBody } from "../../Common/Card";
import Heading from "../../Common/Heading";
import { Media, MediaBody } from "../../UI/Media";
import CardFooter from "../CardFooter";
import Header from "../Header";
import { StyledCard } from "./style";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Col, Row } from "../../Common/Grid";
import { Input, Select } from "../../Common/Forms";
import { Button } from "../../Common/Button";

type Props = {
  user: User;
  onSubmit: (data: UserProfileConfig) => any;
};

const phoneRegExp = /^[0-9]{8,10}$/;

export const ProfileConfiguration: React.FC<Props> = ({ user, onSubmit }) => {
  const { t } = useTranslation();

  const OTP_CHANNELS = [
    {
      label: t("components.profile.otpChannels.email"),
      value: "0",
    },
    {
      label: t("components.profile.otpChannels.pn"),
      value: "2",
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Id: user.Id,
      Alias: user.LoginAlias,
      PhoneNumber: user.PhoneNumber || "",
      MobileNumber: user.MobileNumber || "",
      OtpChannel: typeof user.OtpChannel !== "undefined" ? user.OtpChannel : 0,
    },
    validationSchema: Yup.object({
      Alias: Yup.string()
        .nullable()
        .min(8, t("messages.profileConfiguration.invalidAlias"))
        .matches(
          /^[aA-zZ0-9\s]+$/,
          t("messages.profileConfiguration.invalidAlias")
        ),
      PhoneNumber: Yup.string()
        .nullable()
        .matches(phoneRegExp, t("messages.profileConfiguration.invalidPhone")),
      MobileNumber: Yup.string()
        .nullable()
        .matches(phoneRegExp, t("messages.profileConfiguration.invalidPhone")),
    }),
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledCard mb={["20px", null, null, "25px"]}>
        <Header>
          <Heading tt="uppercase" fontWeight="600" mb="0px">
            {t("components.profile.userData")}
          </Heading>
        </Header>
        <CardBody p={["20px", "20px", null, "25px"]}>
          <Media display={["block", "flex"]}>
            <MediaBody mt={["25px", 0]}>
              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>{t("components.profile.email")}:</Col>
                <Col sm={9}>
                  <Input
                    id="Email"
                    px="0px"
                    borderWidth="0px"
                    placeholder="Email"
                    disabled
                    name="Email"
                    value={user.Email}
                  />
                </Col>
              </Row>

              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>{t("components.profile.fiscalCode")}:</Col>
                <Col sm={9}>
                  <Input
                    id="FiscalCode"
                    px="0px"
                    borderWidth="0px"
                    placeholder="FiscalCode"
                    disabled
                    name="FiscalCode"
                    value={user.FiscalCode || ""}
                  />
                </Col>
              </Row>

              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>{t("components.profile.alias")}:</Col>
                <Col sm={9}>
                  <Input
                    id="Alias"
                    px="0px"
                    borderWidth="0px"
                    placeholder="Alias"
                    {...formik.getFieldProps("Alias")}
                    feedbackText={formik.getFieldMeta("Alias").error}
                    state={
                      formik.getFieldMeta("Alias").error ? "error" : "success"
                    }
                    showState={
                      !!formik.getFieldMeta("Alias").error &&
                      formik.touched.Alias
                    }
                  />
                </Col>
              </Row>

              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>{t("components.profile.phoneNumber")}:</Col>
                <Col sm={9}>
                  <Input
                    id="PhoneNumber"
                    px="0px"
                    borderWidth="0px"
                    placeholder={t("components.profile.phoneNumber")}
                    {...formik.getFieldProps("PhoneNumber")}
                    feedbackText={formik.getFieldMeta("PhoneNumber").error}
                    state={
                      formik.getFieldMeta("PhoneNumber").error
                        ? "error"
                        : "success"
                    }
                    showState={
                      !!formik.getFieldMeta("PhoneNumber").error &&
                      formik.touched.PhoneNumber
                    }
                    onKeyDown={(event) => {
                      if (event.code === "Space") event.preventDefault();
                    }}
                  />
                </Col>
              </Row>

              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>{t("components.profile.mobileNumber")}:</Col>
                <Col sm={9}>
                  <Input
                    id="MobileNumber"
                    px="0px"
                    borderWidth="0px"
                    placeholder={t("components.profile.mobileNumber")}
                    {...formik.getFieldProps("MobileNumber")}
                    feedbackText={formik.getFieldMeta("MobileNumber").error}
                    state={
                      formik.getFieldMeta("MobileNumber").error
                        ? "error"
                        : "success"
                    }
                    showState={
                      !!formik.getFieldMeta("MobileNumber").error &&
                      formik.touched.MobileNumber
                    }
                    onKeyDown={(event) => {
                      if (event.code === "Space") event.preventDefault();
                    }}
                  />
                </Col>
              </Row>

              <Row alignItems="center" marginBottom="0.5rem">
                <Col sm>{t("components.profile.otpChannel")}:</Col>
                <Col sm={9}>
                  <Select
                    {...formik.getFieldProps("OtpChannel")}
                    style={{ border: "0px" }}
                  >
                    {OTP_CHANNELS.map((channel) => (
                      <option key={channel.value} value={channel.value}>
                        {channel.label}
                      </option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </MediaBody>
          </Media>
        </CardBody>
        <CardFooter>
          <Button type="submit" variant="texted" color="primary">
            {t("common.confirm")}
          </Button>
        </CardFooter>
      </StyledCard>
    </form>
  );
};
