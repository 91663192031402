import React, { FC } from "react";
import { StyledForm } from "./style";
import { useTranslation } from "react-i18next";
import { Select } from "../../Common/Forms";
import { Button } from "../../Common/Button";
import { Filter } from "react-feather";
import { SharepointFilters, User } from "../../../types";

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent) => any;
  impersonation?: boolean;
  activeUserData?: User | null;
};

const FilterForm: FC<Props> = ({
  value,
  onChange,
  impersonation,
  activeUserData,
}) => {
  const { t } = useTranslation();

  const options = impersonation
    ? [
        {
          key: "filterSupervisorUnreaded",
          value: SharepointFilters.SUPERVISOR_UNREADED,
          label: t("components.sharepoint.filterSupervisorUnreaded"),
        },
        {
          key: "filterSupervisedUnreaded",
          value: SharepointFilters.SUPERVISED_UNREADED,
          label: t("components.sharepoint.filterSupervisedUnreaded", {
            name: `${activeUserData?.Firstname} ${activeUserData?.Lastname}`,
          }),
        },
        {
          key: "filterAllUnreaded",
          value: SharepointFilters.ALL_UNREADED,
          label: t("components.sharepoint.filterAllUnreaded"),
        },
        {
          key: "filterReadedMixed",
          value: SharepointFilters.READED_MIXES,
          label: t("components.sharepoint.filterReadedMixed"),
        },
      ]
    : [
        {
          key: "filterAll",
          value: SharepointFilters.ALL,
          label: t("components.sharepoint.filterAll"),
        },
        {
          key: "filterUnreaded",
          value: SharepointFilters.UNREADED,
          label: t("components.sharepoint.filterUnreaded"),
        },
      ];

  return (
    <StyledForm style={impersonation ? { minWidth: "180px" } : undefined}>
      <Select value={value} onChange={onChange}>
        {options.map((opt) => (
          <option key={opt.key} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
      <Button variant="texted" iconButton size="md">
        <Filter />
      </Button>
    </StyledForm>
  );
};

export default FilterForm;
