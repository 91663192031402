import styled, { themeGet } from "../../../styled";

export const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledEmptyStateText = styled.small`
  color: ${themeGet("colors.text3")};
  font-size: 12px;
  margin-bottom: 0px;
  opacity: 0.75;
  margin-top: 0.5rem;
`;
